import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { FlexBox } from "./FlexBox";

const style = {
  position: "absolute",
  fontFamily: "Assistant",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: 200,
    md: 400,
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function AskForSignUp({ handleOpen, open, handleClose }) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ marginBottom: "10px", fontFamily: "Assistant" }}
            variant="h6"
            component="h2"
          >
            Not logged in
          </Typography>
          <Typography
            variant="p"
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              fontFamily: "Assistant",
            }}
          >
            You are not loggedin to the BidIt website. You need to be loggedin
            to view jobs.
          </Typography>
          <FlexBox>
            <NavLink to="/signin" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  marginRight: "10px",
                  padding: "5px 30px",
                  fontFamily: "Assistant",
                }}
              >
                Go to LogIn
              </Button>
            </NavLink>
            <Button
              variant="contained"
              sx={{
                marginRight: "10px",
                padding: "5px 30px",
                fontFamily: "Assistant",
              }}
              onClick={handleClose}
            >
              No
            </Button>
          </FlexBox>
        </Box>
      </Modal>
    </div>
  );
}
