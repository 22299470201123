import logo from "../../asset/images/logo.png";
export default function ShowDetailSignup({text}){
    return (<div style={{marginTop:"30px",marginBottom:"30px"}}>
        <div style={{backgroundColor:"#d4f3ee",borderRadius:"10px",borderBottomRightRadius:"0px",padding:"10px 20px",textAlign:"center"}}>
         <h4 style={{ fontFamily: 'Assistant' }}>{text}</h4>
    </div>
    <div style={{display:"flex",flexDirection:"row-reverse",width:"105%"}}>
        <div style={{flex:1,width:"50%"}}></div>
        <div style={{flex:1,alignSelf:"flex-end",justifyItems:"flex-end"}}>
            {/*<img src={logo}alt="Logo" style={{display:"block",width:"100px",height:"100px",borderRadius:"50%",marginLeft:"30px"}}/>*/}
        </div>

    </div>
    </div>)
}