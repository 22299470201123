import { Cancel } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { FlexBox } from "./FlexBox";
import ShowBidPost from "./ShowBidPost";
import ShowBidSide from "./ShowBidSide";
import Snackbar1 from "./Snaclbar";

function ShowBids() {
  const [deleteBid, setDeleteBid] = useState(false);
  const [updateBid, setUpdateBid] = useState(false);
  const [post, setPost] = useState({});
  const [approvebid, setApproveBid] = useState(true);
  const [skills, setSkills] = useState([]);
  const [mes, setMes] = useState("");
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const [closed, setClosed] = useState(false);
  const [result, setResult] = useState([]);
  const [resultBid, setResultBid] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getbid?id=${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        console.log(Cookies.get("id") === parseInt(res.data.Bid[0].user_id));
        setUpdateBid(
          parseInt(Cookies.get("id")) === parseInt(res.data.Bid[0].user_id)
            ? true
            : false
        );
        setClosed(
          parseInt(res.data.Bid[0] - new Date().getTime() < 0 ? true : false)
        );
        setResult(res.data.Biddeds);
        setSkills(res.data.Bid[0].skills.split(","));
        setPost(res.data.Bid[0]);
        setShow(true);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const loadFunction = () => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getbid?id=${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        setResult(res.data.Biddeds);
        setSkills(res.data.Bid[0].skills.split(","));
        setPost(res.data.Bid[0]);
        setShow(true);
      })
      .catch((err) => console.log(err));
  };
  const handleDelete = () => {
    axios
      .delete(
        `${process.env.React_APP_BACKEND_URL}/api/deletebid?id=${post.id}`,
        {
          headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        }
      )
      .then((e) => {
        console.log(e);
        setDeleteBid(true);
      })
      .catch((err) => console.log(err));
  };
  let date = new Date();
  let time = parseInt(post.length1);
  var currentDate = Date.now() + time * 60 * 60 * 1000 + 10 * 6000;
  console.log(currentDate + " " + post.length1);
  let date1 = new Date(currentDate);
  return (
    <div sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {deleteBid && (
        <Typography
          variant="h6"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            margin: "20px auto",
            width: "70%",
          }}
        >
          המכרז נמחק בהצלחה.
        </Typography>
      )}
      {!approvebid && <Snackbar1 mes={mes} />}
      {show && !deleteBid && (
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          {Cookies.get("adminId") && (
            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <NavLink to={`/viewuserprofile/${post.user_id}`}>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                >
                  קישור לפרופיל מפרסם העבודה{" "}
                </Typography>
              </NavLink>
              {approvebid && post.status.toString() === "0" && <FlexBox
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  backgroundColor: "#CDE6C9",
                  padding: "10px",
                  marginTop: "20px",
                  color: "white",
                }}
              >
                <p
                  style={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    color: "black",
                  }}
                >
                  תרצה לאשר או להסיר את המכרז?
                </p>
                <FlexBox>
                   
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        backgroundColor: "white",
                        color: "black",
                      }}
                      onClick={(e) => {
                        axios
                          .put(
                            `${process.env.React_APP_BACKEND_URL}/api/admin/approvebid`,
                            {
                              id,
                              deadline:
                                new Date(
                                  Date.now() +
                                    parseInt(post.length1.toString()) +
                                    10 * 6000
                                ).getTime() + "",
                              dateDeadline:
                                new Date(
                                  Date.now() +
                                    parseInt(post.length1.toString()) +
                                    10 * 6000
                                ).getFullYear() +
                                "-" +
                                (new Date(
                                  Date.now() +
                                    parseInt(post.length1.toString()) +
                                    10 * 6000
                                ).getMonth() +
                                  1 <
                                10
                                  ? "0" +
                                    (new Date(
                                      Date.now() +
                                        parseInt(post.length1.toString()) +
                                        10 * 6000
                                    ).getMonth() +
                                      1)
                                  : new Date(
                                      Date.now() +
                                        parseInt(post.length1.toString()) +
                                        10 * 6000
                                    ).getMonth() + 1) +
                                "-" +
                                (new Date(
                                  Date.now() +
                                    parseInt(post.length1.toString()) +
                                    10 * 6000
                                ).getDate() < 10
                                  ? "0" +
                                    new Date(
                                      Date.now() +
                                        parseInt(post.length1.toString()) +
                                        10 * 6000
                                    ).getDate()
                                  : new Date(
                                      Date.now() +
                                        parseInt(post.length1.toString()) +
                                        10 * 6000
                                    ).getDate()) +
                                " " +
                                (new Date(
                                  Date.now() +
                                    parseInt(post.length1.toString()) +
                                    10 * 6000
                                ).getHours() < 10
                                  ? "0" +
                                    new Date(
                                      Date.now() +
                                        parseInt(post.length1.toString()) +
                                        10 * 6000
                                    ).getHours()
                                  : new Date(
                                      Date.now() +
                                        parseInt(post.length1.toString()) +
                                        10 * 6000
                                    ).getHours()) +
                                ":" +
                                (new Date(
                                  Date.now() +
                                    parseInt(post.length1.toString()) +
                                    10 * 6000
                                ).getMinutes() < 10
                                  ? "0" +
                                    new Date(
                                      Date.now() +
                                        parseInt(post.length1.toString()) +
                                        10 * 6000
                                    ).getMinutes()
                                  : new Date(
                                      Date.now() +
                                        parseInt(post.length1.toString()) +
                                        10 * 6000
                                    ).getMinutes()) +
                                ":" +
                                "00",
                            },
                            {
                              headers: {
                                Authorization: `Bearer ${Cookies.get(
                                  "admintoken"
                                )}`,
                              },
                            }
                          )
                          .then((res) => {
                            console.log(res);
                            setApproveBid(false);
                            setMes("המכרז אושר");
                          })
                          .catch((err) => console.log(err));
                      }}
                    >
                      אשר מכרז
                    </Button>
                  
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      backgroundColor: "white",
                      color: "black",
                      margin: "0px 5px",
                    }}
                    onClick={handleDelete}
                  >
                    הסר מכרז
                  </Button>
                  <Button
                    sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                    variant="text"
                    onClick={(e) => setApproveBid(false)}
                    startIcon={<Cancel />}
                  ></Button>
                </FlexBox>
              </FlexBox>
              }
            </Box>
          )}

          {updateBid && post.deadline - new Date().getTime() > 0 && (
            <FlexBox
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                backgroundColor: "#CDE6C9",
                padding: "10px",
                marginTop: "20px",
                color: "white",
              }}
            >
              <p
                style={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  color: "black",
                }}
              >
                האם ברצונך למחוק את המכרז שלך?
              </p>
              <FlexBox>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    backgroundColor: "white",
                    color: "black",
                  }}
                  onClick={handleDelete}
                >
                  מחק מכרז
                </Button>
                <Button
                  variant="text"
                  onClick={(e) => setUpdateBid(false)}
                  startIcon={<Cancel />}
                ></Button>
              </FlexBox>
            </FlexBox>
          )}
          {updateBid &&
            post.deadline - new Date().getTime() > 0 &&
            result.length === 0 && (
              <FlexBox
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  backgroundColor: "#CDE6C9",
                  padding: "10px",
                  marginTop: "20px",
                  color: "white",
                }}
              >
                <p
                  style={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    color: "black",
                  }}
                >
                  האם ברצונך לערוך מחדש את המכרז שלך?
                </p>
                <FlexBox>
                  <NavLink
                    style={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      textDecoration: "none",
                    }}
                    to={`/updatebid/${post.id}`}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        backgroundColor: "white",
                        color: "black",
                      }}
                    >
                      ערוך מכרז
                    </Button>
                  </NavLink>
                  <Button
                    sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                    variant="text"
                    onClick={(e) => setUpdateBid(false)}
                    startIcon={<Cancel />}
                  ></Button>
                </FlexBox>
              </FlexBox>
            )}
          {post.deadline - new Date().getTime() <= 0 &&resultBid&& (
            <FlexBox
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                backgroundColor: "#CDE6C9",
                padding: "10px",
                marginTop: "20px",
                color: "white",
              }}
            >
			  {/*<p style={{ color: "black" }}>צפה כאן בתוצאות המכרז</p>*/}
              <p style={{ color: "black", fontFamily: "Assistant, sans-serif" }}>צפה כאן בתוצאות המכרז</p>
              <FlexBox>
                <NavLink
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    textDecoration: "none",
                  }}
                  to={`/result/${post.id}`}
                >
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      backgroundColor: "white",
                      color: "black",
                    }}
                  >
                    תוצאות
                  </Button>
                </NavLink>
                <Button
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  variant="text"
                  onClick={(e) => {
                    console.log(e)
                    setResultBid(false)}}
                  startIcon={<Cancel />}
                ></Button>
              </FlexBox>
            </FlexBox>
          )}
          <Box
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginTop: "20px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              {post.title}
            </Typography>
            <Typography
              variant="p"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              {post.area}
            </Typography>
          </Box>
          <FlexBox
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginTop: "20px",
              alignItems: "normal",
            }}
          >
            <ShowBidPost
              post={post}
              skills={skills}
              result={result}
              bidId={id}
              bidderId={post.user_id}
              loadFunction={loadFunction}
            />
            <ShowBidSide
              bidderId={post.user_id}
              bidId={id}
              post={post}
              loadFunction={loadFunction}
              sx={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            />
          </FlexBox>
        </Box>
      )}
    </div>
  );
}

export default ShowBids;
