import Check from "@mui/icons-material/Check";
import FormatBold from "@mui/icons-material/FormatBold";
import FormatItalic from "@mui/icons-material/FormatItalic";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import IconButton from "@mui/joy/IconButton";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import Textarea from "@mui/joy/Textarea";
import { Typography } from "@mui/material";
import * as React from "react";

export default function TextareaValidator({
  notes,
  setNotes,
  what,
  message,
  handleSubmit,
}) {
  const regex =
    /(אפס|אחד|אחת|שניים|שתיים|שלוש|ארבע|חמש|שש|שבע|שמונה|תשע)(\s+(?:אפס|אחד|אחת|שניים|שתיים|שלוש|ארבע|חמש|שש|שבע|שמונה|תשע)){1,}/;
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [error, setError] = React.useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    setError(false);
    if (what === "comment") {
      let comment = notes;
      comment = comment.toLowerCase();
      if (/[0-9]/.test(comment) || regex.test(comment)) {
        setError(true);
      } else {
        setError(false);
        handleSubmit(e);
      }
    } else handleSubmit(e);
  };
  return (
    <FormControl
      sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, marginTop: "20px" }}
    >
      <Typography
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
        variant="h6"
      >
        {message}
      </Typography>
      <Textarea
        placeholder="כתוב תגובה כאן..."
        minRows={3}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        endDecorator={
          <Box
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: "flex",
              gap: "var(--Textarea-paddingBlock)",
              pt: "var(--Textarea-paddingBlock)",
              borderTop: "1px solid",
              borderColor: "divider",
              flex: "auto",
            }}
            name="comment"
          >
            <IconButton
              variant="plain"
              color="neutral"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <FormatBold />
              <KeyboardArrowDown fontSize="md" />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
              size="sm"
              placement="bottom-start"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                "--List-decorator-size": "24px",
              }}
            >
              {["200", "normal", "bold"].map((weight) => (
                <MenuItem
                  key={weight}
                  selected={fontWeight === weight}
                  onClick={() => {
                    setFontWeight(weight);
                    setAnchorEl(null);
                  }}
                  sx={{ fontWeight: weight }}
                >
                  <ListItemDecorator>
                    {fontWeight === weight && <Check fontSize="sm" />}
                  </ListItemDecorator>
                  {weight === "200" ? "lighter" : weight}
                </MenuItem>
              ))}
            </Menu>
            <IconButton
              variant={italic ? "soft" : "plain"}
              color={italic ? "primary" : "neutral"}
              aria-pressed={italic}
              onClick={() => setItalic((bool) => !bool)}
            >
              <FormatItalic />
            </IconButton>
            <Button sx={{ ml: "auto" }} onClick={handleChange}>
              שלח​
            </Button>
          </Box>
        }
      />
      {error && (
        <p style={{ color: "red" }}>לא ניתן להוסיף מספר לקטע התגובות</p>
      )}
    </FormControl>
  );
}
