import { Box, Grid, ImageList, ImageListItem } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";

function ShowBidSide({ post }) {
  const itemData = [
    {
      img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
      title: "Breakfast",
      rows: 2,
      cols: 2,
    },
    {
      img: "https://images.unsplash.com/photo-1551782450-a2132b4ba21d",
      title: "Burger",
    },
    {
      img: "https://images.unsplash.com/photo-1522770179533-24471fcdba45",
      title: "Camera",
    },
    {
      img: "https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c",
      title: "Coffee",
      cols: 2,
    },
    {
      img: "https://images.unsplash.com/photo-1533827432537-70133748f5c8",
      title: "Hats",
      cols: 2,
    },
    {
      img: "https://images.unsplash.com/photo-1558642452-9d2a7deb7f62",
      title: "Honey",
      author: "@arwinneil",
      rows: 2,
      cols: 2,
    },
    {
      img: "https://images.unsplash.com/photo-1516802273409-68526ee1bdd6",
      title: "Basketball",
    },
    {
      img: "https://images.unsplash.com/photo-1518756131217-31eb79b20e8f",
      title: "Fern",
    },
    {
      img: "https://images.unsplash.com/photo-1597645587822-e99fa5d45d25",
      title: "Mushrooms",
      rows: 2,
      cols: 2,
    },
    {
      img: "https://images.unsplash.com/photo-1567306301408-9b74779a11af",
      title: "Tomato basil",
    },
    {
      img: "https://images.unsplash.com/photo-1471357674240-e1a485acb3e1",
      title: "Sea star",
    },
    {
      img: "https://images.unsplash.com/photo-1589118949245-7d38baf380d6",
      title: "Bike",
      cols: 2,
    },
  ];
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: "34%",
        display: {
          xs: "none",
          md: "block",
        },
      }}
    >
      <ImageList
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          width: "100%",
          marginTop: "0px",
        }}
        cols={3}
        rowHeight={164}
      >
        {post.images !== null &&
          post.images
            .split(",")
            .filter((e) => {
              if (e !== ",") return e;
              return "";
            })
            .map((item) => (
              <ImageListItem key={item.img}>
                <img src={item} alt="Lazy" loading="lazy" />
              </ImageListItem>
            ))}
      </ImageList>
      <Grid
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          display: {
            xs: "none",
            md: "inline-block",
          },
        }}
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 2, sm: 8, md: 8 }}
      >
        {Array.from(Array(6)).map((_, index) => (
          <Grid
            item
            xs={2}
            sm={4}
            md={4}
            key={index}
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: {
                sm: "none",
                md: "inline-block",
              },
            }}
          >
            <NavLink to="/showbid" style={{ textDecoration: "none" }}></NavLink>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ShowBidSide;
