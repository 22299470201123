import { Box, Button, TextField, Typography } from "@mui/material";
import JoditEditor from "jodit-react";
import React, { useRef, useState } from "react";
import UploadBid from "./UploadBid";
function PostBidDescription({ bidData }) {
  const ref = useRef(null);
  const [next, setNext] = useState(false);
  const [notes, setNotes] = useState("");
  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    uploader: {
      insertImageAsBase64URI: true,
    },
  };
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    placeholder: "Start typings...",
  };
  const [data, setData] = useState("");
  const [validDesc, setValidDesc] = useState(false);
  const handleChange = (e) => {
    setData(e);
  };
  const handleSubmit = () => {
    if (data.length < 20) setValidDesc(true);
    else {
      bidData.description = data;
      bidData.notes = notes;
      console.log(bidData);
      setNext(true);
    }
  };
  return (
    <div style={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {!next && (
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              textAlign: "center",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            פרסם מכרז עבודה ובדוק שכל השדות מולאו כראוי​
          </Typography>
          <Typography
            variant="h7"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            הזן פירוט של העבודה הרצויה​
          </Typography>
          <Box
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: { xs: "none", md: "block" },
            }}
          >
            <JoditEditor
              tabIn
              style={{
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
              value={data}
              ref={ref}
              config={editorConfig}
              onBlur={(newContent) => handleChange(newContent)}
            />
          </Box>
          <Box
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: { xs: "block", md: "none" },
            }}
          >
            <JoditEditor
              tabIn
              value={data}
              ref={ref}
              style={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              placeholder={"התחל לכתוב..."}
              onBlur={(newContent) => handleChange(newContent)}
            />
          </Box>
          {validDesc && (
            <p
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                color: "red",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              התיאור צריך להיות לפחות 20 תווים
            </p>
          )}
          <Typography
            variant="h7"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            יש לך הערות נוספות? (לא חובה)
          </Typography>
          <TextField
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            required
          />
          <Button
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginTop: "20px",
              width: "100%",
            }}
            variant="contained"
            onClick={handleSubmit}
          >
            פרסם מכרז​
          </Button>
        </Box>
      )}
      {next && <UploadBid bidData={bidData} />}
    </div>
  );
}

export default PostBidDescription;
