import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import * as React from "react";

export default function PaginationRounded({ handleChange, page, count }) {
  return (
    <Stack spacing={2} sx={{ float: "right", marginTop: "20px" }}>
      <Pagination
        count={count}
        sx={{ padding: "5px" }}
        page={page}
        onChange={handleChange}
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  );
}
