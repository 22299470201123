import { Comment, Star, AutoAwesome, ThumbDownAlt, Favorite, ThumbUp } from "@mui/icons-material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Avatar, Box, Button } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { green, grey, red, yellow } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { FlexBox } from "./FlexBox";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CommentsExpand() {
  const [result, setResult] = useState([]);
  const [comments, setComments] = useState([]);
  const [postedBids, setPostedBids] = useState([]);
  const [biddedBids, setBiddedBids] = useState([]);
  const [expanded, setExpanded] = React.useState("panel1");
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/gettotalbids`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        console.log(res);
        setPostedBids(res.data.postedBids);
        setBiddedBids(res.data.biddedBids);
        setComments(res.data.Comments);
        let resul = res.data.Result;
        let secondRes = res.data.SecondResult;
        resul = resul.concat(secondRes);
        resul.sort(function (a, b) {
          // console.log(a);
          // console.log(b);
          return parseInt(b.bidId) - parseInt(a.bidId);
        });
        console.log(resul);
        setResult(resul);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box flex={3} p={2} sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <FlexBox
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="text"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
                color: "black",
              }}
            >
              המכרזים הפעילים שלי​ (
              {
                postedBids.filter((e) => new Date().getTime() - e.deadline < 0)
                  .length
              }
              )
            </Button>
			<Avatar sx={{ bgcolor: grey[500] }}><ThumbUp sx={{ color: 'green' }} />​</Avatar>
            
          </FlexBox>
        </AccordionSummary>
        <AccordionDetails>
          {postedBids.filter((e) => new Date().getTime() - e.deadline < 0)
            .length > 0 &&
            postedBids
              .filter((e) => new Date().getTime() - e.deadline < 0)
              .map((e, i) => {
                return (
                  <NavLink to={`/showbid/${e.id}`} style={{ display: "block" }}>
                    https://www.bidit.co.il/showbid/{e.id}
                  </NavLink>
                );
              })}
          {postedBids.filter((e) => new Date().getTime() - e.deadline < 0)
            .length === 0 && (
            <Typography
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              variant="p"
            >
              אין לך מכרזים פעילים​
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <FlexBox sx={{ width: "100%", marginBottom: "20px" }}>
            <Button
              variant="text"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
				color: "black",
              }}
            >
              המכרזים המועדפים שלי​ (
              {Cookies.get("favids").includes("no")
                ? "0"
                : Cookies.get("favids")
                    .split("f")
                    .filter((e) => {
                      if (e !== "f" && e !== "no") return e;
                      return "";
                    }).length}
              )
            </Button>
			<Avatar sx={{ bgcolor: grey[500] }}><Favorite sx={{ color: '#D32F2F' }} />​</Avatar>
           
          </FlexBox>
        </AccordionSummary>
        <AccordionDetails>
          {Cookies.get("favids")
            .split("f")
            .filter((e) => {
              if (e !== "f" && e !== "no") return e;
              return "";
            })
            .map((e, i) => {
              return (
                <NavLink to={`/showbid/${e}`} style={{ display: "block" }}>
                  https://www.bidit.co.il/showbid/{e}
                </NavLink>
              );
            })}
          {Cookies.get("favids").includes("no") && (
            <Typography variant="p">אין לך מכרזים מועדפים</Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <FlexBox
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              width: "100%",
              marginBottom: "20px",
              color: grey[500],
            }}
          >
            <Button
              variant="text"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
				fontWeight: "bold",
				color: "black",
				
              }}
            >
              המכרזים הסגורים שלי​ (
              {
                postedBids.filter((e) => e.deadline - new Date().getTime() < 0)
                  .length
              }
              )
            </Button>
			<Avatar sx={{ bgcolor: grey[500] }}><ThumbDownAlt sx={{ color: grey[800] }} />​</Avatar>
            
          </FlexBox>
        </AccordionSummary>
        <AccordionDetails>
          {postedBids
            .filter((e) => e.deadline - new Date().getTime() < 0)
            .map((e, i) => {
              return (
                <NavLink to={`/showbid/${e.id}`} style={{ display: "block" }}>
                  https://www.bidit.co.il/showbid/{e.id}
                </NavLink>
              );
            })}
          {postedBids.filter((e) => e.lowestBid > 0).length === 0 && (
            <Typography
              variant="p"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              עדיין לא נסגרו הצעות מחיר שלך
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <FlexBox
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="text"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
				color: "black",
				
              }}
            >
              המכרזים הזוכים שלי​ ({result.length})
            </Button>
			<Avatar sx={{ bgcolor: grey[500] }}><AutoAwesome sx={{ color: 'yellow' }} />​</Avatar>     

          </FlexBox>
        </AccordionSummary>
        <AccordionDetails>
          {Array.from(result).map((e, i) => {
            return (
              <NavLink to={`/showbid/${e.bidId}`} style={{ display: "block" }}>
                https://www.bidit.co.il/showbid/{e.bidId}
              </NavLink>
            );
          })}
          {Array.from(result).length === 0 && (
            <Typography
              variant="p"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              לא זכית באף הצעה עד עכשיו.
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <FlexBox
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              width: "100%",
              marginBottom: "20px",
            }}
          >
            <Button
              variant="text"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
                color: "black",
              }}
            >
              הערות​ ({comments.length})
            </Button>
			<Avatar sx={{ bgcolor: grey[500] }}><Comment sx={{ color: 'white' }} />​</Avatar>
            
          </FlexBox>
        </AccordionSummary>
        <AccordionDetails>
          {comments.length > 0 &&
            comments.map((e, i) => {
              return (
                <NavLink
                  to={`/showbid/${e.bidId}`}
                  style={{ display: "block" }}
                >
                  https://www.bidit.co.il/showbid/{e.bidId}
                </NavLink>
              );
            })}
          {comments.length === 0 && (
            <Typography
              variant="p"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              לא הגבת על אף מכרז
            </Typography>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
