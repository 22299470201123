import { Box, Grid } from "@mui/material";
import Cookies from "js-cookie";
import React, { useState } from "react";
import AskForSignUp from "./AskForSignUp";
import Posts from "./Posts";

function ShowPosts({ showPosts, page, filter, what, search1 }) {
  console.log("Hello I am ShowPosts");
  console.log(showPosts);
  console.log(Array.from(showPosts).length);
  // //console.log(Array.from(showPosts).length);
  // for (let i = 0; i < Array.from(showPosts).length; i++)
  //   console.log(showPosts[i].title);
  const [open, setOpen] = useState(false);
  const handelClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Grid
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        display: {
          xs: "block",
          md: "inline-block",
        },
      }}
      container
      spacing={{ xs: 2, sm: 2, lg: 3 }}
      columns={{ xs: 2, sm: 6, lg: 12 }}
    >
      {Array.from(
        Array.from(showPosts)
          .reverse()
          .filter(function (post) {
            return what === "closed"
              ? post
              : Math.ceil(
                  (post.deadline - new Date().getTime()) / (60 * 60 * 1000)
                ) > 0;
          })
          .slice(page * 18 - 18, 18 * page)
      )
        .filter((e) => {
          if (filter.area === "הכל​") return e;
          else if (filter.area.toString().includes(e.area.toString())) return e;
          else return "";
        })
        .filter((e) => {
          if (filter.profession === "הכל​") return e;
          else if (e.skills.toString().includes(filter.profession.toString()))
            return e;
          else return "";
        })
        .filter((post) => {
          console.log(search1);
          if (search1 === "no") return post;
          else if (
            post.title.includes(search1) ||
            post.description.includes(search1) ||
            post.bidderName.includes(search1)
          )
            return post;
          return "";
        })
        .map((post, index) => (
          <Grid
            item
            xs={2}
            sm={3}
            md={4}
            key={index}
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: {
                xs: "block",
                sm: "inline-block",
              },
            }}
          >
            {Cookies.get("token") !== null &&
            Cookies.get("token") !== undefined ? (
              <Posts post={post} bid={true} />
            ) : (
              <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
                <Box
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  onClick={handleOpen}
                >
                  <Posts post={post} bid={true} />
                </Box>
                {open && (
                  <AskForSignUp
                    open={open}
                    handleClose={handelClose}
                    handleOpen={handleOpen}
                  />
                )}
              </Box>
            )}
          </Grid>
        ))}
    </Grid>
  );
}

export default ShowPosts;
