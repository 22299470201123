import { Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import { NavLink, Navigate, useParams } from "react-router-dom";
import AskModal from "./AskModal";
import SimpleBackdrop from "./BackDrop";


// const steps = [
//   "Select master blaster campaign settings",
//   "Create an ad group",
//   "Create an ad",
// ];

export default function Results({ bidId }) {
  const [noResult, setNoResult] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [open2, setOpen2] = React.useState(false);
  const handleBackDrop = (e) => {
    setOpen2(e);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bidEnded, setBidEnded] = useState(true);
  const { id } = useParams();
  const [result, setResult] = React.useState({});
  const [wait, setWait] = useState(false);
  const [userId, setUserId] = useState(-1);
  const [step, setStep] = useState(0);
  const changeStep = (e) => {
    if (e === 3) {
      console.log("Hello I am Abid it did happen!");
      let month = parseInt(new Date().getMonth()) + 1;
      axios
        .post(
          `${process.env.React_APP_BACKEND_URL}/api/postdeal`,
          {
            id: id,
            date:
              new Date().getDate() +
              "/" +
              month +
              "/" +
              new Date().getFullYear(),
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .then((res) => console.log(res))
        .catch((err) => console.log(err));
    }
    setStep(e);
  };
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getresult?id=${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        setBidEnded(
          res.data.Bid[0].deadline - new Date().getTime() < 0 ? true : false
        );
        setPhoneNumber(res.data.JobAdverter);
        if (res.data.Result.length !== 0) {
          if (res.data.Result[0].major.toString() !== "3") {
            if (res.data.Result[0].username === "no") {
              setNoResult(true);
            } else {
              setResult(res.data.Result[0]);
              if (res.data.Result[0].bidderaccept === "null") setStep(0);
              else if (res.data.Result[0].proaccept === "null") setStep(1);
              else setStep(3);
            }
          } else {
            if (
              res.data.Result[1].username === "no" ||
              res.data.Result[1].major.toString() === "3"
            ) {
              setNoResult(true);
            } else {
              console.log(res.data.Result[1].major.toString());
              setResult(res.data.Result[1]);
              if (res.data.Result[1].bidderaccept === "null") setStep(0);
              else if (res.data.Result[1].proaccept === "null") setStep(1);
              else setStep(3);
            }
          }
        } else {
          setWait(true);
        }

        setUserId(res.data.UserId.user_id);
      })
      .catch((err) => console.log(err));
  }, [id, step]);
  const [isReview, setIsReview] = useState(false);
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: "100%",
        marginTop: "20px",
		display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      dir="ltr"
    >
      {userId !== -1 && bidEnded && !wait && !noResult && (
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <Stepper
            activeStep={step}
            alternativeLabel
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, width: "100%" }}
          >
            <Step>
              <StepLabel
                dir="rtl"
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              >
                {step === 0 ? (
                  <Button
                    sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                    variant={
                      Cookies.get("id").toString() === userId.toString()
                        ? "contained"
                        : "disabled"
                    }
                    onClick={handleOpen}
                  >
                    לקוח - לחץ כאן​
                  </Button>
                ) : (
                  <Typography
                    variant="p"
                    dir="rtl"
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      color: "#1976D2",
                      fontWeight: "bold",
                      fontSize: "105%",
                    }}
                  >
					ההצעה אושרה על ידי הלקוח​
					והועברה לאישור בעל המקצוע
                  </Typography>
                )}
              </StepLabel>
            </Step>
            <Step>
              <StepLabel dir="rtl">
                <Button
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  variant={
                    Cookies.get("id").toString() ===
                      result.bidderId.toString() && step === 1
                      ? "contained"
                      : "disabled"
                  }
                  onClick={handleOpen1}
                >
                  בעל המקצוע - לחץ כאן
                </Button>
              </StepLabel>
            </Step>
            <Step>
              <StepLabel dir="rtl">
                <Typography variant="h6" style={{ fontFamily: 'Assistant' }}>
                  {step === 3 &&
                  Cookies.get("id").toString() === result.bidderId.toString()
                    ? phoneNumber
                    : "*********"}
                </Typography>
              </StepLabel>
            </Step>
            <Step
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: { xs: "none", md: "block" },
              }}
            >
              <StepLabel dir="rtl">
                {result.review === "no" ? (
                  <Button
                    sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                    dir="rtl"
                    variant={
                      Cookies.get("id").toString() === userId.toString() &&
                      result.proaccept !== null &&
                      step === 3
                        ? "contained"
                        : "disabled"
                    }
                    onClick={(e) => setIsReview(true)}
                  >
                    לקוח – מלא חוות דעת​
                  </Button>
                ) : (
                  <Typography
                    dir="rtl"
                    variant="p"
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      color: "#1976D2",
                      fontWeight: "bold",
                      fontSize: "105%",
                    }}
                  >
                    ניתנה ביקורת
                  </Typography>
                )}
              </StepLabel>
            </Step>
          </Stepper>
          <Box
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: { xs: "block", md: "none", marginTop: "20px" },
			  textAlign: "center",
            }}
            dir="rtl"
          >
		  
            <Typography
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                //textAlign: "center",
				marginTop: "20px",
              }}
            >
              חוות דעת לקוח
            </Typography>
			
            {result.review === "no" ? (
              <Button
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, width: "100%", marginTop: "10px" }}
                dir="rtl"
                variant={
                  Cookies.get("id").toString() === userId.toString() &&
                  result.proaccept !== null &&
                  step === 3
                    ? "contained"
                    : "disabled"
                }
                onClick={(e) => setIsReview(true)}
              >
                לקוח - מלא חוות דעת​
              </Button>
            ) : (
              <Typography
                dir="rtl"
                variant="p"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  color: "#1976d2",
                  fontWeight: "bold",
                  fontSize: "105%",
				  marginTop: "10px",
				  
                }}
              >
                ניתנה ביקורת
              </Typography>
            )}

          </Box>
        </Box>
      )}
      {userId !== -1 && !bidEnded && (
        <Box sx={{ width: "70%", margin: "0px auto" }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            המכרז נמצא בתהליך עיבוד נתונים ותוצאות. בדוק שוב בעוד מספר דקות.{" "}
			<NavLink to="/home"> חזור </NavLink>
          </Typography>
        </Box>
      )}
      {wait && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            width: "70%",
            margin: "0px auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{ 
				fontFamily: `${process.env.REACT_APP_FONT}`,
				textAlign: 'right',
			}}
          >
            המכרז נמצא בתהליך עיבוד נתונים ותוצאות. בדוק שוב בעוד מספר דקות. 
            <NavLink to="/home"> חזור </NavLink>
          </Typography>
        </Box>
      )}
      {noResult && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            width: "70%",
            margin: "0px auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{ 
				fontFamily: `${process.env.REACT_APP_FONT}`,
				textAlign: 'right',
			}}
          >
            מצטערים, המכרז הסתיים ללא זכיה. 
            <NavLink to="/home"> חזור</NavLink>
          </Typography>
        </Box>
      )}
      {open && (
        <AskModal
          open={open}
          setOpen2={handleBackDrop}
          id={id}
          handleOpen={handleOpen}
          handleClose={handleClose}
          mes={"Offer Accept"}
          result={result}
          changeStep={changeStep}
        />
      )}
      {open1 && (
        <AskModal
          open={open1}
          id={id}
          setOpen2={handleBackDrop}
          handleOpen={handleOpen1}
          handleClose={handleClose1}
          mes={"Job Accept"}
          result={result}
          changeStep={changeStep}
        />
      )}
      {open2 && (
        <SimpleBackdrop
          open={open2}
          setOpen={setOpen2}
          handleClose={handleClose2}
          handleToogle={handleToggle}
        />
      )}
      {isReview && <Navigate to={`/review/${id}`}></Navigate>}
    </Box>
  );
}
