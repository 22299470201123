import {
  Box,
  Button,
  Modal,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { professions } from "../asset/js/Professions";
const animatedComponents = makeAnimated();
function ProfessionModal({
  open,
  admin,
  handleClose,
  user,
  addChangedUser,
  updatePage,
}) {
  const professionUser =
    user.profession !== null
      ? user.profession.split(",").map((e) => {
          return { value: e, label: e };
        })
      : "";
  console.log(professionUser);
  const [value, setValue] = React.useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: 300,
      md: 400,
    },
    fontFamily: `${process.env.REACT_APP_FONT}`,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };
  const [selectedOptions, setSelectedOptions] = React.useState(
    user.profession !== null
      ? user.profession.split(",").map((e) => {
          return { value: e, label: e };
        })
      : ""
  );
  let options = [];

  Array.from(professions).forEach((e) => {
    options.push({ value: e, label: e });
  });
  let options1;
  if (user.profession !== null)
    options1 = options.concat(
      user.profession.split(",").map((e) => {
        return { value: e, label: e };
      })
    );
  else options1 = options;
  options = Array.from(new Set(options1));
  const [textare, setTextArea] = useState(
    "באפשרותך להוסיף מקצוע שאינו ברשימה (הפרד עם פסיק "
  );
  const [additionalSkills, setAdditionalSkills] = useState("");
  const ProfessionBox = styled(Box)({
    backgroundColor: "#E8F4FF",
    padding: "10px",
    display: "inline-block",
    margin: "10px",
    marginTop: "0px",
    boxShadow: 2,
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const StyledBox = styled(Box)({
    marginTop: "10px",
    float: "none",
    marginBottom: "10px",
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const [invalid, setInvalid] = useState(false);
  const handleSubmit = () => {
    let profession = "";
    for (let i = 0; i < selectedOptions.length; i++) {
      profession += selectedOptions[i].value + ",";
    }
    profession += additionalSkills;
    console.log(profession);

    if (profession !== user.profession) {
      if (admin === "yes") {
        axios
          .put(
            `${process.env.React_APP_BACKEND_URL}/api/admin/updateuser?id=${user.id}`,
            {
              profession,
            },
            {
              headers: { Authorization: `Bearer ${Cookies.get("admintoken")}` },
            }
          )
          .then((res) => {
            addChangedUser();

            handleClose("hd");

            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
        return;
      }
      axios
        .put(
          `${process.env.React_APP_BACKEND_URL}/api/updateuser?id=${user.id}`,
          {
            profession,
          },
          { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
        )
        .then((res) => {
          addChangedUser();
          Cookies.set("profession", profession, { expires: 365 });
          handleClose("hd");

          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Modal
      open={true}
      onClose={(e) => handleClose("profession")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          בחר מקצוע חדש​
        </Typography>
        <Select
          components={animatedComponents}
          isMulti
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          name="professions"
          value={selectedOptions}
          options={options}
          onChange={(item) => {
            setSelectedOptions(item);
          }}
          className="select"
          isClearable={true}
          isSearchable={true}
          isDisabled={false}
          isLoading={false}
          onMenuOpen={() => setTextArea("")}
          onMenuClose={() =>
            setTextArea(
              "במידה ובעל המקצוע שאתה מחפש אינו ברשימה, הזן את המקצוע שלו כאן"
            )
          }
          closeMenuOnSelect={false}
        />

        <TextField
          margin="normal"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginBottom: "20px",
            marginTop: "30px",
          }}
          fullWidth
          id="addSkills"
          value={additionalSkills}
          onChange={(e) => setAdditionalSkills(e.target.value)}
          label={textare}
          name="addSkills"
          autoComplete="addSkills"
        />
        <Button
          onClick={handleSubmit}
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          variant="contained"
        >
          עדכן​
        </Button>
      </Box>
    </Modal>
  );
}

export default ProfessionModal;
