import {
  Avatar,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

function UpdateBidded({ open, handleClose, data, updateBid, post }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    fontFamily: `${process.env.REACT_APP_FONT}`,
  };
  const [invalid, setInvalid] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setInvalid(false);

    let data1 = new FormData(e.currentTarget);
    if (
      (parseInt(data1.get("bid")) > data && data !== 0) ||
      data1.get("bid") <= 10
    ) {
      setInvalid(true);
    } else {
      handleClose();
      updateBid(data1.get("bid"));
    }
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Avatar
          sx={{ width: 30, height: 30, textAlign: "center", marginLeft: "46%" }}
          src="https://images.pexels.com/photos/846741/pexels-photo-846741.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        />
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Bid
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="p"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            display: "inline-block",
          }}
        >
          Lowest Bid is ${post.lowestBid}. You have to update your bid lower
          than that to win the bid.
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            display: "flex",
            gap: "2",
            alignItems: "center",
            marginTop: "10px",
          }}
        >
          <TextField
            id="bid"
            label="Enter Bid"
            name="bid"
            variant="outlined"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: "inline-block",
              marginRight: "10px",
            }}
          />

          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
        {invalid && (
          <p
            style={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              color: "red",
              marginTop: "20px",
            }}
          >
            Need to be less than {data} or more than 10
          </p>
        )}
      </Box>
    </Modal>
  );
}

export default UpdateBidded;
