import { Box } from "@mui/material";
import HeroSection from "./HeroSection";
import React, { useEffect } from "react";
import Working from "./Working";
import HowRegister from "./HowRegisterUser";
import Youtube from "./Youtube";
import Support from "./Support";
import AccordionNoDivider from "./FaqQuestions";
import FaqComponent from "./FaqQuestions";
import Faq from "./Faq";
import user from "../../../asset/images/user-profile.svg";
import best from "../../../asset/images/best.svg";
import television from "../../../asset/images/television.svg"
import Convinient from "./Convinient";
import Footer from "../../Footer";

export default function LandingPage(){
  let prof={current:false}
    

    const landingPageuser={
        heroHeader:"מחפש בעל מקצוע או נותן שירות?",
        heroSecond:"באתר שלנו תוכל לפרסם מודעות לביצוע עבודות ביתיות בשיטת מכרז.",
		heroThird:"בעלי מקצוע יתנו לך הצעות מחיר, כאשר בסיום המכרז, ההצעה הטובה ביותר היא זו שתזכה!",
        workingFirst:"פשוט, נגיש ונוח...",
        workingSecond:"מפרסמים מתי שרוצים לכמה זמן שבוחרים :)",
        workingThird:"המכרז מבצע עבורך את המיקוח על המחיר!",
        howReg:{
            firstTitle:"פתח פרופיל",
            firstSub:"פתח פרופיל באתר שלנו. מלא את הפרטים שלך.",
            secondTitle:"פרסם מכרז לעבודה",
            secondSub:"פרסם באתר מודעת מכרז לעבודה שאתה צריך שיבצעו אותה עבורך. הגדר כמה זמן תרצה שהמכרז יהיה פעיל.",
            thirdTitle:"ביצוע העבודה",
            thirdSub:"לאחר שיוכרז בעל המקצוע הזוכה, תצטרך לאשר אותו ואת ההצעה שלו. רק לאחר מכן בעל המקצוע הזוכה יצור איתך קשר לתיאום של תחילת העבודה!",
        },
		
	        supportList:[
          "ממשק ידידותי, פשוט ונוח.",
          "מפרסמים מודעת מכרז ונותנים לה לעבוד בשבילך!",
          "חסכון בזמן - מודעת המכרז שלך זוכה לחשיפה של מאות אנשי מקצוע.",
          "מקבלים התראות במייל על כל הצעה חדשה או הערה במכרז.",
          "ההצעה שקיבלת או בעל המקצוע שזכה אינו לטעמך? תוכל להתחיל מחדש.",
  
      ]	
		
    }
    const landingPageProf={
        heroHeader:"Add Your sub paragrpah text here",
        heroSecond:"Add Your sub paragrpah text here",
        workingFirst:"Add your text here",
        workingSecond:"Add your text here",
        workingThird:"Add your text here",
        howReg:{
            firstTitle:"Add your Header here",
            firstSub:"Add your text here",
            secondTitle:"Add your Header here",
            secondSub:"Add your text here",
            thirdTitle:"Add your Header here",
            thirdSub:"Add your text here",
        },
		
		       supportList:[
          "Free Website Migration",
          "Free Website Migration",
          "Free Website Migration",
          "Free Website Migration",
          "Free Website Migration",
  
      ]
    }

    const faqUserQuestions = [
        {
          question: 'מה זה בידאיט?',
          answer: 'בידאיט היא פלטפורמה למציאת בעלי מקצוע בצורת מכרז - באופן פשוט, נוח והוגן. כל אחד יכול לפתוח מכרז, מוגבל בזמן, על עבודה שהוא מעוניין שיבצעו אותה עבורו. כל בעל מקצוע יכול להצטרף ולתת הצעת מחיר ולזכות במכרז.',
        },
        {
          question: 'כמה השירות הזה עולה?',
          answer: 'השירות לא עולה כסף. הוא בחינם לבעל המקצוע וללקוח.',
        },
        {
            question: 'איך מצטרפים כמבקש שירות?',
            answer: 'מתחילים את תהליך הרישום באתר במחשב או במובייל, ע"י לחיצה על "הרשמה ללקוחות". ממלאים את כל הפרטים, בדגש על כתובת הדוא"ל והטלפון. חשוב להזין טלפון אמיתי, כיוון שזה האמצעי שבעל המקצוע יתקשר אליו במידה והעסקה ביניכם תאושר. ',
          },
          {
            question: 'איך אני מפרסם מודעת מכרז?',
            answer: 'בדף הראשי, או בתפריט חפש את הכפתור "פרסם מכרז". מלא את הסעיפים שמופיעים מולך. בראש כל סעיף ישנו הסבר פשוט. כדי שבעל המקצוע ייתן הצעה הוגנת, חשוב לפרט כמה שיותר על העבודה שאתם צריכים. חשוב לזכור שהוספה של תמונות מעוררת עניין אצל בעלי המקצוע ונותנת פרטים נוספים על העבודה, ולכן מומלץ לצלם את מושא העבודה. המכרז שלך יעבור לאישור מהיר שלנו, ויפורסם באתר.',
          },
          {
            question: 'איך רואים את תוצאות המכרז?',
            answer: 'כשמכרז מסתיים, הלקוח ובעל המקצוע הזוכה יקבלו הודעה על כך במייל. כאשר תכנסו למכרז, תראו שנוצר לו כפתור "תוצאות". לחיצה עליו תראה את "סרגל ההתקדמות" של העסקה. בשלב ראשון הלקוח צריך לאשר את ההצעה, ורק לאחר מכן יוכל בעל המקצוע לאשר מצדו את ההצעה. כשבעל המקצוע יאשר, ייחשף בפניו מספר הטלפון של הלקוח כדי שהוא יוכל ליצור איתו קשר.',
          },
          {
            question: 'יש לי עוד שאלות...',
            answer: 'תוכל לעיין בסעיף "שאלות נפוצות" באתר שלנו, בתחתית המסך. יש שם תשובות להמון שאלות שיכול להיות שמעניינות אותך. לא קיבלת תשובה? תוכל ליצור איתנו קשר, גם בתחתית המסך תחת "דיגיטל ויצירת קשר".',
          },
		  
      ];
      const faqProfQuestions = [
        {
          question: 'What is the first question?',
          answer: 'This is the answer to the first question. It can be a long answer that will be displayed within the elevated section.',
        },
        {
          question: 'How does the second question work?',
          answer: 'This is the answer to the second question. It can also be a lengthy answer that fits within the elevated section.',
        },
        {
            question: 'How does the second question work?',
            answer: 'This is the answer to the second question. It can also be a lengthy answer that fits within the elevated section.',
          },
          {
            question: 'How does the second question work?',
            answer: 'This is the answer to the second question. It can also be a lengthy answer that fits within the elevated section.',
          },
      ];

      const conviDataUser=[
        {
            text:"פותחים פרופיל",
            image:user,
        },
        {
            text:"מפרסמים באתר מודעת מכרז",
            image:television,
        },
        {
            text:"סוגרים את העסקה הטובה ביותר",
            image:best,
        }
      ]
      const conviDataProf=[
        {
            text:"Add your text here",
            image:user,
        },
        {
            text:"Add your text here",
            image:television,
        },
        {
            text:"Add your text here",
            image:best,
        }
      ]
    return (<Box>
    <Box sx={{margin:"20px auto",width:{xs:"90%",md:"75%"}}}>
        <HeroSection prof={prof.current} text={prof.current?landingPageProf:landingPageuser}/>
        <Working text={prof.current?landingPageProf:landingPageuser}/>
        <HowRegister text={prof.current?landingPageProf:landingPageuser} prof={prof.current}/>
        <Youtube prof={prof.current}/>
        <Support text={prof.current?landingPageProf:landingPageuser} prof={prof.current}/>
        <Faq faq={prof.current?faqProfQuestions:faqUserQuestions} prof={prof.current}/>
        <Convinient conviData={prof.current?conviDataProf:conviDataUser} prof={prof.current}/>
    
    </Box>
        <Footer/>
        </Box>)
}