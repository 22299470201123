import { Box, Stack } from "@mui/material";
import JoinUs from "./JoinUsButton";

export default function Convinient({conviData,prof}){
    return(<Box sx={{margin:"30px auto"}}>
	    <h1 style={{margin:"20px auto",textAlign:'center', fontFamily: 'Assistant'}}>שכנעתם, אני רוצה להצטרף! מה עושים?</h1>
     <Stack sx={{flexDirection:{xs:"column",md:"row",alignItems:'center',justifyItems:'center'}}} gap="20px">
        {conviData.map(e=>{
            return(<Box sx={{flex:1}}>
                <img src={e.image}alt={e.text} style={{display:"block",width:"50px",height:"50px",margin:"0px auto"}}/>
                <p style={{marginTop:'10px',textAlign:"center",fontSize:"18px", fontFamily:"Assistant"}}>{e.text}</p>
            </Box>)
        })}
     </Stack>
     <Box sx={{margin:"40px auto"}}>
       <JoinUs prof={prof}text={"התחל הרשמה >"}/>
     </Box>
    </Box>)
}