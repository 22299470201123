import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SimpleBackdrop from "./BackDrop";

function ChangePhone() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [phone, setPhone] = useState();
  const [next1, setNext1] = useState(false);
  const [inValidPhone, setInValidPhone] = useState();
  const handleSubmit = () => {
    setOpen(true);
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/changephone`,
        {
          phone,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        setOpen(false);
        setNext1(true);
      })
      .catch((err) => {
        console.log(err);
        setOpen(false);
        setInValidPhone(true);
      });
  };
  return (
    <Box noValidate sx={{ fontFamily: "Assistant", mt: 1 }}>
      {!next1 && (
        <Box
          sx={{ fontFamily: "Assistant", width: "70%", margin: "20px auto" }}
        >
          <Typography variant="h6" sx={{ fontFamily: "Assistant" }}>
            הזן מספר טלפון חדש​
          </Typography>
          <Typography
            variant="p"
            sx={{ fontFamily: "Assistant", fontWeight: "bold" }}
          >
            {" "}
            הזן מספר טלפון חדש
          </Typography>
          ​
          <TextField
            margin="normal"
            required
            fullWidth
            onChange={(e) => setPhone(e.currentTarget.value)}
            id="phone"
            label="Phone"
            name="phone"
            autoComplete="email"
            //autoFocus
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          />
          {inValidPhone && (
            <p
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                color: "red",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              מספר טלפון כבר קיים
            </p>
          )}
          <Button
            fullWidth
            onClick={handleSubmit}
            variant="contained"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2 }}
          >
            עדכן​
          </Button>
        </Box>
      )}
      {next1 && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            width: "70%",
            margin: "20px auto",
          }}
        >
          <Typography variant="h6" sx={{ fontFamily: "Assistant" }}>
            {" "}
            מספר הטלפון שלך עודכן. <NavLink to="/">לחץ כאן</NavLink>
          </Typography>
        </Box>
      )}
      {open && (
        <SimpleBackdrop
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleToogle={handleToggle}
        />
      )}
    </Box>
  );
}

export default ChangePhone;
