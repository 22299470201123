import { Button, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';

function SendNoti(props) {
 const sendNotification=()=>{
  axios.get("https://laravelapi.bidit.co.il/api/sendwhatsAppmessage?type=custo").then(res=>{
   window.location.reload();
  }).catch(err=>console.log(err));
 }

 const sendNotificationProf=()=>{
  axios.get("https://laravelapi.bidit.co.il/api/sendwhatsAppmessage?type=prof").then(res=>{
   window.location.reload();
  }).catch(err=>console.log(err));
 }
 return (
  <div style={{marginTop:"30px", marginBottom:"30px"}}>
   <Typography variant='h5'>Send Whatsapp Message to the customers</Typography>
   <Button sx={{mt:5,mb:5}} variant='contained'onClick={sendNotification}>Send Message</Button>

   <Typography variant='h5'>Send Whatsapp Message to the professionals</Typography>
   <Button variant='contained'onClick={sendNotificationProf} sx={{mt:5,mb:5}}>Send Message</Button>
  </div>
 );
}

export default SendNoti;