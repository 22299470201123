import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import ArticleShow from "./ArticleShow";
import AskForSignUp from "./AskForSignUp";
import PaginationRounded from "./PaginationRounded";
import Footer from "./Footer";

function ShowArticles() {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => setPage(value);
  const [allArticles, setAllArticles] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getarticles`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data.Articles);
        setAllArticles(response.data.Articles);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box>
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        display: "flex",
        flexDirection: "column",
        margin: "20px",
		
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          textAlign: "center",
          marginBottom: "20px",
        }}
      >
        המאמרים של בידאיט!
      </Typography>

      <Grid
        container
        spacing={2}
        columns={{ xs: 2, sm: 6, md: 12 }}
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          flex: 1,
        }}
      >
        {allArticles
          .slice(page * 18 - 18, 18 * page)
          .map((article, index) => (
            <Grid
              item
              xs={2}
              sm={3}
              md={4}
              key={index}
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
              }}
            >
              {Cookies.get("token") ? (
                <ArticleShow article={article} bid={true} />
              ) : (
                <Box
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                  }}
                  onClick={handleOpen}
                >
                  <ArticleShow article={article} bid={true} />
                </Box>
              )}
            </Grid>
          ))}
      </Grid>

      <PaginationRounded
        handleChange={handleChange}
        page={page}
        count={parseInt(allArticles.length / 18) + 2}
        sx={{ marginTop: "auto", marginBottom: "20px" }}
      />


		<Box sx={{ flex: 1 }} />
     
      <AskForSignUp open={open} handleClose={handleClose} handleOpen={handleOpen} />
    </Box>
    <Footer/>
    </Box>
  
  );
}

export default ShowArticles;
