import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";

import React, { useState } from "react";
import { FlexBox } from "./FlexBox";

function ChangeNameModal({ open, handleClose, admin, user }) {
  const [value, setValue] = React.useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: 270,
      md: 400,
    },
    fontFamily: "Assistant",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };

  const [invalid, setInvalid] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Hello");
    setInvalid(false);
    const data = new FormData(e.currentTarget);
    if (data.get("name") < 3 || data.get("last_name") < 3) setInvalid(true);
    else {
      if (admin === "yes") {
        axios
          .put(
            `${process.env.React_APP_BACKEND_URL}/api/admin/changename`,
            {
              name: data.get("name"),
              last_name: data.get("last_name"),
              id: user.id,
            },
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("admintoken")}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            setInvalid(false);
            Cookies.set("name", data.get("name"), { expires: 365 });
            Cookies.set("last_name", data.get("last_name"), { expires: 365 });
            handleClose("name");
          })
          .catch((err) => console.log(err));
        return;
      }

      axios
        .put(
          `${process.env.React_APP_BACKEND_URL}/api/changename`,
          {
            name: data.get("name"),
            last_name: data.get("last_name"),
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setInvalid(false);
          Cookies.set("name", data.get("name"), { expires: 365 });
          Cookies.set("last_name", data.get("last_name"), { expires: 365 });
          handleClose("name");
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <Modal
      open={open}
      onClose={(e) => handleClose("name")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontFamily: "Assistant",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          שנה שם​
        </Typography>
        <Box
          component="form"
          sx={{ fontFamily: "Assistant" }}
          onSubmit={handleSubmit}
        >
          <FlexBox
            sx={{
              fontFamily: "Assistant",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              variant="p"
              sx={{ fontFamily: "Assistant", fontWeight: "bold" }}
            >
              שם פרטי
            </Typography>
            <TextField
              margin="normal"
              required
              sx={{
                fontFamily: "Assistant",
                marginLeft: "5px",
                marginRight: "5px",
              }}
              id="text"
              name="name"
              autoComplete="name"
            />
            <Typography variant="p" sx={{ fontWeight: "bold" }}>
              שם משפחה
            </Typography>
            <TextField
              margin="normal"
              required
              id="text"
              label=""
              sx={{ fontFamily: "Assistant" }}
              name="last_name"
              autoComplete="name"
            />
          </FlexBox>
          {invalid && (
            <p
              style={{
                fontFamily: "Assistant",
                color: "red",
                fontWeight: "bold",
              }}
            >
              השם צריך להיות יותר מ-2 תווים
            </p>
          )}
          <Button
            variant="contained"
            type="submit"
            sx={{ fontFamily: "Assistant", marginTop: "20px" }}
          >
            עדכן​
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ChangeNameModal;
