import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
import React from "react";

function ToDo() {
  let token = "";
  return (
    <div>
      <GoogleLogin
        onSuccess={(response) => {
          token = response.credential;
          console.log(jwtDecode(response.credential));
        }}
        onError={() => {
          console.log("Login Failed");
        }}
      />
    </div>
  );
}

export default ToDo;
