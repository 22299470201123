import {
  Box,
  Button,
  InputLabel,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { professions } from "../asset/js/Professions";
import PostBidUploadImages from "./PostBidUploadImages";
const animatedComponents = makeAnimated();

function PostBidGeneralInfo({ bidData }) {
  const [area, setArea] = React.useState("");
  const [time, setTime] = React.useState(1);
  const [next, setNext] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  let options = [];
  Array.from(professions).forEach((e) => {
    options.push({ value: e, label: e });
  });
  const handleChange = (event) => {
    const change = event.target.value;

    if (event.target.name === "location") setArea(change);
    else {
      setTime(change);
    }
  };
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Assistant",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  const [error, setError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    setError(false);
    const data = new FormData(event.currentTarget);

    let profession = "";

    selectedOptions.forEach((e, i) => {
      const p = e.value;
      //  console.log(p);
      if (i === selectedOptions.length - 1) profession += p;
      else profession += p + ",";
    });
    if (data.get("addSkills").length === 0 && selectedOptions.length === 0)
      setError(true);
    else {
      if (data.get("addSkills").length > 0) {
        profession += ",";
        let addSkillArr = data.get("addSkills").split(",");
        addSkillArr.forEach((e, i) => {
          if (i === addSkillArr.length - 1) profession += e;
          else profession += e + ",";
        });
      }
      bidData.skills = profession;
      setNext(true);
    }
  };
  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {!next && (
        <Box
          component="form"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          onSubmit={handleSubmit}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              textAlign: "center",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            פרסם מכרז עבודה ובדוק שכל השדות מולאו כראוי​
          </Typography>
          <InputLabel
            id="skills"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginTop: "20px",
            }}
          >
            בחר את בעל המקצוע הנדרש לעבודה הזו​
          </InputLabel>
          <Select
            style={{ fontFamily: "Assistant" }}
            components={animatedComponents}
            isMulti
            name="professions"
            options={options}
            onChange={(item) => {
              setSelectedOptions(item);
            }}
            className="select"
            isClearable={true}
            isSearchable={true}
            isDisabled={false}
            isLoading={false}
            closeMenuOnSelect={false}
          />
          <ThemeProvider theme={theme}>
            <InputLabel sx={{ marginTop: "30px", marginBottom: "10px" }}>
              לא מצאת את בעל המקצוע? רשום אותו כאן
            </InputLabel>
            <TextField
              margin="normal"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginBottom: "20px",
                marginTop: "1px",
              }}
              fullWidth
              id="addSkills"
              name="addSkills"
              autoComplete="addSkills"
            />
          </ThemeProvider>
          {error && (
            <p
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                color: "red",
              }}
            >
              יש לבחור מקצוע אחד לפחות
            </p>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2 }}
          >
            הבא​
          </Button>
        </Box>
      )}

      {next && <PostBidUploadImages bidData={bidData} />}
    </Box>
  );
}

export default PostBidGeneralInfo;
