import {
  Box,
  Button,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState } from "react";

function BidModal({ open, handleClose, data, giveBid }) {
  const [value, setValue] = React.useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: 250,
      md: 400,
    },
    fontFamily: "Assistant",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
  };
  const [invalid, setInvalid] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setInvalid(false);

    let data1 = new FormData(e.currentTarget);
    console.log(data1.get("bid"));
    if (data1.get("bid") > 10) {
      if (
        (parseInt(data1.get("bid")) >= data && parseInt(data) !== 0) ||
        parseInt(Math.abs(parseInt(data1.get("bid")) - data)) < 10
      ) {
        console.log(Math.abs(data1.get("bid")) - data);
        setInvalid(true);
      } else {
        handleClose();
        giveBid(data1.get("bid"));
      }
    } else setInvalid(true);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            textAlign: "center",
            marginBottom: "10px",
            fontFamily: "Assistant",
          }}
        >
          קדימה תן הצעה​
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="p"
          sx={{ display: "inline-block", fontFamily: "Assistant" }}
        >
          {data.toString() === "0"
            ? "מה ההצעה הכי טובה שלך?"
            //: ` ההצעה הנמוכה ביותר היא ${data} .  אתה צריך להציע פחות מזה כדי לזכות `}
: ` ההצעה הנמוכה ביותר כרגע היא ${data} ש"ח. כדי לזכות צריך להציע פחות מזה - `}
        </Typography>
        <Stack
          component="form"
          onSubmit={handleSubmit}
          sx={{
            direction: {
              md: "row",
            },
            gap: "2",
            alignItems: "center",
            marginTop: "10px",
            fontFamily: "Assistant",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          >
            הזן הצעה (ללא פסיקים)
          </Typography>
          <TextField
            id="bid"
            name="bid"
            variant="outlined"
            sx={{
              display: "inline-block",
              marginRight: "10px",
              fontFamily: "Assistant",
            }}
          />

          <Button
            variant="contained"
            type="submit"
            sx={{ marginTop: "20px", fontFamily: "Assistant" }}
          >
            שלח
          </Button>
        </Stack>
        {invalid && (
          <p style={{ color: "red", marginTop: "20px" , fontFamily: "Assistant" }}>
            {data === 0
              ? "ההצעה צריכה להיות יותר מ-0"
              : `ההצעה צריכה להיות פחות מ ${data} או יותר מ-0 (לא ניתן להציע פחות מ-11 ש"ח)`}
          </p>
        )}
      </Box>
    </Modal>
  );
}

export default BidModal;
