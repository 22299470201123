import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import { Typography } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import * as React from "react";

export default function CommentDesign({ comment }) {
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          display: "flex",
          marginBottom: "10px",
          gap: 1,
          alignItems: "center",
        }}
      >
        <Avatar sx={{ bgcolor: deepOrange[500] }}>
          {comment.name.toString().charAt(0) +
            comment.name.toString().charAt(1)}
        </Avatar>
        <Typography
          variant="span"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontWeight: "bold",
          }}
        >
          {comment.username}
        </Typography>
        <Typography
          variant="span"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontStyle: "light",
            fontSize: "80%",
            color: "grey",
          }}
        >
          {comment.time}
        </Typography>
      </Box>
      <Typography
        variant="span"
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          fontSize: "90%",
          marginLeft: "50px",
          marginTop: "-100px",
        }}
      >
        {comment.comment}
      </Typography>
    </Box>
  );
}
