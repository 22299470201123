import React from 'react';
import logo from "../../asset/images/logo.png";

const ProgressBar = ({ progress, prof }) => {
  const containerStyle = {
    position: 'relative',
    marginTop: '25px', // Adjust the value to move the progress bar down
    width: '100%',
  };

  const progressBarStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '20px',
    border: '1px solid #ccc',
  };

  const progressStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: `${prof ? 10 * (progress === 12 ? 10 : progress) : 16.67 * (progress === 12 ? 6 : progress)}%`,
    backgroundColor: '#239EE1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
  };

  const imageStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    zIndex: 100,
  };

  return (
    <div style={containerStyle}>
      <div style={progressBarStyle}>
        <img src={logo} alt="Progress" style={imageStyle} />
        <div style={progressStyle}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
