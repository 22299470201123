import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";

const RenderPhotos = ({ source, deleteHandler }) => {
  // console.log("source: ", source);
  return (
    <Grid
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        display: {
          xs: "block",
          sm: "inline-block",
          md: "inline-block",
        },
      }}
      container
      spacing={{ xs: 2, sm: 2, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
    >
      {Array.from(source).map((image, index) => (
        <Grid
          item
          xs={4}
          sm={4}
          md={4}
          key={index}
          sx={{
            display: {
              xs: "block",
              sm: "inline-block",
              md: "inline-block",
            },
          }}
        >
          {!image.type.toString().includes("video") ? (
            <Box key={image} className="image">
              <img
                style={{ width: "100%" }}
                src={image.url}
                height="200"
                alt="upload"
              />
              <Button variant="outlined" onClick={() => deleteHandler(image)}>
                מחק קובץ
              </Button>
            </Box>
          ) : (
            <Box key={image} className="image">
              <video width="100%" height="200px" controls="controls">
                <source src={image.url} type="video/mp4"></source>
              </video>
              <Button variant="outlined" onClick={(e) => deleteHandler(image)}>
                מחק סרטון
              </Button>
            </Box>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
export default RenderPhotos;
