import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import TabPanel from "@mui/joy/TabPanel";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import { Box, Button } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import PayPalPayment from "./PayPalPayment";
export default function Payment() {
  const [plans, setPlans] = React.useState([]);
  const [id, setId] = useState(-1);
  const [complete, setCom] = useState(false);
  const [result, setResult] = useState({});
  const { id2 } = useParams();
  const setComplete = () => {
    setCom(true);
  };
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getplans?id=${id2}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        setPlans(res.data.Plans);
        if (res.data.result[0].major.toString() !== "3")
          setResult(res.data.result[0]);
        else setResult(res.data.result[1]);
      })
      .catch((err) => console.log(err));
  }, [id2]);
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: "80%",
        margin: "0px auto",
      }}
    >
      {Object.keys(result).length > 0 && (
        <Tabs
          size="sm"
          aria-label="Pricing plan"
          defaultValue={0}
          sx={(theme) => ({
            "--Tabs-gap": "0px",
            borderRadius: "lg",
            boxShadow: "sm",
            overflow: "auto",
            border: `1px solid ${theme.vars.palette.divider}`,
          })}
        >
          <TabList
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              "--List-item-radius": "0px",
              borderRadius: 0,
              [`& .${tabClasses.root}`]: {
                fontWeight: "lg",
                flex: 1,
                bgcolor: "background.body",
                position: "relative",
                [`&.${tabClasses.selected}`]: {
                  color: "primary.500",
                },
                [`&.${tabClasses.selected}:before`]: {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  bottom: -1,
                  width: "100%",
                  height: 2,
                  bgcolor: "primary.400",
                },
                [`&.${tabClasses.focusVisible}`]: {
                  outlineOffset: "-3px",
                },
              },
            }}
          >
            <Tab sx={{ py: 1.5 }}>Community</Tab>
            {plans.length > 0 &&
              plans.map((e) => {
                return <Tab>{e.name}</Tab>;
              })}
          </TabList>

          <TabPanel
            value={0}
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, p: 3 }}
          >
            <Typography
              level="inherit"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              Get Started with One time payment. This will give you the
              opportunity to get only this job.
            </Typography>
            <Typography
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              textColor="success.400"
              fontSize="xl3"
              fontWeight="xl"
              my={1}
            >
              $20{" "}
              <Typography
                fontSize="sm"
                textColor="text.secondary"
                fontWeight="md"
              >
                －/job
              </Typography>
            </Typography>
            {Cookies.get("id").toString() === result.bidderId.toString() && (
              <PayPalPayment
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  width: "100",
                }}
                product={{ desc: "Hello I am Abid", cost: "20" }}
                setComplete={setComplete}
                id={id2}
              />
            )}
          </TabPanel>

          {plans.length > 0 &&
            plans.map((e, i) => {
              return (
                <TabPanel
                  value={i + 1}
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, p: 3 }}
                >
                  <Typography
                    level="inherit"
                    sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  >
                    Best for professionals who has the potential and confidence
                    to get jobs at regular basis.
                  </Typography>
                  <Typography
                    textColor="primary.400"
                    fontSize="xl3"
                    fontWeight="xl"
                    my={1}
                  >
                    ${e.cost}{" "}
                    <Typography
                      fontSize="sm"
                      textColor="text.secondary"
                      fontWeight="md"
                    >
                      / dev / month
                    </Typography>
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      width: "100%",
                      marginTop: "20px",
                    }}
                    onClick={(e) => setId(plans[i].id)}
                  >
                    Choose this plan
                  </Button>
                </TabPanel>
              );
            })}
          {id !== -1 && <Navigate to={`/stripe/${id}`}></Navigate>}
          {complete && <Navigate to={`/result/${id2}`}></Navigate>}
        </Tabs>
      )}
    </Box>
  );
}
