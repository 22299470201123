import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export function Copyright(props) {
  return (
    <Typography
      sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <NavLink color="inherit" to="/home" style={{ fontFamily: 'Assistant, sans-serif' }}>
        כל הזכויות שמורות ל- BIDIT
      </NavLink>{" "}
      {new Date().getFullYear()}
      {""}
    </Typography>
  );
}
