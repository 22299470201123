import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import logo from "../asset/images/logo.png";

function ViewNews() {
  const { id } = useParams();
  const [news, setNews] = useState([]);
  const [what, setWhat] = useState(false);
  const [delete1, setDelete] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getanews?id=${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        console.log(res);
        setNews(res.data.news);
        setWhat(true);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const deleteNews = () => {
    axios
      .delete(
        `${process.env.React_APP_BACKEND_URL}/api/admin/deletenews?id=${id}`,
        {
          headers: { Authorization: `Bearer ${Cookies.get("admintoken")}` },
        }
      )
      .then((res) => {
        console.log(res);
        setNews(res.data.news);
        setDelete(true);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {what && !delete1 && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            width: "80%",
            margin: "20px auto",
            textAlign: "center",
          }}
        >
          {/* <img
            src={logo}
            alt="Logo"
            style={{ width: "200px", height: "200px" }}
          ></img> */}
		<Box
			sx={{
				fontFamily: `${process.env.REACT_APP_FONT}`,
				height: "350px",
				background: `url(${logo})`,
				backgroundSize: "contain", // Change to "contain" to fit the logo within the box
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				}}
		></Box>

          <Typography
            variant="h6"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            {news.title}
          </Typography>
          <Typography
            variant="span"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              color: "grey",
              marginTop: "10px",
              marginBottom: "20px",
              display: "block",
            }}
          >
            {news.date}
          </Typography>
          <Typography
            variant="p"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            {news.des}
          </Typography>

          {Cookies.get("adminId") && (
            <Button
              variant="contained"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                marginTop: "20px",
                width: "100%",
              }}
              onClick={deleteNews}
            >
              מחק חדשות
            </Button>
          )}
        </Box>
      )}
      {delete1 && <Navigate to="/home"></Navigate>}
    </Box>
  );
}

export default ViewNews;
