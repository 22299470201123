import { Avatar, Box, Button, Typography } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import axios from "axios";
import Cookies from "js-cookie";
import { default as React, useState } from "react";
import AlertModal from "./AlertModal";
import SimpleBackdrop from "./BackDrop";
import Snackbar1 from "./Snaclbar";
import UpdateBidded from "./UpdateBidded";
function Bidded({ bid, allBids, post }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen1 = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [showSnack, setShowSnack] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [mes, setMes] = useState("");
  const handleClose4 = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const updateBid = (e) => {
    setOpen4(true);
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/updatebidded`,
        {
          id: bid.id,
          bidId: post.id,
          bidderId: Cookies.get(bid.bidderId),
          bidAmount: e,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        setOpen4(false);
        setShowSnack(true);
        setMes("עודכן בהצלחה");
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const blockUser = (e) => {
    handleClose();
    const id = bid.bidderId;
    console.log();
    let lowestBid = "false";
    Array.from(allBids)
      .filter((e) => {
        if (e.bidderId.toString() === bid.bidderId.toString()) return e;
        return "";
      })
      .forEach((e) => {
        if (e.bidAmount.toString() === post.lowestBid.toString())
          lowestBid = "true";
      });

    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/blockuser`,
        {
          id,
          bidId: bid.bidId,
          lowestBid,
        },
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      )
      .then((res) => {
        console.log(res);
        setMes("המשתמש נחסם בהצלחה");
        setShowSnack(true);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box
      sx={{
        display: "flex",
        fontFamily: "Assistant",
        gap: 2,
        alignItems: "right",
        marginBottom: "20px",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {showSnack && <Snackbar1 mes={mes} />}
      <Box
        sx={{
          display: "flex",
          fontFamily: "Assistant",
          gap: 2,
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            fontFamily: "Assistant",
          }}
        >
          <Avatar sx={{ bgcolor: deepOrange[500] }}>
            {bid.username.toString().charAt(0) +
              bid.username.toString().charAt(1)}
          </Avatar>
          <Typography
            variant="span"
            sx={{ fontFamily: "Assistant", fontWeight: "bold" }}
          >
            {bid.username}
          </Typography>
        </Box>
        <Box
          sx={{
            fontFamily: "Assistant",
            display: "inline-block",
            color: "white",
            padding: "5px 10px",
            borderRadius: "10px",
            backgroundColor: "#1976D2",
          }}
        >
          <Typography sx={{ fontFamily: "Assistant" }} variant="span">
            ₪​{bid.bidAmount}
          </Typography>
        </Box>
      </Box>
      {bid.bidderId === parseInt(Cookies.get("id")) ? (
        <Button variant="outlined" onClick={handleOpen3}>
          Update Bid
        </Button>
      ) : (
        ""
      )}
      {parseInt(Cookies.get("id")) === parseInt(post.user_id) ? (
        <Button variant="outlined" onClick={handleOpen1} style={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          חסום את נותן ההצעה הזה​
        </Button>
      ) : (
        ""
      )}
      {open3 && (
        <UpdateBidded
          updateBid={updateBid}
          open={open3}
          post={post}
          handleClose={handleClose3}
          bid={bid}
        />
      )}
      {open && (
        <AlertModal
          handleOpen={handleOpen1}
          handleClose={handleClose}
          open={open}
          blockUser={blockUser}
          what="block"
          message="אתה בטוח שאתה רוצה לחסום את המשתמש הזה?"
        ></AlertModal>
      )}
      {open4 && (
        <SimpleBackdrop
          open={open4}
          setOpen={setOpen4}
          handleClose={handleClose4}
          handleToogle={handleToggle}
        />
      )}
    </Box>
  );
}

export default Bidded;
