import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { NavLink } from "react-router-dom";
import SimpleBackdrop from "./BackDrop";

function ForgotPassword() {
  const [sentOtp, setSentOtp] = useState("");
  const [otp, setValue] = useState("");
  const [err, setErr] = useState("");
  const [next1, setNext1] = useState(false);
  const [password, setPassword] = useState("");
  const [next, setNext] = useState(false);
  const [next2, setNext2] = useState(false);

  const handleChange = (event) => {
    console.log(event);
    setValue(event);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [email, setEmail] = useState("");
  const handleSubmit = () => {
    if (otp.toString() === sentOtp.toString()) {
      setNext1(true);
    }
  };
  const updatePassword = () => {
    setOpen(true);
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/updatepassword`,
        {
          password,
          email,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        setOpen(false);
        setNext2(true);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sentEmail = () => {
    setOpen(true);

    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/forgot`,
        { email: email },
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      )
      .then((res) => {
        console.log(res);
        setOpen(false);
        setSentOtp(res.data.otp);
        setNext(true);
      })
      .catch((err) => {
        setOpen(false);
        setErr("Email does not exist");
        console.log(err);
      });
  };
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        textAlign: "center",
        width: "50%",
        margin: "10px auto",
      }}
    >
      {!next && (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`}}
          >
            הזן את כתובת הדוא"ל שנרשמת איתה 
          </Typography>
          <TextField
            margin="normal"
            required
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            fullWidth
            id="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            label="אימייל"
            name="email"
            autoComplete="email"
            //autoFocus
          />
          {err && (
            <p
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                color: "red",
              }}
            >
              כתובת הדוא"ל לא מוכרת
            </p>
          )}
          <Button
            variant="contained"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, width: "100%" }}
            onClick={sentEmail}
          >
            בצע
          </Button>
        </Box>
      )}
      {next && !next1 && (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            הזן את הקוד שנשלח לתיבת הדוא"ל שלך 
          </Typography>
          <Box dir="ltr">
            <OtpInput
              containerStyle={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                textAlign: "center",
              }}
              inputStyle={{
                width: "100%",
                height: "30px",
                color: "black",
                fontWeight: "110%",
              }}
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={<span>-</span>}
            />
          </Box>
          <Button variant="contained" onClick={handleSubmit}>
            בצע
          </Button>
        </Box>
      )}
      {next1 && !next2 && (
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <TextField
            margin="normal"
            required
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            fullWidth
            name="password"
            label="סיסמא"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            autoComplete="current-password"
          />
          <Button
            variant="contained"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, width: "100%" }}
            onClick={updatePassword}
          >
            בצע
          </Button>
        </Box>
      )}
      {open && (
        <SimpleBackdrop
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleToogle={handleToggle}
        />
      )}
      {next2 && (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            הסיסמא עודכנה.{" "}
            <NavLink
              to="/signin"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              אתה יכול להתחבר עכשיו
            </NavLink>
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ForgotPassword;
