import { Box, Button } from "@mui/material";
import { NavLink } from "react-router-dom";
import JoinUs from "./JoinUsButton";

export default function Youtube({prof}){
    return(<Box>
        <Box sx={{width:"100%",height:{xs:"300px",md:"750px"},margin:"40px auto"}}>
    <iframe
        width="100%"
        height="100%"
        src="https://www.youtube.com/embed/U7oi1pIztlw"
        title="Embedded Video"
        frameBorder="0"
        allowFullScreen
      ></iframe></Box>
              <JoinUs prof={prof}text={"צור פרופיל >"}/>
    </Box>)
}