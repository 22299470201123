import { Box, Stack } from "@mui/material";
import user from "../../../asset/images/profile_2.png";
import auction from "../../../asset/images/auction.png";
import like from "../../../asset/images/deal_1.png";
import second from "../../../asset/images/second.png";
import first from "../../../asset/images/first.png";
import third from "../../../asset/images/third.png";

export default function HowRegister({text}){
    return (<Box sx={{backgroundColor:"#eeeeee",padding:{xs:"10px 20px",md:"20px 10px"}, borderRadius:"5%"}} gap={"20px"}>
	<h1 style={{margin:"20px 0px", fontFamily: 'Assistant', textAlign: 'center'}}>איך זה בדיוק עובד?</h1>
  <Stack  sx={{flexDirection:{xs:"column",md:"row"},gap:"30px",margin:"10px",justifyItems:'center',}}>
    <Box sx={{backgroundColor:"white",padding:"10px",flex:1}}>
        <Stack direction={"row"}>
        <div style={{flexGrow:4,}}>
        <Stack direction="row" gap="10px" sx={{marginBottom:"10px",alignItems:'center'}}>
			<img src={first} style={{width:"40px",height:"40px"}}/>
        <h4 style={{marginBottom:"10px", fontFamily:"Assistant", fontSize:"25px"}}>{text.howReg.firstTitle}</h4>
        </Stack>
             <p style={{fontFamily:"Assistant", fontSize:"20px"}}>{text.howReg.firstSub}</p>

           </div>
           <div style={{flex:1}}>
           <img src={user} style={{alignSelf:"flex-end",width:"30px",height:"30px"}}alt="user"/>
           </div>
        </Stack>
    </Box>
    <Box sx={{backgroundColor:"white",padding:"10px",flex:1}}>
        <Stack direction={"row"}>
        <div style={{flex:4}}>
        <Stack direction="row" gap="10px" sx={{marginBottom:"10px",alignItems:"center"}}>
			<img src={second} style={{width:"40px", height:"40px"}}/>
			{/*<p style={{padding:"5px 10px",borderRadius:"50%",fontWeight:'bold',backgroundColor:"#eeeeee",display:"inline-block"}}>2</p>*/}
			<h4 style={{marginBottom:"10px", fontFamily:"Assistant", fontSize:"25px"}}>{text.howReg.secondTitle}</h4>
             </Stack>
			 {/*<p>{text.howReg.secondSub}</p>*/}
			 <p style={{fontFamily:"Assistant", fontSize:"20px"}}>{text.howReg.secondSub}</p>
           </div>
           <div style={{flex:1}}>
           <img src={auction} style={{alignSelf:"flex-end",width:"37px",height:"37px"}}alt="auction"/>
           </div>
        </Stack>
    </Box>
    <Box sx={{backgroundColor:"white",padding:"10px",flex:1}}>
        <Stack direction={"row"}>
           <div style={{flex:4}}>
          <Stack direction="row" gap="10px" sx={{marginBottom:"10px",alignItems:'center'}}>
          <img src={third} style={{width:"40px",height:"40px"}}/>
           <h4 style={{marginBottom:"10px", fontFamily:"Assistant", fontSize:"25px"}}>{text.howReg.thirdTitle}</h4>
          </Stack>
          
             <p style={{fontFamily:"Assistant", fontSize:"20px"}}>{text.howReg.thirdSub}</p>
           </div>
           <div style={{flex:1}}>
           <img src={like} style={{alignSelf:"flex-end",width:"35px",height:"35px"}}alt="like"/>
           </div>
        </Stack>
    </Box>
    </Stack>

    </Box>)
}