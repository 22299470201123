import { Button } from "@mui/material"
import logo from "../../../asset/images/logo.png"
import { NavLink } from "react-router-dom"
import JoinUs from "./JoinUsButton"
export default function HeroSection({prof,text}){
    return (<div style={{textAlign:'center',margin:"20px auto"}}>
        <img src={logo} style={{display:"block",margin:"0px auto",height:"100%" ,width:"32%" ,borderRadius:"50%"}}/>
        <h1 style={{margin:"20px 0px", fontFamily: 'Assistant'}}>{text.heroHeader}</h1>
        <h2 style={{margin:"20px auto", fontFamily: 'Assistant'}}>{text.heroSecond}</h2>
		<h2 style={{margin:"20px auto", fontFamily: 'Assistant'}}>{text.heroThird}</h2>
       <JoinUs prof={prof} text={"יאללה, מתאים לי! >"}/>
    </div>)
}