import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { NavLink } from "react-router-dom";
import SimpleBackdrop from "./BackDrop";
import ChangeUpdateEmail from "./ChangeUpdateEmail";

function ChangeEmail() {
  const [sentOtp, setSentOtp] = useState("");
  const [otp, setValue] = useState("");
  const [err, setErr] = useState("");
  const [next1, setNext1] = useState(false);
  const [next, setNext] = useState(false);
  const [next2, setNext2] = useState(false);

  const handleChange = (event) => {
    console.log(event);
    setValue(event);
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [email, setEmail] = useState("");
  const handleSubmit = () => {
    if (otp.toString() === sentOtp.toString()) {
      setNext1(true);
    }
  };

  const sentEmail = () => {
    setOpen(true);

    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/forgot`,
        { email: email },
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      )
      .then((res) => {
        console.log(res);
        setOpen(false);
        setSentOtp(res.data.otp);
        setNext(true);
      })
      .catch((err) => {
        setOpen(false);
        setErr("Email does not exist");
        console.log(err);
      });
  };
  return (
    <Box
      sx={{
        fontFamily: "Assistant",
        textAlign: "center",
        width: "50%",
        margin: "10px auto",
      }}
    >
      {!next && (
        <Box>
          <Typography
            variant="h6"
            sx={{ fontFamily: "Assistant", color: "red" }}
          >
            הזן את הדוא"ל שאיתו נרשמת​
          </Typography>
          <Typography
            variant="p"
            sx={{ fontFamily: "Assistant", fontWeight: "bold" }}
          >
            כתובת דוא"ל:
          </Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            name="email"
            sx={{ fontFamily: "Assistant" }}
            autoComplete="email"
            autoFocus
          />
          {err && (
            <p style={{ fontFamily: "Assistant", color: "red" }}>
              אימייל כזה לא קיים בשרת שלנו
            </p>
          )}
          <Button
            variant="contained"
            sx={{ fontFamily: "Assistant", width: "100%" }}
            onClick={sentEmail}
          >
            בצע​
          </Button>
        </Box>
      )}
      {next && !next1 && (
        <Box>
          <Typography variant="h6" sx={{ fontFamily: "Assistant" }}>
            הזן את הקוד שנשלח לדוא"ל שלך​
          </Typography>
          <Box dir="ltr">
            <OtpInput
              containerStyle={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                textAlign: "center",
              }}
              inputStyle={{
                width: "100%",
                height: "30px",
                color: "black",
                fontWeight: "110%",
              }}
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={<span>-</span>}
            />
          </Box>
          <Button
            variant="contained"
            sx={{ fontFamily: "Assistant" }}
            onClick={handleSubmit}
          >
            המשך​
          </Button>
        </Box>
      )}
      {next1 && !next2 && <ChangeUpdateEmail />}
      {open && (
        <SimpleBackdrop
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleToogle={handleToggle}
        />
      )}
      {next2 && (
        <Box>
          <Typography variant="h6">
            סיסמה עודכנה.{" "}
            <NavLink to="/signin">אתה יכול להתחבר עכשיו</NavLink>
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ChangeEmail;
