import { Box, Typography } from "@mui/material";
import React from "react";

function NotAuthorized() {
  return (
    <Box
      width="70%"
      sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, margin: "0px auto" }}
    >
      <Typography variant="h3">401</Typography>
      <Typography
        variant="p"
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
      >
        You are not authorized to access this page
      </Typography>
    </Box>
  );
}

export default NotAuthorized;
