import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { FlexBox } from "./FlexBox";
import { createTheme } from "@mui/material";
const theme = createTheme({
  typography: {
    fontFamily: [
      "Assistant",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: 270,
    md: 400,
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  textAlign: "center",
  p: 4,
};

export default function AlertModal({
  handleOpen,
  open,
  handleClose,
  what,
  message,
  blockUser,
}) {
  return (
    <div>
      {" "}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{ marginBottom: "10px", fontFamily: "Assistant" }}
            variant="h6"
            component="h2"
          >
            {message}
          </Typography>
          {what !== "not" ? (
            <FlexBox>
              <Button
                variant="contained"
                onClick={blockUser}
                sx={{
                  marginRight: "10px",
                  padding: "5px 30px",
                  fontFamily: "Assistant",
                }}
              >
                כן​
              </Button>
              <Button
                variant="contained"
                sx={{
                  marginRight: "10px",
                  padding: "5px 30px",
                  fontFamily: "Assistant",
                }}
                onClick={handleClose}
              >
                לא​
              </Button>
            </FlexBox>
          ) : (
            <FlexBox>
              <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                  margin: "0px 10px",
                  padding: "5px 30px",
                  fontFamily: "Assistant",
                }}
              >
                בסדר
              </Button>
            </FlexBox>
          )}
        </Box>
      </Modal>
    </div>
  );
}
