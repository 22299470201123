import {
  AccountBox,
  AdminPanelSettings,
  Logout,
  PlayArrow,
  SignpostOutlined,
  Work,
} from "@mui/icons-material";

import MenuIcon from "@mui/icons-material/Menu";
import WorkIcon from "@mui/icons-material/Work";
import { PersonOutline } from '@mui/icons-material';
import { HandymanOutlined } from '@mui/icons-material';
import { Link } from '@mui/icons-material'; 
import { Login } from '@mui/icons-material';

import {
  ThemeProvider,
  alpha,
  createTheme,
  styled,
} from "@mui/material/styles";

import HomeIcon from "@mui/icons-material/Home";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  InputBase,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import logo from "../asset/images/logo.png";
//import logo from "../asset/images/bidit1.svg";
// import logo from "../asset/images/bidit1.PNG";
import PositionedMenu from "./PositionedMenu";
import YouTubeIcon from '@mui/icons-material/YouTube';

function Navbar() {
  const [content, setContent] = useState([]);
  const [auto,setAuto]=useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = useState([]);
  const [badgeContent, setBadgeContent] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  useEffect(() => {
    if(Cookies.get("id"))
    {
      axios
      .get(
        `${
          process.env.React_APP_BACKEND_URL
        }/api/getnotifications?id=${Cookies.get("id")}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        console.log();
        setContent(res.data.Content[0]);
        setNotifications(res.data.Notifications);
        // if (state !== res.data.Size) {
        //   setBadgeContent(res.data.Notifications.length);
        // } else setBadgeContent(0);
        setBadgeContent(res.data.TotalNoti);
        setShowNotifications(true);
      })
      .catch((err) => {
        console.log(err);
        setContent(err.response.data.Content[0]);
      });
    }
  }, []);
  useEffect(() => {
    let interval = setInterval(() => {
      if(Cookies.get("id"))
    {  axios
        .get(
          `${
            process.env.React_APP_BACKEND_URL
          }/api/getnotifications?id=${Cookies.get("id")}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setContent(res.data.Content);
          setNotifications(res.data.Notifications);
          if (res.data.TotalNoti !== 0) setBadgeContent(res.data.TotalNoti);
          setShowNotifications(true);
        })
        .catch((err) => console.log(err));
      }
    }, 2 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  const [menuOpen, setMenuOpen] = useState(true);
  const [shown, setIsShown] = useState(false);
  const [open, setOpen] = useState(false);

  const StyledToolbar = styled(Toolbar)({
    display: "flex",
    margin: "0px",
    justifyContent: "space-between",
    fontFamily: `${process.env.REACT_APP_FONT}`,
    fontWeight: "bold",
  });
  // const Search = styled("div")(({ theme }) => ({
  //   backgroundColor: "white",
  //   padding: "0 10px",
  //   borderRadius: theme.shape.borderRadius,
  // }));
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    fontFamily: `${process.env.REACT_APP_FONT}`,
    fontWeight: "bold",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: { display: { xs: "50%", md: "100%" } },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: `${process.env.REACT_APP_FONT}`,
    fontWeight: "bold",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      fontFamily: `${process.env.REACT_APP_FONT}`,
      fontWeight: "bold",
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "20ch",
      },
    },
  }));
  const BoxStyled = styled(Box)(({ theme }) => ({
    display: "none",
    justifyContent: "space-between",
    gap: "10px",
    fontFamily: `${process.env.REACT_APP_FONT}`,
    fontWeight: "bold",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  }));
  const UserBox = styled(Box)(({ theme }) => ({
    display: "flex",
    fontFamily: `${process.env.REACT_APP_FONT}`,
    fontWeight: "bold",
    justifyContent: "space-between",
    gap: "20px",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }));
  const AppBar1 = styled(AppBar)(({ theme }) => ({
    position: "sticky",
    width: "100%",
    fontFamily: `${process.env.REACT_APP_FONT}`,
    fontWeight: "bold",
  }));
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const [showResult, setShowResult] = useState(false);
  const [search1, setSearch] = useState("");
  const handleClose = () => {
    setAnchorEl1(null);
  };
  const theme = createTheme({
    position: "relative",
    right: "0px",
  });
  return (
    <AppBar1
      sx={{
        //fontFamily: `${process.env.REACT_APP_FONT}`,
        fontWeight: "bold",
        margin: "0px",

      }}
    >
      <StyledToolbar>
        <BoxStyled>
          <Button
            variant="outlined"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
			  backgroundColor: "inherit",
              maxWidth: "fit-content",
              display: {
                md: "block",
                xs: "none",
              },
            }}
          >
            <Box
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
                display: {
                  md: "block",
                  xs: "none",
                },
              }}
            >
              <Box>
                <NavLink to="/">
                  <img
                    src={logo}
                    style={{
                      maxWidth: "60px",
                      height: "60px",//30
                      marginTop: "5px",
                      padding: "0px",
					  borderRadius: "50%", // Set borderRadius to 50% for a circular shape
                    }}
                    alt="Bidit logo"
                  />
                </NavLink>
              </Box>
            </Box>
          </Button>
          <Box
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
              display: {
                md: "block",
                xs: "none",
              },
            }}
          >
            <NavLink
              to="showarticles"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  fontWeight: "bold",
                  display: {
                    md: "block",
                    xs: "none",
                  },
                }}
              >
                מאמרים​
              </Typography>{" "}
            </NavLink>
          </Box>
          <Box
            sx={{
              display: {
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
                md: "block",
                xs: "none",
              },
            }}
          >
            <NavLink
              to="postbid"
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
                textDecoration: "none",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  fontWeight: "bold",
                  backgroundColor: "#faaf3c",
                  color: "white",
                  margin: "0px 5px",
                  display: {
                    md: "block",
                    xs: "none",
                  },
                }}
              >
                פרסם מכרז​
              </Button>
            </NavLink>
          </Box>
          <Button
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
              padding: "0px",
              marging: "0px",
              backgroundColor: "inherit",
              display: {
                md: "none",
                xs: "block",
              },
            }}
            id="basic-button1"
            aria-controls={open1 ? "basic-menu1" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuIcon
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
                backgroundColor: "white",
                display: {
                  sm: "none",
                  xs: "block",
                },
              }}
            />
          </Button>
        </BoxStyled>
        <BoxStyled
          sx={{
            display: {
              md: "none",
              xs: "block",
            },
          }}
        >
          <Button
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
              padding: "0px",
              margin: "0px",
              backgroundColor: "inherit",
            }}
            id="basic-button"
            aria-controls={open1 ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open1 ? "true" : undefined}
            onClick={handleClick}
          >
            <MenuIcon
				sx={{
					backgroundColor: "white",
					borderRadius: "50%",
					fontSize: "34px",
					boxShadow: "0 0 0 2px white, 0 0 0 4px #2196F3, 0 2px 4px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1)", // Add a white border outside the blue border
					"&:hover": {
					backgroundColor: "#f0f0f0",
					},
			}}
		/>
          </Button>
          {Cookies.get("id") ? (
            <ThemeProvider theme={theme}>
              <Menu
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  fontWeight: "bold",
                }}
                id="basic-menu1"
                //anchorEl={anchorEl1}
                open={open1}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button1",
                }}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <NavLink
                  to="/"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    textDecoration: "none",
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    fontWeight: "bold",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      fontWeight: "bold",
                    }}
                    onClick={handleClose}
                  >
                    <ListItemIcon>
                      <HomeIcon fontSize="small" />
                    </ListItemIcon>
                    <span style={{ fontFamily: "Assistant" }}
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        fontWeight: "bold",
                      }}
                    >
                      בית
                    </span>
                  </MenuItem>
                </NavLink>
                <NavLink
                  to="/profile"
                  style={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    fontWeight: "bold",
                    margin: "0px",
                    padding: "0px",
                    textDecoration: "none",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      fontWeight: "bold",
                    }}
                    onClick={handleClose}
                  >
                    <ListItemIcon>
                      <AccountBox fontSize="small" />
                    </ListItemIcon>
                    <span style={{ fontFamily: "Assistant" }}
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        fontWeight: "bold",
						
                      }}
                    >
                      פרופיל​
                    </span>
                  </MenuItem>
                </NavLink>
                <NavLink
                  to="/postbid"
                  style={{
                    margin: "0px",
                    padding: "0px",
                    textDecoration: "none",
                  }}
                >
                  <MenuItem
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
					  backgroundColor: "#faaf3c",
                      fontWeight: "bold",
                    }}
                    onClick={handleClose}
                  >
                    <ListItemIcon>
                      <WorkIcon fontSize="small" />
                    </ListItemIcon>
                    <span style={{ fontFamily: "Assistant" }}
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        fontWeight: "bold",
                      }}
                    >
                      פרסם מכרז
                    </span>
                  </MenuItem>
                </NavLink>
                {Cookies.get("adminId") !== null &&
                  Cookies.get("adminId") !== undefined && (
                    <NavLink
                      to="/admin/home"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem
                        sx={{
                          fontFamily: `${process.env.REACT_APP_FONT}`,
                          fontWeight: "bold",
                        }}
                        onClick={handleClose}
                      >
                        <ListItemIcon>
                          <AdminPanelSettings fontSize="small" />
                        </ListItemIcon>

                        <span style={{ fontFamily: "Assistant" }}
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            fontWeight: "bold",
                          }}
                        >
                          מנהל מערכת
                        </span>
                      </MenuItem>
                    </NavLink>
                  )}

                <MenuItem
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    fontWeight: "bold",
                  }}
                  onClick={handleClose}
                >
                  <a
                    href={content.changelink}
                    style={{ cursor: "pointer", textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ListItemIcon>
                      <PlayArrow fontSize="small" />
                    </ListItemIcon>
                  </a>
                  <span style={{ fontFamily: "Assistant" }}
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      fontWeight: "bold",
                    }}
                  >
				  צפו בי​
                  </span>
                </MenuItem>

                <NavLink
                  to="/mybids"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      fontWeight: "bold",
                    }}
                    onClick={handleClose}
                  >
                    <ListItemIcon>
                      <Work fontSize="small" />
                    </ListItemIcon>

                    <span style={{ fontFamily: "Assistant" }}
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        fontWeight: "bold",
						
                      }}
                    >
                      המכרזים שלי
                    </span>
                  </MenuItem>
                </NavLink>
                {Cookies.get("adminId") !== null &&
                  Cookies.get("adminId") !== undefined && (
                    <NavLink
                      to="/admin/home"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem
                        sx={{
                          fontFamily: `${process.env.REACT_APP_FONT}`,
                          fontWeight: "bold",
                        }}
                        onClick={handleClose}
                      >
                        <ListItemIcon>
                          <AdminPanelSettings fontSize="small" />
                        </ListItemIcon>

                        <span style={{ fontFamily: "Assistant" }}
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            fontWeight: "bold",
                          }}
                        >
                          מנהל מערכת
                        </span>
                      </MenuItem>
                    </NavLink>
                  )}
                <NavLink
                  to="/signin"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <MenuItem
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      fontWeight: "bold",
                    }}
                    onClick={(e) => {
                      Cookies.set("subscription", "No", { expires: 365 });

                      Cookies.remove("favids");
                      Cookies.remove("token");
                      Cookies.remove("name");
                      Cookies.remove("last_name");
                      Cookies.remove("id");
                      Cookies.remove("username");
                      Cookies.remove("email");
                      Cookies.remove("phone");
                      Cookies.remove("adminId");
                      Cookies.remove("admintoken");
                      localStorage.clear();
                      handleClose();
                    }}
                  >
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <span style={{ fontFamily: "Assistant" }}
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        fontWeight: "bold",
                      }}
                    >
                      התנתק
                    </span>
                  </MenuItem>
                </NavLink>
              </Menu>
            </ThemeProvider>
          ) : (
            <Menu
              id="basic-menu1"
              MenuListProps={{
                "aria-labelledby": "basic-button1",
              }}
              //anchorEl={anchorEl1}
              open={open1}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <NavLink
                to="/signin"
                style={{
                  margin: "0px",
                  padding: "0px",
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  sx={{ fontFamily: "Assisstant" }}
                  onClick={handleClose}
				  
                >
                  <ListItemIcon>
                    <Login fontSize="small" />
                  </ListItemIcon>
                  <span
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      fontWeight: "bold",
                    }}
                  >
                    התחבר​
                  </span>
                </MenuItem>
              </NavLink>
              <NavLink
                to="/userlandingpage"
                style={{
                  margin: "0px",
                  padding: "0px",
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  sx={{ fontFamily: "Assisstant, sans-serif" }}
                  onClick={handleClose}
                >
                  <ListItemIcon>
                    <PersonOutline fontSize="small" />
                  </ListItemIcon>
                  <span
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      fontWeight: "bold",
                    }}
                  >
                    הרשמה ללקוחות
                  </span>
                </MenuItem>
              </NavLink>
             
              <NavLink
                to="/proflandingpage"
                style={{
                  margin: "0px",
                  padding: "0px",
                  textDecoration: "none",
                }}
              >
                <MenuItem
                  sx={{ fontFamily: "Assisstant" }}
                  onClick={handleClose}
                >
                  <ListItemIcon>
                    <HandymanOutlined fontSize="small" />
                  </ListItemIcon>
                  <span
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      fontWeight: "bold",
                    }}
                  >
                  הרשמה לבעלי מקצוע
                  </span>
                </MenuItem>
              </NavLink>
       
            </Menu>
          )}
        </BoxStyled>
		
		  <Stack sx={{display:{md:"none",sm:"block"}}}>
            <NavLink to="/"
          
            >
              <Tooltip title="מסך הבית" sx={{ cursor: "pointer" }}>
				<IconButton sx={{ borderRadius: '50%', backgroundColor: '#D32F2F', padding: '4px' }}>
					<HomeIcon sx={{ color: 'white', fontSize: '20px' }} />
				</IconButton>
              </Tooltip>
            </NavLink>
            <Typography
              variant="span"
              sx={{ textAlign: "center", fontFamily: "Assistant", fontSize: "12px" }}
            >
             ​
            </Typography>
          </Stack>

        <BoxStyled sx={{ display: "flex" }}>
          {showNotifications && (
            <PositionedMenu
              setNotifications={setNotifications}
              notifications={notifications}
              badgeContent={badgeContent}
              setBadgeContent={setBadgeContent}
            />
          )}

          <Stack>
            <a
              href={content.changelink}
              style={{ cursor: "pointer" }}
              target="_blank"
              rel="noreferrer"
            >
              <Tooltip title="לחץ כדי לצפות בי" sx={{ cursor: "pointer" }}>
				<IconButton sx={{ borderRadius: '50%', backgroundColor: '#D32F2F', padding: '4px' }}>
					<YouTubeIcon sx={{ color: 'white', fontSize: '20px' }} />
				</IconButton>
              </Tooltip>
            </a>
            <Typography
              variant="span"
              sx={{ textAlign: "center", fontFamily: "Assistant", fontSize: "13px" }}
            >
			צפו בי​
            </Typography>
          </Stack>
        </BoxStyled>
        
        <Search
          onClick={()=>setAuto(true)}
          onKeyDown={(e) =>
           {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent the default behavior (form submission, etc.)
              setShowResult(true);
              e.target.blur();
            } else {
              setShowResult(false);
            }
           }
          }
        >
          <StyledInputBase
            autoFocus={auto}
            value={search1}
            onBlur={()=>setAuto(false)}
            onChange={(e) => setSearch(e.currentTarget.value)}
            placeholder="חפש..."
            inputProps={{ "aria-label": "search" }}
			style={{ width: "120px" }}
          />
         
        </Search>
        <BoxStyled>
          {showResult && <Navigate to={`/${search1}`}></Navigate>}
          {Cookies.get("id") ? (
            <Avatar
              onClick={(e) => setOpen(true)}
              sx={{
                display: {
                  md: "block",
                  xs: "none",
                },
                textAlign: "center",
				bgcolor: "#fdad3a",
                //bgcolor: "#F20091",
                /*bgcolor: deepOrange[500],*/
              }}
            >
              <Typography sx={{ marginTop: "25%" }}>
                {Cookies.get("name")
                  ? Cookies.get("name").charAt(0)
                  : "" + Cookies.get("last_name")
                  ? Cookies.get("last_name").charAt(0)
                  : ""}
              </Typography>
            </Avatar>
          ) : (
            <NavLink to="/signin" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                sx={{
                  fontFamily: "Assisstant",
                  backgroundColor: "white",
                  color: "black",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
              >
                <span style={{ fontFamily: "Assistant" }}>התחבר​</span>
              </Button>
            </NavLink>
          )}
          {!Cookies.get("id") && (
            <NavLink to="/userlandingpage" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  fontFamily: "Assistant",
                  backgroundColor: "white",
                  color: "black",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
                variant="contained"
              >
                <span style={{ fontFamily: "Assistant" }}> הרשמה ללקוחות​</span>
              </Button>
            </NavLink>
          )}
           {!Cookies.get("id") && (
            <NavLink to="/proflandingpage" style={{ textDecoration: "none" }}>
              <Button
                sx={{
                  fontFamily: "Assistant",
                  backgroundColor: "white",
                  color: "black",
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
                variant="contained"
              >
                <span style={{ fontFamily: "Assistant" }}> הרשמה לבעלי מקצוע</span>
              </Button>
            </NavLink>
          )}
        </BoxStyled>
        <UserBox></UserBox>
      </StyledToolbar>
      {/* {Boolean(anchorEl1) && (
        <MenuShow
          anchorEl={anchorEl1}
          setAnchorEl={setAnchorEl1}
          handleClick={handleClick}
          handleClose={handleClose}
        />
      )} */}
      <Menu
        id="basic-menu"
        open={open}
        onClose={(e) => setOpen(false)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <NavLink to="/profile" style={{ textDecoration: "none" }}>
          <MenuItem
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
          >
            פרופיל​
          </MenuItem>
        </NavLink>
        <NavLink
          to="/mybids"
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
            onClick={handleClose}
          >
            <span style={{ fontFamily: "Assistant" }}
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
				
              }}
            >
              המכרזים שלי
            </span>
          </MenuItem>
        </NavLink>
        {Cookies.get("adminId") !== null &&
          Cookies.get("adminId") !== undefined && (
            <NavLink
              to="/admin/home"
              style={{ textDecoration: "none", color: "black" }}
            >
              <MenuItem
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  fontWeight: "bold",
                }}
                onClick={handleClose}
              >
                <span style={{ fontFamily: "Assistant" }}
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    fontWeight: "bold",
                  }}
                >
                  מנהל מערכת
                </span>
              </MenuItem>
            </NavLink>
          )}
        <NavLink
          to="/signin"
          style={{ textDecoration: "none", color: "black" }}
        >
          <MenuItem
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
            onClick={(e) => {
              Cookies.set("subscription", "No", { expires: 365 });

              Cookies.remove("favids");
              Cookies.remove("token");
              Cookies.remove("name");
              Cookies.remove("last_name");
              Cookies.remove("id");
              Cookies.remove("username");
              Cookies.remove("email");
              Cookies.remove("phone");
              Cookies.remove("adminId");
              Cookies.remove("admintoken");
              localStorage.clear();
              handleClose();
            }}
          >
            התנתק
          </MenuItem>
        </NavLink>
      </Menu>
    </AppBar1>
  );
}

export default Navbar;
