import { Box, Button, Stack, ThemeProvider, createTheme } from "@mui/material";
import React, { useState, useEffect } from "react";
import DropDown from "./DropDown";
import logo from "../asset/images/cell_phone_5.png"; //bid_page_5.png

function BidTop({
  handleEnding,
  handleNewlyListed,
  handleAll,
  filter,
  handleClosed,
  setFilter,
}) {
  const [color1, setColor1] = useState("red");
  const [color2, setColor2] = useState("blue");
  const [color3, setColor3] = useState("blue");
  const [color4, setColor4] = useState("blue");
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Assistant",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px", // Adjust the height as needed
          "@media (max-width: 600px)": {
            height: "160px", // Adjust the height for mobile screens
          },
        }}
      >
        <img
          src={logo}
          alt="מכרזים לבעלי מקצוע"
          style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "20px" }}
        />
      </Box>
      <Stack
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          fontFamily: "Assistant",
        }}
        direction={{ xs: "column", md: "row" }}
      >
        <DropDown setFilter={setFilter} filter={filter} />
        <Box sx={{ textAlign: "center", fontFamily: "Assistant" }}>
          <Button
            variant="text"
            onClick={(e) => {
              setColor1("red");
              setColor2("blue");
              setColor3("blue");
              setColor4("blue");
              handleAll();
            }}
            sx={{ color: color1 }}
          >
            הכל
          </Button>
          <Button
            variant="text"
            onClick={(e) => {
              setColor2("red");
              setColor1("blue");
              setColor3("blue");
              setColor4("blue");
              handleEnding();
            }}
            sx={{ color: color2, fontFamily: "Assistant" }}
          >
            מסתיימים בקרוב​
          </Button>
          <Button
            variant="text"
            onClick={(e) => {
              setColor3("red");
              setColor1("blue");
              setColor2("blue");
              setColor4("blue");
              handleNewlyListed();
            }}
            sx={{ color: color3, fontFamily: "Assistant" }}
          >
            חדשים​
          </Button>
          <Button
            variant="text"
            onClick={(e) => {
              setColor3("blue");
              setColor4("red");
              setColor1("blue");
              setColor2("blue");
              handleClosed();
            }}
            sx={{ color: color4, fontFamily: "Assistant" }}
          >
            הסתיימו
          </Button>
        </Box>
      </Stack>
    </ThemeProvider>
  );
}

export default BidTop;
