import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import JoditEditor from "jodit-react";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import SimpleBackdrop from "../BackDrop";

function ChangePrivacy({ change }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [data, setData] = useState("");
  const [validDesc, setValidDesc] = useState(false);
  const [next, setNext] = useState(false);
  const [notes, setNotes] = useState("");
  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    uploader: {
      insertImageAsBase64URI: true,
    },
  };
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    placeholder: "Start typings...",
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.React_APP_BACKEND_URL}/api/getcontent?change=${change}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}
        `,
          },
        }
      )
      .then((response) => {
        console.log(response.data.Content[0]);
        setData(response.data.Content[0][change]);
        setNext(true);
      })
      .catch((err) => console.log(err));
  }, [change]);

  const handleChange = (e) => {
    setData(e);
  };
  const handleSubmit = () => {
    setOpen(true);
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/admin/updatecontent`,
        {
          changed: data,
          change,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("admintoken")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setOpen(false);
        setShow(true);
        setNext(false);
      })
      .catch((err) => {
        setOpen(false);
        console.log(err);
      });
  };
  return (
    <Box sx={{ width: "80%" }}>
      <Typography
        variant="h6"
        sx={{
          marginBottom: "20px",
          marginTop: "20px",
          fontFamily: "Assistant",
        }}
      >
        Change {change} page
      </Typography>
      {next && !show && (
        <Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            {change !== "changelink" ? (
              <JoditEditor
                tabIn
                style={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                }}
                value={data}
                ref={ref}
                config={editorConfig}
                onBlur={(newContent) => handleChange(newContent)}
              />
            ) : (
              <TextField
                margin="normal"
                value={data}
                onChange={(e) => setData(e.target.value)}
                sx={{ marginBottom: "20px", fontFamily: "Assistant" }}
                required
                fullWidth
                id="title"
                autoFocus
                name="title"
              />
            )}
          </Box>
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            {change !== "changelink" ? (
              <JoditEditor
                tabIn
                style={{
                  display: {
                    xs: "none",
                    md: "block",
                  },
                  fontFamily: "Assistant",
                }}
                value={data}
                ref={ref}
                config={editorConfig}
                onBlur={(newContent) => handleChange(newContent)}
              />
            ) : (
              <TextField
                margin="normal"
                value={data}
                onChange={(e) => setData(e.target.value)}
                sx={{ marginBottom: "20px", fontFamily: "Assistant" }}
                required
                fullWidth
                id="title"
                autoFocus
                name="title"
              />
            )}
          </Box>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ marginTop: "20px", fontFamily: "Assistant" }}
          >
            Submit
          </Button>
        </Box>
      )}
      {show && (
        <Box>
          <Typography sx={{ fontFamily: "Assistant" }}>
            Successfully Changed
          </Typography>
        </Box>
      )}
      {open && (
        <SimpleBackdrop
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleToogle={handleToggle}
        />
      )}
    </Box>
  );
}

export default ChangePrivacy;
