import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import * as React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../asset/images/signin_new.JPG";
import ValidateEmail from "../asset/js/ValidateEmail";
import SimpleBackdrop from "./BackDrop";
import { Copyright } from "./Copyright";
import Next from "./Next";
import OTPInput from "./OTP";
import Footer from "./Footer";
import { useEffect } from "react";
import ProgressBarWithImage from "./SignUpCompo/Progressbar";
import ProgressBar from "./SignUpCompo/Progressbar";
import ShowDetailSignup from "./SignUpCompo/ShowDetailsSignup";
const theme = createTheme({
  typography: {
    fontFamily: [
      "Assistant",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});


export default function SignUp() {
  const [show,setShow]=useState(true);
  useEffect(()=>{
    axios.get(`${process.env.React_APP_BACKEND_URL}/api/admin/show`).then(res=>{
      console.log(res);
      setShow(res.data.data[0].show==="yes"?true:false);
    }).catch(err=>console.log(err))
  },[])
  const [direct, setDirect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  const [next, setNext] = React.useState(false);
  const [otp, setotp] = useState("");
  const [token, setToken] = useState("no");
  const [which, setWhich] = useState("no");
  let email = React.useRef();
  let prof=React.useRef(false);
  useEffect(() => {
    // Accessing the current URL
    const currentUrl = window.location.href;
    console.log('Current URL:', currentUrl);
    if(currentUrl.includes("prof"))
    prof.current=true;

    // You can perform further actions with the URL if needed
  }, []);
  const [validEmail, setValidEmail] = React.useState(false);
  const handleSubmit = () => {
    setValidEmail(false);
    console.log(email.current);
    if (ValidateEmail(email.current)) {
      setOpen(true);
      axios
        .get(
          `${process.env.React_APP_BACKEND_URL}/api/checkemail?email=${email.current}`
        )
        .then((data1) => {
          if (data1.status === 200) {
            setotp(data1.data.OTP);
            setOpen(false);
            setNext(true);
            setDirect(true);
          }
          console.log(data1)
        })
        .catch((err) => {
          setOpen(false);
          console.log(err);
          if (err.response.status === 400) setValidEmail(true);
        });
    } else {
      setValidEmail(true);
    }
  };

  const handleEmail = (e) => {
    const change = e.target.value;
    email.current = change;
  };
  const [progress, setProgress] = useState(1);

  
  return (
    <ThemeProvider theme={theme}>
       {/* <NavLink to="/home" style={{ textDecoration: "none",textAlign:"center"}}>
            <img src={logo} style={{ objectFit: "fit-content", borderRadius: "20px",display:"block",margin:"0px auto" }} alt="פשוט למצוא בעל מקצוע לוגו" />
          </NavLink> */}
       <ProgressBar progress={progress} prof={prof.current}/>
      <Container component="main" maxWidth="md">
        <CssBaseline />
       
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
         
          <Typography
            component="h1"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
            variant="h5"
          >
            הפרופיל שלי​
          </Typography>
			<p
				style={{
				fontFamily: `${process.env.REACT_APP_FONT}`,
				fontWeight: "bold",
				}}
			>
			{/*בואו נתחיל...*/}
			</p>
		  
          {!next && !direct && (
            <Box
              noValidate
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 1 }}
            >
              <Typography
                variant="p"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  fontWeight: "bold",
                }}
              >
                הזן את כתובת הדוא"ל שלך: ​
              </Typography>
              <TextField
                margin="normal"
                required
                fullWidth
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                onChange={(e) => handleEmail(e)}
                id="email"
                name="email"
                autoComplete="email"
                //autoFocus
              />
              {validEmail && (
                <p
                  style={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  דוא"ל לא תקין או שכבר בשימוש​
                </p>
              )}
              <Button
                fullWidth
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  mt: 3,
                  mb: 2,
				  backgroundColor: 'black',
				  color: 'white',
                }}
              >
                שלח לי את הקוד למייל (המתן מספר שניות)
              </Button>

              <ShowDetailSignup text={'לכתובת הדוא"ל שתזין אנחנו נשלח ממש עוד רגע קוד. כדאי שזו תהיה כתובת פעילה כי לשם אנחנו נשלח בהמשך את ההתראות על המכרזים.'}/>
            </Box>
          )}
         {open && (
            <SimpleBackdrop
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              handleToogle={handleToggle}
            />
          )}
          {next && which === "no" && (
            <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
              <OTPInput email={email.current} sentOtp={otp} setProgress={setProgress}progress={progress} prof={prof.current}/>
            </Box>
          )}
          {direct && which !== "no" && (
            <Next email={email.current} token={token} which={which} type={"email"}setProgress={setProgress}progress={progress}/>
          )}
        {show&&  <><NavLink to="/signupwithphone" ><p style={{textAlign:"end", fontFamily:'Assistant', fontSize: '18px', color: 'black'}}>אני מעוניין לקבל קוד למספר הסלולרי</p></NavLink>
     {!prof.current&&  <><p style={{textAlign:"center"}}>{/*Or*/}</p>
<NavLink to="/signupasprof"onClick={e=>{
  prof.current=true;
}} ><p style={{textAlign:"end", fontFamily:'Assistant', fontSize: '18px', color: 'black'}}>{/*Sign Up as a professional*/}</p></NavLink></> }
         </>
}

		<br />
          <Grid container sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
            <Grid item sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
              <NavLink to="/signin" style={{ fontFamily: 'Assistant, sans-serif' }}>כבר יש לך חשבון? התחבר​</NavLink>
            </Grid>
          </Grid>
		   
        </Box>
		
		{/*<Copyright sx={{ mt: 8, mb: 4 }} />*/}
      </Container>
	  <Footer />
    </ThemeProvider>
  );
}
