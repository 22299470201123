import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";

function ListItem1({ post }) {
  return (
    <ListItem
      disablePadding
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        "&hover": {
          backgroundColor: "none",
        },
      }}
    >
      <ListItemButton sx={{ paddingTop: "0px", paddingLeft: "0px" }}>
        <ListItemIcon
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginRight: "0px",
            paddingRight: "0px",
            minWidth: "30px",
            color: "black",
          }}
        >
          <AccessAlarmIcon sx={{ color: "white" }} />
        </ListItemIcon>
        <ListItemText
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginLeft: "0px",
          }}
        >
          <Typography
            variant="p"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            {post.deadline - new Date().getTime() < 0
              ? "הסתיים"
              : Math.ceil(
                  (post.deadline - new Date().getTime()) / (60 * 60 * 1000)
                ) <= 23
              ? Math.ceil(
                  (post.deadline - new Date().getTime()) / (60 * 60 * 1000)
                ) + " שעות"
              : Math.ceil(
                  (post.deadline - new Date().getTime()) / (24 * 60 * 60 * 1000)
                ) + " ימים"}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default ListItem1;
