import { Button, styled } from "@mui/material";
import React from "react";

function StyledButton() {
  const StyledButton = styled(Button)({
    backgroundColor: "#2D6141",
    marginRight: "5px",
    padding: "1px 26px",
  });
  return <StyledButton variant="contained" style={{ fontFamily: 'Assistant' }}>תן הצעה​</StyledButton>;
}

export default StyledButton;
