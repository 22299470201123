import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Select as Select1,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import returnCityNames from "../asset/js/CityNames";
import PostBidGeneralInfoUpdate from "../Components/PostBidGeneralInfoUpdate";
function PostBidUpdate() {
  const { id } = useParams();
  const [time, setTime] = React.useState(0);
  const [title1, setTitle1] = React.useState("");
  const [show, setShow] = useState(false);
  const [bidData, setBidData] = useState({});
  const [title, setTitle] = useState("");
  const [next, setNext] = useState(false);
  const [data, setData] = useState("");
  const [content, setContent] = useState("");
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getabid?id=${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        setTitle1(res.data.Bid[0].title);
        setArea(res.data.Bid[0].area);
        setBidData(res.data.Bid[0]);
        setShow(true);
      });
  }, [id]);

  const [validTitle, setValidTitle] = useState(false);

  const StyledBox = styled(Box)({
    width: "70%",
    margin: "0px auto",
    marginTop: "20px",
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        fontFamily: `${process.env.REACT_APP_FONT}`,
      },
    },
  };
  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setArea(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const [area, setArea] = React.useState(["צפון"]);
  //   const config = {
  //     readonly: false, // all options from https://xdsoft.net/jodit/doc/,
  //     placeholder:
  //       "Enter Your Description(Should be more than at least 300characters)",
  //     height: "400px",
  //   };

  const handleChange = (event) => {
    event.preventDefault();
    const change = event.target.value;

    if (event.target.name === "location") setArea(change);
    else if (event.target.name === "time") {
      setTime(change);
    } else setTitle1(change);
  };
  const cityNames = returnCityNames();
  const handleSubmit = (event) => {
    event.preventDefault();

    const date = new Date();

    console.log(date.getTime());

    setValidTitle(false);
    if (title1.length < 20) setValidTitle(true);

    if (!validTitle) {
      let area1 = "";
      for (let i = 0; i < area.length; i++) area1 += area[i];
      if (time === 0) {
        bidData.title = title1;
        bidData.area = area1;
        bidData.length = 24 + "";
        bidData.deadline = bidData.deadline.toString();
      } else {
        bidData.length = time + "";

        bidData.title = title1;
        bidData.area = area1;
        bidData.deadline = date.getTime() + time * 60 * 60 * 1000;
      }
      setNext(true);
    }
  };
  return (
    <div style={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {show && (
        <StyledBox noValidate sx={{ mt: 1 }}>
          {!next && (
            <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  textAlign: "center",
                  marginBottom: "20px",
                  marginTop: "20px",
                }}
              >
                פרסם מכרז עבודה ובדוק שכל השדות מולאו כראוי
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  fontWeight: "bold",
                }}
              >
                הזן כותרת של המכרז, מומלץ לציין גם את העיר
              </Typography>
              <TextField
                margin="normal"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  marginBottom: "20px",
                }}
                required
                fullWidth
                value={title1}
                onChange={(e) => setTitle1(e.target.value)}
                id="title"
                name="title"
                autoFocus
              />
              {validTitle && (
                <p
                  style={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    color: "red",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  הכותרת צריכה להיות לפחות מ-5 תווים
                </p>
              )}

              <InputLabel
                id="area"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  marginTop: "10px",
                }}
              >
                בחר את האזור שבו תתבצע העבודה
              </InputLabel>
              <Select1
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  width: "100%",
                  marginBottom: "10px",
                }}
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                placeholder="Enter Area"
                value={area}
                onChange={handleChange1}
                input={<OutlinedInput label="Tag" />}
                renderValue={(selected) => selected}
                MenuProps={MenuProps}
              >
                {[
                  "צפון",
                  "קרית שמונה והסביבה",
                  "חיפה והסביבה",
                  "ירושלים והסביבה",
                  "נתניה והסביבה",
                  "השרון והסביבה",
                  "מרכז",
                  "שפלה והסביבה",
                  "באר שבע והסביבה",
                  "דרום",
                ].map((name, i) => (
                  <MenuItem
                    key={name}
                    value={name}
                    sx={{
                      fontFamily: `${process.env.REACT_APP_FONT}`,
                      flexDirection: "column",
                      fontSize: { xs: "80%", md: "100%" },
                    }}
                  >
                    <Checkbox checked={area.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                    <Box
                      display="block"
                      sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                    >
                      {name === "צפון" ? (
                        <Box>
                          {cityNames.southCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                      ) : name === "קרית שמונה והסביבה" ? (
                        <Box>
                          {cityNames.northCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                      ) : name === "חיפה והסביבה" ? (
                        <Box>
                          {cityNames.westCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                      ) : name === "ירושלים והסביבה" ? (
                        <Box>
                          {cityNames.eastCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                      ) : name === "נתניה והסביבה" ? (
                        <Box>
                          {cityNames.eCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                      ) : name === "השרון והסביבה" ? (
                        <Box>
                          {cityNames.fCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                      ) : name === "מרכז" ? (
                        <Box>
                          {cityNames.gCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                      ) : name === "שפלה והסביבה" ? (
                        <Box>
                          {cityNames.hCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                      ) : name === "באר שבע והסביבה" ? (
                        <Box>
                          {cityNames.iCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
                    ) : name === "דרום" ? (
                        <Box>
                          {cityNames.jCities.map((e) => {
                            return (
                              <Typography
                                variant="p"
                                display="block"
                                sx={{
                                  fontFamily: `${process.env.REACT_APP_FONT}`,
                                  color: "grey",
                                  margin: "0px 3px",
                                }}
                              >
                                {e}
                              </Typography>
                            );
                          })}
                        </Box>
					 ) : (
                       ""
					 )}
                    </Box>
                  </MenuItem>
                ))}
              </Select1>
              <InputLabel
                id="time"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  marginTop: "20px",
                }}
              >
                בחר את משך הזמן למכרז​
              </InputLabel>
              <Select
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  width: "100%",
                }}
                labelId="time"
                id="time"
                value={time}
                label="Time"
                name="time"
                onChange={handleChange}
              >
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={0.166}
                >
                  10 minutes
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={0.5}
                >
                  30 minutes
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={1}
                >
                  1 hour
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={3}
                >
                  3 hour
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={6}
                >
                  6 hour
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={12}
                >
                  12 hour
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={24}
                >
                  1 day
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={48}
                >
                  2 day
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={72}
                >
                  3 days
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={96}
                >
                  4 days
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={120}
                >
                  5 days
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={144}
                >
                  6 days
                </MenuItem>
                <MenuItem
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  value={168}
                >
                  7 days
                </MenuItem>
              </Select>
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  mt: 3,
                  mb: 2,
                }}
              >
                הבא​
              </Button>
            </Box>
          )}
          {next && <PostBidGeneralInfoUpdate bidData={bidData} />}
        </StyledBox>
      )}
    </div>
  );
}

export default PostBidUpdate;
