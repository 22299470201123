import { Box, Button, styled, TextField, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SimpleBackdrop from "../BackDrop";

function CreateNews() {
  const [data, setData] = useState("");
  const [content, setContent] = useState("");
  const [open, setOpen] = useState(false);
  const [goToHome, setGoToHome] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [validTitle, setValidTitle] = useState(false);

  const StyledBox = styled(Box)({
    width: "70%",
    margin: "0px auto",
    marginTop: "20px",
  });

  //   const config = {
  //     readonly: false, // all options from https://xdsoft.net/jodit/doc/,
  //     placeholder:
  //       "Enter Your Description(Should be more than at least 300characters)",
  //     height: "400px",
  //   };
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setOpen(true);
    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/admin/createnews`,
        {
          des: data.get("des"),
          title: data.get("title"),
          date: new Date().getDate() + " " + monthNames[new Date().getMonth()],
        },
        { headers: { Authorization: `Bearer ${Cookies.get("admintoken")}` } }
      )
      .then((res) => {
        setOpen(false);
        window.location.reload();
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setValidTitle(true);
      });
  };
  return (
    <Box
      flex={5}
      p={2}
      sx={{ width: "100%", overflow: "hidden", fontFamily: "Assistant" }}
    >
      {!goToHome && (
        <StyledBox noValidate sx={{ mt: 1, fontFamily: "Assistant" }}>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ fontFamily: "Assistant" }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: "Assistant",
                textAlign: "center",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              צור חדשות
            </Typography>
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
            >
              הזן את כותרת החדשות שלך
            </Typography>
            <TextField
              margin="normal"
              sx={{ marginBottom: "20px", fontFamily: "Assistant" }}
              required
              fullWidth
              id="title"
              name="title"
            />
            <Typography
              variant="p"
              sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
            >
              הזן תיאור של החדשות שלך
            </Typography>
            <TextField
              margin="normal"
              sx={{ marginBottom: "20px", fontFamily: "Assistant" }}
              required
              fullWidth
              id="des"
              name="des"
            />
            {validTitle && (
              <Typography
                variant="p"
                sx={{ color: "red", fontFamily: "Assistant" }}
              >
                התיאור חייב להיות יותר מ-2 תווים
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontFamily: "Assistant" }}
            >
              שלח
            </Button>
          </Box>

          {open && (
            <SimpleBackdrop
              open={open}
              setOpen={setOpen}
              handleToogle={handleToggle}
            />
          )}
        </StyledBox>
      )}
      {goToHome && (
        <StyledBox>
          <Typography variant="p">
            הידיעה הוגשה. לך הביתה מ <NavLink to="/admin/home">כאן</NavLink>
          </Typography>
        </StyledBox>
      )}
    </Box>
  );
}

export default CreateNews;
