import { Box } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BidTop from "./BidTop";
import PaginationRounded from "./PaginationRounded";
import ShowPosts from "./ShowPosts";

function MyBids() {
  const [posts, setPosts] = useState([]);
  const [filter, setFilter] = useState({ area: "הכל​", profession: "הכל​" });
  const [allPosts, setAllPosts] = useState([]);
  const [what, setWhat] = useState("all");
  const { id } = useParams();
  const [showPosts, setShowPosts] = useState([]);
  const [show, setShow] = useState(false);
  const [page, setPage] = React.useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    const token = Cookies.get("token");
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/mybids?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}
          `,
        },
      })
      .then((res) => {
        console.log(res);
        setAllPosts(res.data.Bids);
        setPosts(res.data.Bids);
        setShow(true);
      })
      .catch((err) => console.log(err));
  }, [page, id]);
  const handleNewlyListed = () => {
    let sel = [];
    let dif = allPosts;
    console.log(allPosts);
    Array.from(dif).forEach((e) => {
      //let dat = new Date(e.created_at);
      let dat = new Date();
      console.log(e);
      if (dat.getTime() - new Date().getTime() <= 1000) sel.push(e);
    });
    setWhat("all");
    setPosts(sel);
  };
  const handleEnding = () => {
    let sel = [];
    let dif = allPosts;
    //console.log(allPosts);
    Array.from(dif).forEach((e) => {
      console.log(
        Math.round((e.deadline - new Date().getTime()) / (60 * 60 * 1000))
      );
      if (
        Math.round((e.deadline - new Date().getTime()) / (60 * 60 * 1000)) <= 18
      )
        sel.push(e);
    });
    // console.log(sel.toString() + " Abid");
    setWhat("all");
    setPosts(sel);
  };
  const handleAll = () => {
    setWhat("all");
    setPosts(allPosts);
  };
  const handleClosed = () => {
    let sel = [];
    let dif = allPosts;
    //console.log(allPosts);
    Array.from(dif).forEach((e) => {
      if (parseInt(e.deadline) - new Date().getTime() < 0) sel.push(e);
    });
    // console.log(sel.toString() + " Abid");
    setWhat("closed");
    setPosts(sel);
  };
  return (
    <Box flex={3} p={2} sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      <BidTop
        setFilter={setFilter}
        filter={filter}
        handleEnding={handleEnding}
        handleAll={handleAll}
        handleNewlyListed={handleNewlyListed}
        handleClosed={handleClosed}
      />

      {show && (
        <ShowPosts
          showPosts={posts}
          page={page}
          filter={filter}
          what={what}
          search1={"no"}
        />
      )}
      <PaginationRounded handleChange={handleChange} page={page} />
    </Box>
  );
}

export default MyBids;
