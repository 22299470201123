import { Box, styled } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { NavLink } from "react-router-dom";

export default function GeneralNews({ news }) {
  const monthMapping = {
    January: "ינואר",
    February: "פברואר",
    March: "מרץ",
    April: "אפריל",
    May: "מאי",
    June: "יוני",
    July: "יולי",
    August: "אוגוסט",
    September: "ספטמבר",
    October: "אוקטובר",
    November: "נובמבר",
    December: "דצמבר",
  };
  console.log(news);
  const date = new Date(news.created_at);
  const month = monthMapping[date.toLocaleString("en", { month: "long" })]; // Get the corresponding Hebrew month
  const day = date.getDate();
  const year = date.getFullYear();
  const StyledDate = styled(Box)({
    backgroundColor: "black",
    color: "white",
    width: "100px",
    height: "60px",
    textAlign: "center",
    fontFamily: `${process.env.REACT_APP_FONT}`,
    display: "inline-block",
    padding: "5px 10px",
	borderRadius: "10px",
  });
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
      }}
    >
      <StyledDate>
        <Typography
          variant="h6"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
        >
          {day}
        </Typography>
        <Typography
          variant="button"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
        >
          {month}&nbsp;&nbsp;
          {/* {year.toString().charAt(year.toString.length - 3)}
          {year.toString().charAt(year.toString.length - 2)} */}
          {year}
        </Typography>
      </StyledDate>

      <Card
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          elevation: "0",
          boxShadow: "0",
        }}
      >
        <CardContent
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            paddingBottom: "0px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            color="text.secondary"
          >
            {news.title.substr(0, 10)}
          </Typography>
        </CardContent>
        <CardActions sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <NavLink
            style={{ textDecoration: "none", color: "red" }}
            to={`/shownews/${news.id}`}
          >
            <Button size="small" sx={{ color: "red", fontFamily: "Assistant" }}>
              למידע נוסף...
            </Button>
          </NavLink>
        </CardActions>
      </Card>
    </Box>
  );
}
