import {
  AdminPanelSettings,
  Bloodtype,
  CardMembershipSharp,
  Group,
  HourglassEmpty,
  Menu,
  ModeEdit,
} from "@mui/icons-material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import PaidIcon from "@mui/icons-material/Paid";
import { ListSubheader } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import * as React from "react";
export default function AdminMenu({ setShow, setWhat }) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        fontFamily: "Assistant",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          fontFamily: "Assistant",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Menu
          </ListSubheader>
        }
      >
        <ListItemButton
          sx={{ fontFamily: "Assistant" }}
          onClick={(e) => {
            setShow(false);
            setWhat("unapproved");
          }}
        >
          <ListItemIcon>
            <HourglassEmpty sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="מכרזים בהמתנה"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={(e) => {
            setShow(false);
            setWhat("approved");
          }}
        >
          <ListItemIcon
            sx={{
              fontFamily: "Assistant",
              paddingLeft: "0px",
              paddingRight: "0px",
              marginRight: "0px",
              marginLeft: "0px",
            }}
          >
            <CheckBoxIcon
              sx={{
                paddingLeft: "0px",
                paddingRight: "0px",
                marginRight: "0px",
                marginLeft: "0px",
              }}
            />
          </ListItemIcon>
          <ListItemText
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
            primary="מכרזים שאושרו"
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("deals")}>
          <ListItemIcon>
            <PaidIcon sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="עסקאות"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("users")}>
          <ListItemIcon>
            <Group sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="משתמשים"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={(e) => {
            setShow(false);
            setWhat("admins");
          }}
        >
          <ListItemIcon>
            <AdminPanelSettings sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="מנהלי מערכת"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={(e) => {
            setShow(false);
            setWhat("history");
          }}
        >
          <ListItemIcon>
            <AdminPanelSettings sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="היסטוריית מנהלים"
            sx={{ fontWeight: "bold" }}
          />
        </ListItemButton>
        <ListItemButton
          onClick={(e) => {
            setShow(false);
            setWhat("subs");
          }}
        >
          <ListItemIcon>
            <CardMembershipSharp sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="מנויים"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("plans")}>
          <ListItemIcon>
            <Bloodtype sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="הצג תוכניות"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("createans")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="צור תוכנית"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("createNews")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="צור חדשות"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
          
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("createArticles")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="צור מאמרים"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("changePrivacy")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="שנה פרטיות"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("changeAboutUs")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="שנה אודותינו"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("faq")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="שנה שאלות נפוצות"
            sx={{ fontWeight: "bold" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("termsofuse")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="שנה את תנאי השימוש"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("support")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="שנה תמיכה"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("changelink")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="שנה קישור"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("changeShow")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="שנה סטטוס הרשמה סלולרית"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
          
        </ListItemButton>
        <ListItemButton onClick={(e) => setWhat("sendNoti")}>
          <ListItemIcon>
            <ModeEdit sx={{ bgColor: "black" }} />
          </ListItemIcon>
          <ListItemText
            primary="Send Notification"
            sx={{ fontWeight: "bold", fontFamily: "Assistant" }}
          />
          
        </ListItemButton>
        
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment key="right">
        <Menu
          sx={{ marginTop: "10px", cursor: "pointer" }}
          onClick={toggleDrawer("right", true)}
        ></Menu>
        <SwipeableDrawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
        >
          {list("right")}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
