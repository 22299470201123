import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import logo from "../asset/images/signin_new.JPG";

import Cookies from "js-cookie";
import * as React from "react";
import { useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import Footer from "./Footer";
import { Copyright } from "./Copyright";
const theme = createTheme({
  typography: {
    fontFamily: [
      "Assistant",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
export default function SignIn() {
  const [wrongCredentials, setWrongCredentials] = React.useState(false);
  const [home, setHome] = useState(false);
  const [token, setToken] = useState("no");
  const [which, setWhich] = useState("no");
  const [block, setBlock] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axios
      .post(`${process.env.React_APP_BACKEND_URL}/api/login`, {
        email: data.get("email"),
        password: data.get("password"),
        token: "no",
        which: "no",
      })
      .then((data1) => {
        console.log(data1);

        if (data1.status === 200) {
          if (data1.data.user.block === "block") {
            setBlock(true);
            return;
          }
          let favs = "";
          data1.data.user.favids.split(",").forEach((e) => {
            favs += e + "f";
          });
          console.log(favs);
          Cookies.set("subscription", "No", { expires: 365 });
          localStorage.setItem("reload", "1");
          Cookies.set("favids", favs, { expires: 365 });
          Cookies.set("token", data1.data.access_token, { expires: 365 });
          Cookies.set("name", data1.data.user.name, { expires: 365 });
          Cookies.set("last_name", data1.data.user.last_name, { expires: 365 });
          Cookies.set("id", data1.data.user.id, { expires: 365 });
          Cookies.set("username", data1.data.user.username, { expires: 365 });
          Cookies.set("email", data1.data.user.email, { expires: 365 });
          Cookies.set("phone", data1.data.user.phone, { expires: 365 });
          Cookies.set(
            "bio",
            data1.data.user.bio === null ? "no" : data1.data.user.bio,
            { expires: 365 }
          );
          Cookies.set(
            "experience",
            data1.data.user.experience === null
              ? "no"
              : data1.data.user.experience,
            {
              expires: 365,
            }
          );
          Cookies.set(
            "profession",
            data1.data.user.profession === null
              ? "no"
              : data1.data.user.profession,
            {
              expires: 365,
            }
          );
          setHome(true);
        } else {
          setWrongCredentials(true);
        }
      })
      .catch((err) => {
        console.log(err);
        console.log(err.response.status);
        setWrongCredentials(true);
        if (err.response.status === 401) setWrongCredentials(true);
      });
  };

  let email = React.useRef();

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        {home && <Navigate to="/"></Navigate>}
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <NavLink to="/home" sx={{ textDecoration: "none" }}>
            <img
              src={logo}
              style={{ objectFit: "fit-content",  borderRadius: "20px" }}
              alt="פשוט למצוא בעל מקצוע לוגו"
            />
          </NavLink>
          <Typography
            component="h1"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
            variant="h5"
          >
            התחבר
          </Typography>
		  		  	<p
				style={{
				fontFamily: `${process.env.REACT_APP_FONT}`,
				fontWeight: "bold",
				}}
			>
				(ניתן להתחבר עם כתובת הדוא"ל בלבד)
			</p>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 1 }}
          >
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
              }}
            >
              כתובת דוא"ל: ​
            </Typography>
            <TextField
              margin="normal"
              required
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              //autoFocus
            />
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
              }}
            >
              סיסמא:​​
            </Typography>
            <TextField
              margin="normal"
              required
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              fullWidth
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="זכור אותי​"
            />
            {wrongCredentials && (
              <p
                style={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  color: "red",
                }}
              >
                דוא"ל או סיסמא אינם נכונים​
              </p>
            )}
            {block && (
              <p
                style={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  color: "red",
                }}
              >
                נחסמת על ידי האתר, צור קשר עם התמיכה
              </p>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, backgroundColor: 'black', color: 'white', }}
            >
              התחבר​
            </Button>
            {/*<NavLink to="/signinwithphone"><p style={{textAlign:"end",margin:"20px 0px"}}>LogIn with phone</p></NavLink>*/}

            <Grid
              container
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              <Grid
                item
                xs
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              >
                <NavLink to="/forgot" style={{ fontFamily: 'Assistant, sans-serif' }}>שכחת סיסמא?</NavLink>
              </Grid> 
             
            </Grid>
            <NavLink to="/signupwithphone" style={{ fontFamily: 'Assistant, sans-serif' }} variant="body2">
                  {"הרשם כלקוח​"}
                </NavLink>
                <NavLink to="/signupasprof" style={{ fontFamily: 'Assistant, sans-serif',display:'block' }} variant="body2">
                  {"הרשם כבעל מקצוע"}
                </NavLink>
          </Box>
        </Box>
        {/*<Copyright sx={{ mt: 8, mb: 4 }} />*/}
      </Container>
	  <Footer />
    </ThemeProvider>
  );
}
