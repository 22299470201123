 export const professions=["אזעקות ומצלמות",
"אינסטלציה",
"אינטרנט",
"בטון",
"בישול",
"גבס",
"גגן",
"גינון",
"דודי חשמל",
"דלתות",
"הדברות",
"הובלות",
"הנדימן",
"הסעות",
"הרכבות והתקנות",
"חלונות ותריסים",
"חשבונאות",
"חשמל",
"טיפול בחיות",
"טכנאי גז",
"טכנאי מחשבים",
"טכנאי טלפונים",
"טכנאי מוצרי חשמל",
"מוזיקה",
"מורה",
"מיזוג אויר",
"מכונאות רכב",
"מנעולן",
"מעקות",
"משלוחים",
"נגרות",
"נזילות ואיטום",
"ניקיון ופוליש",
"ספרות",
"עיצוב",
"עריכת דין",
"פרגולות ודקים",
"צבעי",
"צילום",
"קבלן",
"קוסמטיקה",
"ריצוף ופרקטים",
"ריקודים",
"שיפוצים",
"שערים וגדרות"];