import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useRef } from "react";

export default function PayPalPayment({ id, product, setComplete }) {
  const paypal = useRef();
  //const [com, setCom] = useState(false);

  useEffect(() => {
    const updateResult = () => {
      axios
        .put(
          `${process.env.React_APP_BACKEND_URL}/api/updateresult?id=${id}`,
          { proaccept: "Yes", payment: "Yes" },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .then((res) => {
          setComplete(true);
        })
        .catch((err) => console.log(err));
    };
    window.paypal
      .Buttons({
        createOrder: (data, actions, err) => {
          return actions.order.create({
            intent: "CAPTURE",
            purchase_units: [
              {
                description: product.desc,
                amount: {
                  currency_code: "USD",
                  value: product.cost,
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          const order = await actions.order.capture();
          console.log(order);
          if (order.status === "COMPLETED") updateResult();
        },
        onError: (err) => {
          console.log(err);
        },
      })
      .render(paypal.current);
  }, [product.cost, product.desc, setComplete, id]);

  return (
    <div>
      <div ref={paypal}></div>
    </div>
  );
}
