import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import logo from "../../asset/images/signin.jpg";
import SimpleBackdrop from "../BackDrop";
import { Copyright } from "../Copyright";
const theme = createTheme({
  typography: {
    fontFamily: [
      "Assistant",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});
export default function AdminLogin() {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [wrongCredentials, setWrongCredentials] = React.useState(false);
  const [home, setHome] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
    axios
      .post(`${process.env.React_APP_BACKEND_URL}/api/admin/login`, {
        email: data.get("email"),
        password: data.get("password"),
      })
      .then((data1) => {
        if (data1.status === 200) {
          Cookies.set("admintoken", data1.data.access_token, { expires: 365 });
          Cookies.set("adminId", data1.data.admin.id, { expires: 365 });
          setOpen(false);
          setHome(true);
        } else {
          setWrongCredentials(true);
        }
      })
      .catch((err) => {
        setOpen(false);
        console.log(err);
        setWrongCredentials(true);
        if (err.response.status === 401) setWrongCredentials(true);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />

        <Box
          sx={{
            fontFamily: "Assistant",
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {home && <Navigate to="/"></Navigate>}
          <NavLink to="/home" sx={{ textDecoration: "none" }}>
            <img src={logo} style={{ objectFit: "fit-content" }} alt="פשוט למצוא בעל מקצוע לוגו" />
          </NavLink>
          <Typography
            component="h1"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
            variant="h5"
          >
            היכנס כמנהל
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, fontFamily: "Assistant" }}
          >
            <TextField
              sx={{ fontFamily: "Assistant" }}
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              sx={{ fontFamily: "Assistant" }}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              sx={{ fontFamily: "Assistant" }}
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  sx={{ fontFamily: "Asisstant" }}
                />
              }
              label="Remember me"
            />
            {wrongCredentials && (
              <p style={{ color: "red", fontFamily: "Assistant" }}>
                Email and password combination is wrong
              </p>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontFamily: "Assistant" }}
            >
              Sign In
            </Button>
            <Grid container sx={{ fontFamily: "Assistant" }}>
              <Grid item xs sx={{ fontFamily: "Assistant" }}>
                <NavLink to="/forgot">Forgot password?</NavLink>
              </Grid>
              <Grid item sx={{ fontFamily: "Assistant" }}>
                <NavLink
                  to="/admin/signup"
                  variant="body2"
                  sx={{ fontFamily: "Assistant" }}
                >
                  {"Don't have an account? Sign Up"}
                </NavLink>
              </Grid>
            </Grid>
          </Box>
          {open && (
            <SimpleBackdrop
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              handleToogle={handleToggle}
            />
          )}
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
