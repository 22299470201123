import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
const FaqComponent = ({faq}) => {
 

  return (
    <div>
      {faq.map((faq, index) => (
        <Accordion key={index} sx={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',marginBottom:'20px',marginTop:'20px'}}>
          <AccordionSummary
          
            aria-controls={`faq-${index}-content`}
            id={`faq-${index}-header`}
          
          >
            <div style={{ display: 'flex', alignItems: 'center', borderRadius: '8px', padding: '10px', width: '100%' }}>
            <KeyboardArrowLeftIcon fontSize='large'color='white' sx={{display:"block"}}/><Typography sx={{ fontFamily: 'Assistant' }}>{faq.question}</Typography>
             
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'flex', flexDirection: 'column', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px', padding: '10px'}}>
            <Typography sx={{ fontFamily: 'Assistant' }}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FaqComponent;
