import { Box } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function PrivateOutlet() {
  const [show, setShow] = useState(false);
  const [auth, setAuth] = useState(false);
  const token = Cookies.get("token");

  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setAuth(true);
          setShow(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setAuth(false);
        setShow(true);
      })
      .finally(() => true);
  }, [token]);

  return <Box>{show && (auth ? <Outlet /> : <Navigate to="/signin" />)}</Box>;
}
