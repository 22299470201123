import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";
import axios from "axios";
import parse from "html-react-parser";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";


function ViewArticle() {
  const { id } = useParams();
  const [article, setArticle] = useState({});
  const [articles, setArticles] = useState([]);
  const [what, setWhat] = useState(false);
  const [delete1, setDelete] = useState(false);
  
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getarticle?id=${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        console.log(res);
        setArticle(res.data.Article[0]);
        setArticles(res.data.Articles);
        setWhat(true);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const deleteArticle = () => {
    axios
      .delete(
        `${process.env.React_APP_BACKEND_URL}/api/admin/deletearticle?id=${id}`,
        {
          headers: { Authorization: `Bearer ${Cookies.get("admintoken")}` },
        }
      )
      .then((res) => {
        console.log(res);
        //setNews(res.data.news);
        setDelete(true);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {what && !delete1 && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            margin: "20px 50px",
          }}
        >
          {article.images !== null && (
            <Box
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginBottom: "10px",
              }}
            >
              <Box
                component="img"
                sx={{
                  //fontFamily: `${process.env.REACT_APP_FONT}`,
                  display: { xs: "none", md: "block" },
                  minWidth: "200px",
                  maxWidth: "250px",
                  minHeight: "150px",
                  maxHeight: "350px",
                }}
                alt="The house from the offer."
                src={article.images.split(",")[0]}
              />
            </Box>
          )}

          <Typography
            variant="h6"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
              lineHeight: "1.25",
              wordSpacing: "3px",
            }}
          >
            {article.title}
          </Typography>
          <Typography
            variant="span"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              color: "grey",
              marginTop: "10px",
              marginBottom: "20px",
              display: "block",
            }}
          >
            תאריך: {article.date}
			{/*Date: {article.date}*/}
          </Typography>
		
          <Typography
            variant="pre"
            sx={{
			  fontFamily: `${process.env.REACT_APP_FONT}`,	
              fontWeight: "400",
              marginBottom: "20px",
			  color: "black",

            }}
          >
			
			{parse(article.description.toString())}
			
          </Typography>
          <Typography
            variant="p"
            display="block"
            sx={{
			  fontFamily: `${process.env.REACT_APP_FONT}`,
              marginTop: "30px",
            }}
          >
            צפיות: {article.views}
          </Typography>
          {/* {Cookies.get("adminId") && (
            <Button
              variant="contained"
              sx={{ display: "block", marginTop: "20px", width: "100%" }}
              onClick={deleteNews}
            >
              Delete News
            </Button>
          )}*/}
          <Box>
            <Typography
              variant="h6"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
              }}
            >
			
			{/* תמונות */}
			  
            </Typography>
            <ImageList
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                width: "100%",
                height: 450,
                marginTop: "0px",
                display: {},
              }}
              cols={3}
              rowHeight={164}
            >
              {article.images !== null &&
                article.images
                  .split(",")
                  .filter((e) => {
                    if (e !== ",") return e;
                    return "";
                  })
                  .map((item) =>
                    item.length > 0 ? (
                      <ImageListItem key={item.img}>
                        <img
                          src={item}
                          maxHeight="300px"
                          alt="Side"
                          loading="lazy"
                        />
                      </ImageListItem>
                    ) : (
                      ""
                    )
                  )}
            </ImageList>
          </Box>
          {Cookies.get("adminId") && (
            <Button
              variant="contained"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                marginTop: "20px",
                width: "100%",
              }}
              onClick={deleteArticle}
            >
              Delete Article
            </Button>
          )}
        </Box>
      )}
      {delete1 && <Navigate to="/home"></Navigate>}
    </Box>
  );
}

export default ViewArticle;
