import { Avatar, Box, Button, Rating, Typography } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import Cookies from "js-cookie";
import React from "react";
import { NavLink } from "react-router-dom";
import { FlexBox } from "./FlexBox";
import ReviewsOfUser from "./ReviewsOfUser";
function ProfessionalAboutMe({ user }) {
  return (
    <Box
      sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, marginTop: "10px" }}
    >
      <FlexBox
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, flexWrap: "wrap" }}
      >
        <FlexBox sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <Avatar
            sx={{
              bgcolor: "#F20091",
              padding: { xs: "5px", md: "20px" },
            }}
          >
            {user.name.charAt(0) + " " + user.last_name.charAt(0)}
          </Avatar>
          <Box margin="0px 20px">
            <Typography
              variant="h6"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              {" "}
              {user.username}
            </Typography>
            {user.bio !== null && (
              <Typography
                variant="span"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  color: "grey",
                }}
              >
                {user.bio}
              </Typography>
            )}
            {user.bio !== null && (
              <FlexBox sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
                <Typography
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    display: "inline-block",
                  }}
                >
                  דירוג משוקלל:{" "}
                </Typography>
                <Rating
                  dir="ltr"
                  name="simple-controlled"
                  value={user.rating}
                  precision={0.5}
                  readOnly
                />
              </FlexBox>
            )}
          </Box>
        </FlexBox>
        {Cookies.get("id").toString() === user.id.toString() && (
          <NavLink
            to="/editprofile"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button
              variant="outlined"
              sx={{
                marginTop: { xs: "20px", md: "0px" },
                textAlign: { xs: "center" },
              }}
            >
              פרופיל ערוך
            </Button>
          </NavLink>
        )}
      </FlexBox>
      <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
        <Typography variant="h6" sx={{ display: "block", textAlign: "center", fontFamily: "Assistant" }}>
		דירוג וחוות דעת של מנהל האתר
		</Typography>
        <ReviewsOfUser id={user.id} />
      </Box>
    </Box>
  );
}

export default ProfessionalAboutMe;
