import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MissedVideoCallIcon from "@mui/icons-material/MissedVideoCall";
import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import BasicModal from "./ImageModal";
import RenderPhotos from "./RenderPhotos";
import Snackbar1 from "./Snaclbar";
import "./styles.css";
const UploadPhoto = ({ setImages }) => {
  const [error, setError] = useState(false);
  const [size, setSize] = useState(0);
  const [fileError, setFileError] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState({});
  const handleImageChange = (e) => {
    setError(false);
    let sizeOfImages = 0;
    for (let i = 0; i < e.target.files.length; i++)
      sizeOfImages += e.target.files[i].size;
    setSize(sizeOfImages);
    console.log(e.target.files);
    if (sizeOfImages > 30 * 1024 * 1024) setError(true);
    if (e.target.files) {
      let fileArray = [];
      //   const filesArray = Array.from(e.target.files).map((file, i) =>
      //     console.log(file)
      //   );
      for (let i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > 10 * 1024 * 1024) {
          setFileError(true);
          continue;
        }

        let url = URL.createObjectURL(e.target.files[i]);
        fileArray.push({
          url,
          type: e.target.files[i].type,
          size: e.target.files[i].size,
        });
      }
      // console.log(e.target.files[0].type);

      // console.log("filesArray: ", filesArray);
      console.log(e.target.files);
      setSelectedImages((prevImages) =>
        Array.from(prevImages).concat(e.target.files)
      );
      console.log(selectedImages);
      setSelectedFiles((prevImages) => prevImages.concat(fileArray));
      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );
    }
    console.log(selectedFiles);
  };

  function deleteHandler(image) {
    let sel = {};
    let p = 0;
    let sizeOfImages = size;
    setError(false);
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].url.toString() === image.toString()) {
        p = i;
        sizeOfImages -= selectedFiles[i].size;
        continue;
      }
      console.log(selectedImages[i]);
      sel[p] = selectedImages[i];

      p++;
    }
    setSize(sizeOfImages);
    if (sizeOfImages > 30 * 1024 * 1024) {
      setError(true);
    }
    console.log(sel);
    setSelectedImages(sel);
    setSelectedFiles(selectedFiles.filter((e) => e !== image));
    URL.revokeObjectURL(image);
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    console.log("Chal be chutiye");
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const uploadImages = () => {
    console.log(selectedImages);
    handleClose();
    if (size <= 30 * 1024 * 1024) setImages(selectedImages, size);
    else setError(true);
    //
  };
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: "70%",
        margin: "20px auto",
        padding: "30px",
        textAlign: "center",
        backgroundColor: "#323436",
      }}
    >
      {fileError && <Snackbar1 />}

      <Button
        variant="outlined"
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          color: "white",
          margin: "0px 10px 10px 10px",
        }}
        component="label"
      >
        <span style={{ marginLeft: "5px" }}>בחר תמונה​</span>
        <AddAPhotoIcon />
        <input
          hidden
          multiple
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
      </Button>

      <Button
        variant="outlined"
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          color: "white",
          margin: "0px 10px 10px 10px",
          textAlign: "center",
        }}
        component="label"
      >
        <span style={{ marginLeft: "5px" }}> בחר סרטון</span>
        <MissedVideoCallIcon sx={{ marginTop: "5px" }} />​
        <input
          hidden
          multiple
          type="file"
          accept="video/*"
          onChange={handleImageChange}
        />
      </Button>
      <Button
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          color: "white",
          marginBottom: "10px",
        }}
        variant="outlined"
        onClick={selectedImages.length > 0 ? handleOpen : uploadImages}
      >
        <span>{selectedImages.length > 0 ? "להעלות קבצים" : "דלג​"}</span>
        <CloudUploadIcon sx={{ padding: "5px" }} />
      </Button>

      <Box>
        {<RenderPhotos source={selectedFiles} deleteHandler={deleteHandler} />}
      </Box>

      {open && (
        <BasicModal
          handleOpen={handleOpen}
          handleClose={handleClose}
          setOpen={setOpen}
          open={open}
          cancel={error}
          uploadImages={uploadImages}
        />
      )}
    </Box>
  );
};

export default UploadPhoto;
