import { Button, Switch } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";

export default function ChangeShow(){
    const [show,setShow]=useState(true);
    const [ins,setIns]=useState(false);
    const [checked, setChecked] = React.useState(true);

    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

    useEffect(()=>{
      axios.get(`${process.env.React_APP_BACKEND_URL}/api/admin/show`).then(res=>{
        console.log(res);
        setChecked(res.data.data[0].show==="yes"?true:false);
        setIns(true)
      }).catch(err=>console.log(err))
    },[]);

    const submit=()=>{
        axios.post(`${process.env.React_APP_BACKEND_URL}/api/admin/changeshow`,{
            show:checked?"yes":"no"
        }).then(res=>{
            console.log(res);
            //location.reload();
        }).catch(err=>console.log(err))
    }
    return (
      <div style={{width:"65%",margin:"30px auto"}}>
       
         {ins&&<div>
         <div style={{display:"flex",alignItems:"center",gap:"20px"}}>
            <p>שנה את ההרשמה עם מספר סלולרי:</p>
            <Switch
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />
    </div>
    <Button onClick={submit} variant="contained">שנה</Button>
            </div>}

        </div>
    )
}