import { Cancel } from "@mui/icons-material";
import { Box, styled, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Notifications({ notification, removeNotification, handleClose }) {
  const FlexBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const [what, setWhat] = useState(false);
  const handleCancel = (e) => {
    setWhat(true);
    axios
      .delete(
        `${process.env.React_APP_BACKEND_URL}/api/deletenoti?id=${notification.id}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {!what && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            padding: "10px",
            marginBottom: "10px",
          }}
        >
          <FlexBox>
            <NavLink
              onClick={handleClose}
              to={`/showbid/${notification.bidId}`}
              style={{ textDecoration: "none", color: "black" }}
            ></NavLink>
            <FlexBox>
              <svg
                width="50"
                height="50"
                viewBox="0 0 128 128"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M98.89 98.758l-.107-52.202-38.339.064-13.096-13.394H28.755v65.532h70.136z"
                  stroke="#007FED"
                  stroke-width="4"
                  stroke-miterlimit="10"
                />
                <path
                  d="M51.459 37.115h39.903v9.695"
                  stroke="#007FED"
                  stroke-width="4"
                />
                <path
                  d="M41.25 80.314h43.852M41.25 68.905h43.852"
                  stroke="#007FED"
                  stroke-width="4"
                  stroke-miterlimit="10"
                />
              </svg>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    fontWeight: "bold",
                    display: "block",
                  }}
                >
                  {notification.notification.toString().substr(0, 50) + "..."}
                </Typography>
              </Box>
            </FlexBox>
            <Cancel sx={{ float: "left" }} onClick={handleCancel} />
          </FlexBox>
        </Box>
      )}
    </Box>
  );
}

export default Notifications;
