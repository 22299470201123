import { Box } from "@mui/material";
import React from "react";

function AdvertVideos({ video, height, width }) {
  return (
    <Box>
      <video width={width} height={height} controls="controls">
        <source src={video} type="video/mp4"></source>
      </video>
    </Box>
  );
}

export default AdvertVideos;
