import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useState } from "react";
import { Navigate, NavLink } from "react-router-dom";
import { FlexBox } from "./FlexBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: 250,
    md: 400,
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  textAlign: "center",
  fontFamily: "Assistant",
  p: 4,
};
export default function AskModal({
  open,
  id,
  setOpen2,
  handleOpen,
  handleClose,
  result,
  changeStep,
  mes,
}) {
  const [doubleCheck, setDoubleCheck] = useState(false);
  const [navigate, setNavigate] = useState(false);
  const [payment, setPayment] = useState(false);
  const updateBidAccept = () => {
    handleClose();
    setOpen2(true);
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/updateresult?id=${id}`,
        { bidderaccept: "Yes" },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        setOpen2(false);
        changeStep(1);
      })
      .catch((err) => console.log(err));
  };
  const handleOfferReject = () => {
    handleClose();
    setOpen2(true);
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/updateresult?id=${id}`,
        { bidderaccept: "no", major: 3 },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        //setOpen2(false);
        window.location.reload(true);
        //changeStep(1);
      })
      .catch((err) => console.log(err));
  };
  const goToPayment = () => {
    let month = parseInt(new Date().getMonth()) + 1;
    // if (Cookies.get("subscription").toString() === "Yes") {
    //   handleClose();
    //   changeStep(3);
    // } else
    if (result.paymenttype === "free") {
      axios
        .put(
          `${process.env.React_APP_BACKEND_URL}/api/updateresult?id=${id}`,
          { proaccept: "Yes", payment: "Yes" },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .then((res) => {
          handleClose();

          changeStep(3);
        })
        .catch((err) => console.log(err));
    } else setPayment(true);
  };
  const handleBidderReject = () => {
    handleClose();
    setOpen2(true);
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/updateresult?id=${id}`,
        { proaccept: "no", major: 3 },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        setNavigate(true);
        window.location.reload(true);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ fontFamily: "Assistant" }}>
          {!doubleCheck && (
            <Box sx={style}>
              {payment && <Navigate to={`/payment/${id}`}></Navigate>}
              {navigate && <Navigate to="/home"></Navigate>}
              <Typography
                id="modal-modal-title"
                sx={{ marginBottom: "10px", fontFamily: "Assistant" }}
                variant="h6"
                component="h2"
              >
                {mes === "Offer Accept"
                  ? `ההצעה הזוכה היא ₪${result.bidAmount}. תרצה לאשר את ההצעה ולהתקדם?
                  `
                  : `הצעת המחיר שלך היא על סך ₪​${result.bidAmount}. תרצה לאשר את ההצעה ולהתקדם?`}
              </Typography>
              <FlexBox
                sx={{
                  flexDirection: { xs: "column", md: "row" },
                  fontFamily: "Assistant",
                }}
              >
                <Button
                  variant="contained"
                  onClick={
                    mes === "Offer Accept" ? updateBidAccept : goToPayment
                  }
                  sx={{
                    marginRight: "10px",
                    padding: "5px 30px",
                    marginBottom: "10px",
                  }}
                >
                  {mes === "Offer Accept" ? "כן" : "מאשר"}
                </Button>
                {mes === "Offer Accept" && (
                  <NavLink
                    to={`/viewuserprofile/${result.bidderId}`}
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        marginRight: "10px",
                        padding: "5px 30px",
                        marginBottom: "10px",
                        fontFamily: "Assistant",
                      }}
                    >
                      הצג בעל מקצוע
                    </Button>
                  </NavLink>
                )}
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: "Assistant",
                    marginRight: "10px",
                    padding: "5px 30px",
                    marginBottom: "10px",
                  }}
                  onClick={(e) => {
                    console.log("Kya chutiyap he");
                    setDoubleCheck(true);
                  }}
                >
                  לא מאשר
                </Button>
              </FlexBox>
            </Box>
          )}
          {doubleCheck && (
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                sx={{ marginBottom: "10px", fontFamily: "Assistant" }}
                variant="h6"
                component="h2"
              >
                האם אתה בטוח שברצונך לבטל את ההצעה?
              </Typography>
              <FlexBox>
                <Button
                  variant="contained"
                  onClick={
                    mes === "Offer Accept"
                      ? handleOfferReject
                      : handleBidderReject
                  }
                  sx={{
                    marginRight: "10px",
                    padding: "5px 30px",
                    fontFamily: "Assistant",
                  }}
                >
                  כן אני בטוח
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    marginRight: "10px",
                    padding: "5px 30px",
                    fontFamily: "Assistant",
                  }}
                  onClick={(e) => setDoubleCheck(false)}
                >
                  חזור
                </Button>
              </FlexBox>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
