import { Box } from "@mui/material";
import React from "react";
import PostBidDescriptionUpdate from "./PostBidDescriptionUpdate";
import UploadPhotoUpdate from "./UploadPhotoUpdate";
function PostBidUploadImageUpdate({ bidData }) {
  const [next, setNext] = React.useState(false);
  const setImages = (e, size, bidImages1) => {
    let files = [];
    console.log("Abid");
    if (e.length !== undefined) {
      for (let i = 0; i < e.length; i++) files.push(e[i]);
      console.log(files);
      bidData.bidImages = files;
      bidData.size = size;
      setNext(true);
    } else {
      bidData.bidImages = e;
      bidData.size = size;
      setNext(true);
    }

    bidData.publicid = "";

    let ima = "";
    let imageS = "";
    for (let i = 0; i < bidImages1.length; i++) {
      ima += bidImages1[i].url + ",";
      //console.log(bidImages1.size);
      imageS += bidImages1[i].size + ",";
      bidData.publicid += bidImages1[i].publicid;
    }
    console.log(bidImages1);
    bidData.images = ima;
    bidData.imagesSize = imageS;
    console.log(imageS);

    bidData.size = size;
    setNext(true);
  };

  return (
    <Box>
      {!next && (
        <UploadPhotoUpdate
          setImages={setImages}
          bidImages={bidData.images}
          size1={bidData.size}
          imagesSize={bidData.imagesSize}
          publicid={bidData.publicid}
        />
      )}
      {next && <PostBidDescriptionUpdate bidData={bidData} />}
    </Box>
  );
}

export default PostBidUploadImageUpdate;
