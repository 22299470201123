import {
  Avatar,
  Box,
  Button,
  Rating,
  Typography,
  createTheme,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import Cookies from "js-cookie";
import React from "react";
import { NavLink } from "react-router-dom";
import { FlexBox } from "./FlexBox";
import ReviewsOfUser from "./ReviewsOfUser";
function AboutMe({ user }) {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Assistant",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  return (
    <Box flex={3} p={2}>
      <FlexBox sx={{ flexWrap: "wrap", fontFamily: "Assistant" }}>
        <FlexBox sx={{ fontFamily: "Assistant" }}>
          <Avatar sx={{ bgcolor: "#FFFFFF", padding: "20px" }}>
            {user.name.charAt(0) + " " + user.last_name.charAt(0)}
          </Avatar>
          <Box margin="0px 20px" sx={{ fontFamily: "Assistant" }}>
            <Typography variant="h6" sx={{ fontFamily: "Assistant" }}>
              {" "}
              {user.username}
            </Typography>
            {user.bio !== null && (
              <Typography
                variant="span"
                sx={{ color: "grey", fontFamily: "Assistant" }}
              >
                {user.bio}
              </Typography>
            )}
            {user.bio !== null && (
              <FlexBox>
                <Typography
                  sx={{ display: "inline-block", fontFamily: "Assistant" }}
                >
                  דירוג משוקלל:{" "}
                </Typography>
                <Rating
                  dir="ltr"
                  name="simple-controlled"
                  value={user.rating}
                  precision={0.5}
                  readOnly
                />
              </FlexBox>
            )}
          </Box>
        </FlexBox>
        {Cookies.get("id").toString() === user.id.toString() && (
          <NavLink
            to="/editprofile"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Button
              variant="outlined"
              sx={{
                marginTop: { xs: "20px", md: "0px" },
                textAlign: { xs: "center" },
                fontFamily: "Assistant",
              }}
            >
              ערוך פרופיל​
            </Button>
          </NavLink>
        )}
      </FlexBox>
		<Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
			<Typography variant="h6" sx={{ fontFamily: "Assistant", textAlign: "center" }}>
			דירוג וחוות דעת של מנהל האתר<br />
			</Typography>
		<Typography
			variant="body2"
			sx={{
				fontFamily: `${process.env.REACT_APP_FONT}`,
				fontSize: "16px", 
				textAlign: "center", 
				}}
		>
    (לעדכון יש לפנות אלינו)
  </Typography>
        <ReviewsOfUser id={user.id} />
      </Box>
    </Box>
  );
}

export default AboutMe;
