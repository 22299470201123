import {
  Backdrop,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import AdvertVideos from "./AdvertVideos";
import { FlexBox } from "./FlexBox";
import Snackbar1 from "./Snaclbar";
import TimeandMoney from "./TimeandMoney";
function Posts({ post, bid }) {
  const [showSnack, setShowSnack] = useState(false);
  const [mes, setMes] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, width: "100%" }}>
      {showSnack && <Snackbar1 mes={"נוסף לרשימת המשאלות בהצלחה"} />}
      <Card sx={{ width: { md: "320px", xs: "100%" } }}>
        <NavLink
          to={`/showbid/${post.id}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <CardActionArea>
            {post.images !== null && (
              <Box>
                <img
                  src={post.images.split(",")[0]}
                  maxWidth="100%"
                  style={{
                    objectFit: "fill",
                    margin: "0px auto",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    textAlign: "center",
                  }}
                  alt="green iguana"
                />
              </Box>
            )}
            {post.images === null && post.video !== null && (
              <AdvertVideos
                width="100%"
                height="200px"
                style={{
                  margin: "0px auto",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  textAlign: "center",
                }}
                video={post.video.split(",")[0]}
              />
            )}
            {post.images === null && post.video === null && (
              <Box
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  backgroundColor: "#7C7C7C",
                  height: "200px",
                  margin: "0px auto",
                  verticalAlign: "center",
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    color: "white",
                  }}
                >
                  Bidit
                </Typography>
              </Box>
            )}
            <CardContent>
              <FlexBox sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
                <Typography
                  variant="h6"
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                >
                  {post.title.substr(0, 17) + "..."}
                </Typography>
              </FlexBox>
              <Typography
                variant="p"
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              >
                {post.extra}
              </Typography>
            </CardContent>
          </CardActionArea>
        </NavLink>
        <TimeandMoney
          bid={bid}
          post={post}
          handleToggle={handleToggle}
          setShowSnack={setShowSnack}
          handleClose={handleClose}
          setMes={setMes}
        />
      </Card>
      <Backdrop
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

export default Posts;
