import { Facebook, Instagram, WhatsApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../asset/images/logo.png";
import ValidateEmail from "../asset/js/ValidateEmail";
import { Copyright } from "./Copyright";
import { FlexBox } from "./FlexBox";

function Footer() {
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [mes, setMes] = useState("");

  const StyledInstagramIcon = styled(Instagram, {
    name: "Instagram",
    slot: "Wrapper",
  })({
    color: "#E4405F",
    "&:hover": { color: "#E4405F" },
  });
  const StyledFacebookIcon = styled(Facebook, {
    name: "Facebook",
    slot: "Wrapper",
  })({
    color: "#1877F2",
    "&:hover": { color: "#1877F2" },
  });
  const StyledWhatsappIcon = styled(WhatsApp, {
    name: "WhatsApp",
    slot: "Wrapper",
  })({
    color: "#25D366",
    "&:hover": { color: "#25D366" },
  });
  const [validEmail, setValidEmail] = useState(true);
  const sendMail = () => {
    if (!ValidateEmail(email)) {
      setValidEmail(false);
      return;
    }
    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/customermail`,
        {
          title: title,
          mes,
          email,
        },
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      )
      .then((res) => {
        setTitle("");
        setMes("");
        setEmail("");
        setValidEmail(true);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        backgroundColor: "#EEEEF0",
        color: "black",
        marginTop: "30px",
        paddingBottom: "10px",
      }}
    >
      <Stack
        spacing={2}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, marginTop: "30px" }}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-evenly"
      >
        <FlexBox
          flex={1}
          p={1}
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            flexDirection: { xs: "row", md: "column" },
            justifyContent: "start",
            alignItems: "right",
          }}
        >
          <NavLink to="/" style={{ textDecoration: "none" }}>
            <img
              src={logo}
              style={{
                maxWidth: "50px", //120
                height: "50px",  //30
                padding: "0px", //0
                margin: "0px 5px", //0 5 
				borderRadius: "50%", // Set borderRadius to 50% for a circular shape
              }}
              alt="Bidit logo"
            />
          </NavLink>
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: "block",
              fontWeight: "bold",
              color: "black",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
            }}
          >
		  
          </Typography>
          <FlexBox>
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                fontWeight: "bold",
                marginBottom: "10px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              דיגיטל ויצירת קשר​
            </Typography>

            <a
              href="https://wa.me/972553181000"
              target="_blank"
              rel="noreferrer"
            >
              <StyledWhatsappIcon sx={{ margin: "0px 5px" }} />
            </a>
            <a
              href="https://facebook.com/100092381437181"
              target="_blank"
              rel="noreferrer"
            >
              <StyledFacebookIcon sx={{ margin: "0px 5px" }} />
            </a>
            <a
              href="https://www.instagram.com/biditsimple"
              target="_blank"
              rel="noreferrer"
            >
              <StyledInstagramIcon sx={{ margin: "0px 5px" }} />
            </a>
          </FlexBox>
        </FlexBox>
        <FlexBox
          flex={1}
          p={1}
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            flexDirection: { xs: "row", md: "column" },
            justifyContent: "start",
            alignItems: "right",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: "block",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
              fontWeight: "bold",
            }}
          >
            פרטיות​
          </Typography>
          <NavLink
            to="/privacypolicy"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                marginBottom: "10px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              מדיניות פרטיות​
            </Typography>
          </NavLink>
          <NavLink
            to="/termsofuse"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                marginBottom: "10px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              תנאי שימוש​
            </Typography>
          </NavLink>
        </FlexBox>
        <FlexBox
          flex={1}
          p={1}
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            flexDirection: { xs: "row", md: "column" },
            justifyContent: "start",
            alignItems: "right",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: "block",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
              fontWeight: "bold",
            }}
          >
            מאמרים​
          </Typography>
          <NavLink
            to={`/showarticles`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                marginBottom: "10px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              עיין במאמרים​
            </Typography>
          </NavLink>
        </FlexBox>
        <FlexBox
          flex={1}
          p={1}
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            flexDirection: { xs: "row", md: "column" },
            justifyContent: "start",
            alignItems: "right",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: "block",
              marginBottom: "10px",
              marginRight: "5px",
              marginLeft: "5px",
              fontWeight: "bold",
            }}
          >
            מי אנחנו​
          </Typography>
          <NavLink
            to={`/support`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                marginBottom: "10px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              תמיכה​
            </Typography>
          </NavLink>
          <NavLink
            to={`/faq`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                marginBottom: "10px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              שאלות נפוצות​
            </Typography>
          </NavLink>
          <NavLink
            to={`/aboutus`}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "block",
                marginBottom: "10px",
                marginRight: "5px",
                marginLeft: "5px",
              }}
            >
              קצת עלינו​
            </Typography>
          </NavLink>
        </FlexBox>
        <Box flex={1} p={1}>
          <Typography variant="p" sx={{ fontFamily: "Assistant", fontWeight: "bold" }}>
            שאלה? שלח לנו דוא"ל​
          </Typography>
          <Typography
            variant="p"
            sx={{ fontFamily: "Assistant", marginTop: "10px", display: "block", fontWeight: "bold" }}
          >
            נושא הפניה
          </Typography>
          <TextField
            margin="normal"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "20px",
              color: "black",
            }}
            required
            fullWidth
            id="title"
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
            name="title"
          />
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
          >
            תוכן הפניה
          </Typography>
          <TextField
            margin="normal"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "20px",
            }}
            required
            fullWidth
            id="message"
            value={mes}
            onChange={(e) => setMes(e.currentTarget.value)}
            name="message"
          />
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
          >
            ציין את כתובת המייל שלך
          </Typography>
          <TextField
            dir="rtl"
            margin="normal"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "20px",
            }}
            color="secondary"
            required
            fullWidth
            id="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            name="email"
          />
          {!validEmail && (
            <p
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                color: "red",
              }}
            >
              יש להזין אימייל תקין
            </p>
          )}
          <Button
            variant="contained"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              backgroudnColor: "white",
            }}
            onClick={(e) => sendMail()}
          >
            שלח​
          </Button>
        </Box>
      </Stack>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Box>
  );
}

export default Footer;
