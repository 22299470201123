import { Box, Typography } from "@mui/material";
import axios from "axios";
import parse from "html-react-parser";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";

function FAQ() {
  const [data, setData] = useState("");

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getcontent?change=faq`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((response) => {
        console.log(response.data.Content[0]);
        setData(response.data.Content[0]["faq"]);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box>
      <Box
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          width: "75%",
          margin: "20px auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginBottom: "20px",
          }}
        >
        </Typography>
        {data.length > 0 && (
          <Typography
            variant="pre"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            {parse(data)}
          </Typography>
        )}
      </Box>
      <Footer />
    </Box>
  );
}

export default FAQ;

