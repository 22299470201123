import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import { FlexBox } from "./FlexBox";

function ExplainUser({ open, handleClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: 180,
      md: 400,
    },
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    fontFamily: `${process.env.REACT_APP_FONT}`,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "10px",
            }}
            variant="h6"
            component="h2"
          >
		  רק בעלי מקצוע יכולים לתת הצעות מחיר למכרזים. אם אתה מעוניין להשתתף במכרז, הוסף לפרופיל שלך
		  את המקצוע שלך, אזור השירות שלך וספר מעט על עצמך
          </Typography>
          <FlexBox sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
            <NavLink
              to="/editprofile"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Button
                variant="contained"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  marginRight: "10px",
                  padding: "5px 30px",
                }}
              >
                ערוך פרופיל
              </Button>
            </NavLink>
            <Button
              variant="contained"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginRight: "10px",
                padding: "5px 30px",
              }}
              onClick={handleClose}
            >
              חזור
            </Button>
          </FlexBox>
        </Box>
      </Modal>
    </div>
  );
}

export default ExplainUser;
