import { Box, Button, Typography } from "@mui/material";
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { professions } from "../asset/js/Professions";
import PostBidUploadImageUpdate from "./PostBidUploadImageUpdate";

const animatedComponents = makeAnimated();

function PostBidGeneralInfoUpdate({ bidData }) {
  const [next, setNext] = React.useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState(
    bidData.skills !== null
      ? bidData.skills.split(",").map((e) => {
          return { value: e, label: e };
        })
      : ""
  );
  let options = [];

  Array.from(professions).forEach((e) => {
    options.push({ value: e, label: e });
  });
  let options1;
  if (bidData.skills !== null)
    options1 = options.concat(
      bidData.skills.split(",").map((e) => {
        return { value: e, label: e };
      })
    );
  else options1 = options;
  options = Array.from(new Set(options1));
  const handleSubmit = (event) => {
    event.preventDefault();
    let profession = "";
    for (let i = 0; i < selectedOptions.length; i++) {
      profession += selectedOptions[i].value + ",";
    }
    bidData.skills = profession;
    setNext(true);
  };

  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {!next && (
        <Box
          component="form"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          onSubmit={handleSubmit}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              textAlign: "center",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            פרסם מכרז עבודה ובדוק שכל השדות מולאו כראוי​
          </Typography>
          <Select
            components={animatedComponents}
            isMulti
            name="professions"
            value={selectedOptions}
            options={options}
            onChange={(item) => {
              setSelectedOptions(item);
            }}
            className="select"
            isClearable={true}
            isSearchable={true}
            isDisabled={false}
            isLoading={false}
            closeMenuOnSelect={false}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2 }}
          >
            הבא​
          </Button>
        </Box>
      )}
      {next && <PostBidUploadImageUpdate bidData={bidData} />}
    </Box>
  );
}

export default PostBidGeneralInfoUpdate;
