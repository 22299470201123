import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { FlexBox } from "./FlexBox";

function ChangePassword({
  open,
  handleClose,
  user,
  addChangedUser,
  updatePage,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [value, setValue] = React.useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: 200,
      md: 400,
    },
    fontFamily: "Assistant",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [invalid, setInvalid] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setInvalid(false);
    if (password.length < 8) setInvalid(true);
    else {
      axios
        .put(
          `${process.env.React_APP_BACKEND_URL}/api/updateuser?id=${user.id}`,
          {
            password,
          },
          { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
        )
        .then((res) => {
          handleClose("password");
          addChangedUser("password", password);
          updatePage();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [password, setPassword] = useState("");
  return (
    <Modal
      open={open}
      onClose={(e) => handleClose("password")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontFamily: "Assistant",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          שנה סיסמא​
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="p"
          sx={{ fontFamily: "Assistant", display: "inline-block" }}
        >
          בחר סיסמא באורך של לפחות 8 תווים ושאינה אחת הסיסמאות הקודמות שלך​
        </Typography>
        <FlexBox sx={{ flexDirection: { xs: "column", md: "row" } }}>
          <Typography variant="p" sx={{ fontWeight: "bold" }}>
            הזן סיסמא ​
          </Typography>
          <TextField
            sx={{
              fontFamily: "Assistant",
              marginTop: "15px",
              marginBottom: "15px",
            }}
            variant="outlined"
            type={showPassword ? "text" : "password"} // <-- This is where the magic happens
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              // <-- This is where the toggle button is added.
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button onClick={handleSubmit} variant="contained">
            עדכן​
          </Button>
        </FlexBox>
        {invalid && (
          <p style={{ fontFamily: "Assistant" }}>
            צריך להיות באורך של לפחות 8 תווים
          </p>
        )}
      </Box>
    </Modal>
  );
}

export default ChangePassword;
