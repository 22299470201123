import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import * as React from "react";

export default function Snackbar1({ mes }) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div style={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      <Snackbar
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={mes}
        action={action}
      />
    </div>
  );
}
