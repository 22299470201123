import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../asset/images/logo.png";
function Thanks() {
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: "70%",
        margin: "0px auto",
      }}
    >
      <img
        src={logo}
        style={{ height: "200px", width: "fit-content" }}
        alt="Bidit logo"
      />
      <Typography sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
        Thanks for subscribing to BidIt. Now you can get access to jobs without
        paying the one time payment. Find more jobs
        <NavLink to="/home"> Here</NavLink>
      </Typography>
    </Box>
  );
}

export default Thanks;
