import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select as Select1,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import returnCityNames from "../asset/js/CityNames";
import { professions } from "../asset/js/Professions";
import { FlexBox } from "./FlexBox";
import ShowDetailSignup from "./SignUpCompo/ShowDetailsSignup";
import "./counter.css"
const MenuProps = {
  menuPortalTarget: document.body, // Render the menu outside the parent components
};


const animatedComponents = makeAnimated();
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
function Next({ email, token, which,type,phone,setProgress,progress,prof }) {
  console.log(type+" "+email);
  const [validName, setValidName] = useState(false);
  const [validPass, setValidPass] = useState(false);
  const [validUserName, setValidUsername] = useState(false);
  const [validBio, setValidBio] = useState(false);
  const [professional, setProfessional] = useState(false);
  const [area, setArea] = useState("South");
  const [phoneError, setPhoneError] = useState(false);
  const [login, setLogin] = useState(false);
  const [emailError,setEmailError]=useState(false)

  const [validArea, setInValidArea] = useState(false);
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Assistant",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  const createUser = (e) => {
    console.log("token");
    console.log("Abid" + email);
    e.preventDefault();
    let data = new FormData(e.currentTarget);
    if(!prof){
    allSubmit(data);
  return;
}
  allSubmit(data);
  };
  const inputStyle = {
    WebkitAppearance: 'none', /* WebKit browsers */
    margin: 0,
    MozAppearance: 'textfield', /* Firefox */
  };
  const [allData,setAllData]=useState({name:"",last_name:"",password:"",username:"",bio:"",phone:"",email:""});
  const allSubmit = (data) => {


  
    let profession = "";
    selectedOptions.forEach((e, i) => {
      const p = e.value;
      //  console.log(p);
      if (i === selectedOptions.length - 1) profession += p;
      else profession += p + ",";
    });
    let userArea = "";
      personName.forEach((e) => (userArea += e + ","));
   

          axios
        .post(`${process.env.React_APP_BACKEND_URL}/api/register`, {
          email:type==="phone"?allData.email:email,
          username:allData.username,
          password:allData.password,
          name:allData.name,
          last_name: allData.last_name,
          profession,
          area: userArea,
          bio:allData.bio,
          experience:allData.experience,
          token,
          which,
          emailNoti: emailCheck ? "yes" : "no",
          smsNoti: smsCheck ? "yes" : "no",
          phone: type!=="phone"?allData.phone:email,
        })
        .then((data1) => {
          if (data1.status === 200) {
            data.getAll.value = "";
            setLogin(true);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.includes("phone")) setPhoneError(true);
          else setValidUsername(true);
        })
      
    
  };
  const checkPhone=(e)=>{
    axios
    .post(
      `${process.env.React_APP_BACKEND_URL}/api/checkphone`,{
        phone:allData.phone
      }
    )
    .then((data1) => {
      console.log(data1);
      setProgress(e=>e+1);
 

    })
    .catch((err) => {
      console.log(err);
      console.log(err)
      setPhoneError(true);

    });
  }
  const checkEmail=(e)=>{
    console.log(e)
     console.log(allData.email)
      console.log("Abid Ahmed")
       axios
      .get(
        `${process.env.React_APP_BACKEND_URL}/api/checkemail?email=${allData.email}`
      )
      .then((data1) => {
        console.log(data1)
        setProgress(e=>e+1);
    
      })
      .catch((err) => {
        console.log(err);
        setEmailError(true);
      });
  }

  const checkUsername=(e)=>{
    console.log(e);
    axios
    .post(
      `${process.env.React_APP_BACKEND_URL}/api/checkusername`,{username:allData.username}
    )
    .then((data1) => {
      console.log(data1);
      console.log(allData.username);
     setProgress(e=>e+1);

    })
    .catch((err) => {
      console.log(err);
      setValidUsername(true);
    });
  }
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    
      setAllData({
        ...allData,
        personName:true
      })
    
   
  };
  const [emailCheck, setEmailCheck] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  let options = [];
  Array.from(professions).forEach((e) => {
    options.push({ value: e, label: e });
  });
  console.log(progress);
  // const options = [
  //   { value: "Mechanic", label: "Mechanic" },
  //   { value: "Driver", label: "Driver" },
  //   { value: "Gardener", label: "Gardener" },
  //   { value: "Car Mechanic", label: "Car Mechanic" },
  //   { value: "Carpenter", label: "Carpenter" },
  //   { value: "Electrician", label: "Electrician" },
  // ];
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  
  // const southCities = "New York Washington Alaska Sillicon Valey";
  // const northCities = "New York Washington Alaska Sillicon Valey";
  // const westCities = "New York Washington Alaska Sillicon Valey";
  // const eastCities = "New York Washington Alaska Sillicon Valey";
  const cityNames = returnCityNames();
  

  return (
      <Box
        component="form"
        onSubmit={createUser}
        noValidate
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 1,width:"90%", }}
      >
	  
      {progress===2&&<> <FlexBox sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
          >
            שם פרטי​​
          </Typography>
          <TextField
            margin="normal"
            required
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginLeft: "5px",
              marginRight: "5px",
            }}
            id="text"
            name="name"
            value={allData.name}
            onChange={e=>{
              setAllData({
                ...allData,
                name:e.currentTarget.value
              })
            }}
            autoComplete="name"
			//autoFocus
			
          />
          {validName && (
            <p
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                color: "red",
                fontWeight: "bold",
              }}
            >
			{/*Name needs to be more than 4 characters*/}
            </p>
          )}
          
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
          >
             שם משפחה ​​​
          </Typography>
          <TextField
            margin="normal"
            required
            value={allData.last_name}
            onChange={e=>{
              console.log(e.currentTarget.value)
              console.log(allData)
              setAllData({
                ...allData,
                last_name:e.currentTarget.value
              })
            }}
            id="text"
            sx={{ marginRight: "5px",fontFamily: `${process.env.REACT_APP_FONT}` }}
            name="last_name"
            autoComplete="last_name"
          />
        </FlexBox>
       { <Button 
       variant={(allData.name.length>1&&allData.last_name.length>1)?"contained":"disabled"}
       onClick={e=>{
          setProgress(e=>e+1);
        }}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px', padding: '5px 65px'}}
        >המשך</Button>}
        <ShowDetailSignup text={"אנא וודא שאתה משתמש בשם האמיתי שלך."}/>
       </> }
      {type==="phone"&&progress===3&& <Box>
        <Typography
          variant="p"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontWeight: "bold",
          }}
        >
		הזן את כתובת הדוא"ל שלך:
         </Typography>
        <TextField
          margin="normal"
          required
          value={allData.email}
          onChange={e=>setAllData({...allData,email:e.currentTarget.value})}
          fullWidth
          id="text"
          style={inputStyle}
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          name="email"
          autoComplete="email"
          //autoFocus
        />
        {emailError && (
          <p
            style={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              color: "red",
              fontWeight: "bold",
            }}
          >
             דוא"ל לא תקין או שכבר בשימוש
          </p>
        )}
        <Button 
        variant={allData.email.length>3?"contained":"disabled"}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px', padding: '5px 65px' }}
        onClick={checkEmail}>המשך</Button>
         <ShowDetailSignup text={'נא הזן כתובת דוא"ל פעילה. עם הכתובת הזו אתה תתחבר לאתר ואליה גם נשלח לך התראות על המכרזים.'}/>

       </Box>}
   { progress===4&& <>   <Typography
          variant="p"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontWeight: "bold",
          }}
        >
          בחר שם משתמש:​​​​
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          value={allData.username}
          onChange={e=>{
            setAllData({
              ...allData,
              username:e.currentTarget.value
            })
          }}
          id="text"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          name="username"
          autoComplete="username"
          //autoFocus
        />
        {validUserName && (
          <p
            style={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              color: "red",
              fontWeight: "bold",
            }}
          >
            שם המשתמש צריך להיות ייחודי או יותר מ-3 תווים
          </p>
        )}
        { <Button 
        variant={allData.username.length>3?"contained":"disabled"}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px', padding: '5px 65px' }}
        onClick={checkUsername}>המשך</Button>}
		 <ShowDetailSignup text={'בחר לעצמך שם משתמש. השם חייב להכיל לפחות 4 תווים (אותיות או מספרים).'}/>
        </>
   }
    {progress===5&& <>   <Typography
          variant="p"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontWeight: "bold",
          }}
        >
          בחר סיסמא:​​​​​<br />
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          value={allData.password}
          onChange={e=>{
            setAllData({
              ...allData,
              password:e.currentTarget.value
            })
          }}
          type="password"
          id="password"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          autoComplete="current-password"
        />
        {validPass && which === "no" && (
          <p
            style={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              color: "red",
              fontWeight: "bold",
            }}
          >
            הסיסמא צריכה להכיל לפחות 6 תווים
          </p>
        )}
        {<Button 
        variant={allData.password.length>5?"contained":"disabled"}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px',padding: '5px 65px' }}
        onClick={e=>{
          if(prof)
          setProgress(e=>e+1);
         else
         setProgress(12)
        
        }}>המשך</Button>}
		<ShowDetailSignup text={'חשוב לזכור את הסיסמא כדי להכנס מחדש לאתר. הסיסמא צריכה להכיל לפחות 6 תווים. אנחנו ממליצים לבחור סיסמא באנגלית.'}/>
        </>}
       {type!=="phone"&&progress===3&& <Box>
        <Typography
          variant="p"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontWeight: "bold",
          }}
        >
          הזן מספר טלפון​​​​​​​
        </Typography>
        <TextField
          margin="normal"
          required
          value={allData.phone}
          onChange={e=>setAllData({...allData,phone:e.currentTarget.value})}
          fullWidth
          id="text"
          inputmode="numeric"
          type="number"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          name="phone"
          autoComplete="phone"
        />
        {phoneError && (
          <p
            style={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              color: "red",
              fontWeight: "bold",
            }}
          >
            מספר הטלפון הזה כבר תפוס
          </p>
        )}
        <Button 
        variant={allData.phone.length>0?"contained":"disabled"}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px',padding: '5px 65px' }}
        onClick={checkPhone}>המשך</Button>
        <ShowDetailSignup text={'נא הזן את מספר הטלפון שבשימושך.'}/>

        </Box>}
  { prof&&<>
   <Typography
      variant="h6"
        sx={{
    fontFamily: `${process.env.REACT_APP_FONT}`,
    fontWeight: 'bold', 
    textDecoration: 'underline',
    }}
    >
	{/*לבעלי מקצוע בלבד*/}
    </Typography>
 {progress===9&&<>   
 <Typography
      variant="p"
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        
      }}
    >
       כמה מילים על עצמך:​​​​​​​​
    </Typography>
    <TextField
      margin="normal"
      sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
      fullWidth
      value={allData.bio}
      onChange={e=>{
        setAllData({
          ...allData,
          bio:e.currentTarget.value
        })
      }}
      name="bio"
      type="text"
      id="bio"
    />
    {validBio && (
      <p
        style={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          color: "red",
          fontWeight: "bold",
        }}
      >
        הביוגרפיה צריכה להיות יותר מ-4 תווים
      </p>
    )}
    
    <Button 
        variant={allData.bio.length>5?"contained":"disabled"}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px',padding: '5px 65px' }}
        onClick={e=>{
          setProgress(12);
        }}>המשך</Button>
   
   <ShowDetailSignup text={"כתוב כמה מילים על עצמך, כאלו שיתארו אותך הכי טוב ללקוחות."}/>

    </>
    
    
    }
  {progress===8&&
    <>
  <TextField
      margin="normal"
      defaultValue={1}
      fullWidth
      value={allData.experience}
      onChange={e=>setAllData({...allData,experience:e.currentTarget.value})}
      sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
      name="experience"
      label="שנות ניסיון​"
      type="number"
      InputProps={{ inputProps: { min: 0, max: 50 } }}
      id="expereince"

    />
    <Button 
        variant={"contained"}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px',padding: '5px 65px' }}
        onClick={e=>{
          setProgress(e=>e+1);
        }}>המשך</Button>
       <ShowDetailSignup text={"כתוב כמה שנות ניסיון יש לך במקצוע."}/>
    </>
      }
 
{ progress===7&&
<>
 <Typography variant="h8" sx={{ fontFamily: 'Assistant' }}>
אזור/י שירות
</Typography>
<div style={{ minWidth: '325px',maxWidth:"325px", margin: 'auto' }}>

    <Select1
    fullWidth
    onClick={e=>{
      e.preventDefault()
    }}
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        overflow:"hidden",
        marginBottom: "10px",
      }}
      labelId="demo-multiple-checkbox-label"
      id="demo-multiple-checkbox"
      multiple
      displayEmpty
      value={personName}
      onChange={handleChange}
      input={<OutlinedInput label="Tag" />}
      renderValue={(selected) => selected.join(`\n `)}
      MenuProps={MenuProps}
    >
      {[
        "צפון",
        "קרית שמונה והסביבה",
        "חיפה והסביבה",
        "ירושלים והסביבה",
        "נתניה והסביבה",
        "השרון והסביבה",
        "מרכז",
        "שפלה והסביבה",
        "באר שבע והסביבה",
        "דרום",
      ].map((name, i) => (
        <MenuItem
          key={name}
          value={name}
          sx={{overflow:"hidden", fontFamily: "Assistant, sans-serif"}}
          style={getStyles(name, personName, theme)}
        >
          <Checkbox checked={personName.indexOf(name) > -1} />
          <ListItemText primary={name} />
          <Box
            display="block"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`,overflow:"hidden" }}
          >
            {name === "צפון" ? (
              <Box>
                {cityNames.southCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "קרית שמונה והסביבה" ? (
              <Box>
                {cityNames.northCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "חיפה והסביבה" ? (
              <Box>
                {cityNames.westCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "ירושלים והסביבה" ? (
              <Box>
                {cityNames.eastCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "נתניה והסביבה" ? (
              <Box>
                {cityNames.eCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "השרון והסביבה" ? (
              <Box>
                {cityNames.fCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "מרכז" ? (
              <Box>
                {cityNames.gCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "שפלה והסביבה" ? (
              <Box>
                {cityNames.hCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "באר שבע והסביבה" ? (
              <Box>
                {cityNames.iCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
            ) : name === "דרום" ? (
              <Box>
                {cityNames.jCities.map((e) => {
                  return (
                    <Typography
                      variant="p"
                      display="block"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                        margin: "0px 3px",
                      }}
                    >
                      {e}
                    </Typography>
                  );
                })}
              </Box>
    ) : (
              ""
    )}
          </Box>
        </MenuItem>
      ))}
    </Select1>
</div>
    {validArea && (
      <p style={{ color: "red", fontWeight: "bold" }}>
        אתה צריך לבחור לפחות אזור אחד
      </p>
    )}

<Button 
        variant={allData.personName?"contained":"disabled"}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px',padding: '5px 65px' }}
        onClick={e=>{
          setProgress(e=>e+1);
        }}>המשך</Button>
   <ShowDetailSignup text={'בחר את אזורי השירות שבהם אתה נותן שירות. תוכל לבחור יותר מאזור שירות אחד. כדי לסגור את תפריט הגלילה שייפתח, הקש על "הפרופיל שלי". '}/>
 </> }
{progress===6&&<><Typography variant="h8" sx={{ fontFamily: 'Assistant', margin: '100px' }}>
מקצוע
</Typography>
    <Select
      components={animatedComponents}
      isMulti
      name="professions"
      options={options}
      onChange={(item) => {
        if(item.length>0)
        {
          setAllData({
            ...allData,
            opti:true
          })
        }
        else
        {
          setAllData({
            ...allData,
            opti:false
          })
        }
        setSelectedOptions(item);

      }}
      className="select"
      isClearable={true}
      isSearchable={true}
      isDisabled={false}
      width="325px"
      isLoading={false}
      closeMenuOnSelect={false}
  menuPortalTarget={document.body} // Set the menuPortalTarget
  MenuProps={MenuProps}
  styles={{control: base => ({
    ...base,
    minWidth:"325px",
    maxWidth:"325px",
    fontFamily: `${process.env.REACT_APP_FONT}`, // specify the width here
  }),}}
    />
    <Button 
        variant={allData.opti?"contained":"disabled"}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px',padding: '5px 65px' }}
        onClick={e=>{
          setProgress(e=>e+1);
        }}>המשך</Button>
      <ShowDetailSignup text={"בחר את תחום המקצוע שלך. תוכל לבחור יותר מתחום מקצוע אחד."}/>
    </>}
  </>}
  {progress===12&&<>
     <Checkbox
          checked={emailCheck}
          onChange={(e) => setEmailCheck(e.target.checked)}
        ></Checkbox>
        <span style={{ fontFamily: 'Assistant, sans-serif' }}>דוא"ל​</span>
        <Checkbox
          checked={smsCheck}
          onChange={(e) => setSmsCheck(e.target.checked)}
        ></Checkbox>
        <span style={{ fontFamily: 'Assistant, sans-serif' }}>SMS/וואטסאפ</span>
        <Typography
          variant="p"
            sx={{
				fontFamily: `${process.env.REACT_APP_FONT}`,
				marginBottom: '15px', 
				}}
          display="block"
        >
          אני מסכים לקבל התראות​<br />
		  <span style={{ color: 'red', fontFamily:'Assistant' }}>חובה לסמן את שניהם כדי להרשם</span>
		  
        </Typography>
        <Button
          type="submit"
          fullWidth
          variant={emailCheck && smsCheck ? "contained" : "disabled"}
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px', padding: '10px' }}
        >
          לחץ כאן לסיום ההרשמה!​<br />
        </Button>
        
        <ShowDetailSignup text={'כדי לסיים את ההרשמה יש לאשר את קבלת ההתראות ב-SMS/וואטסאפ ובדוא"ל.'}/>

        </>}
        {login && <Navigate to="/congo" />}
      </Box>
  );

}

export default Next;
