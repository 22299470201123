import { NotificationAdd } from "@mui/icons-material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge, Box, styled, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import Notifications from "./Notifications";

export default function PositionedMenu({
  notifications,
  setBadgeContent,
  badgeContent,
  setNotifications,
}) {
  const [noti, setNoti] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    console.log("Abid");
    setAnchorEl(event.currentTarget);

    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/setnoti`,
        {
          totaNoti: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => {
    setBadgeContent(0);
    setAnchorEl(null);
  };
  const removeNotification = (noti) => {
    let notifications1 = notifications;
    notifications1.filter((e) => {
      if (noti.id === e.id) return "";
      return e;
    });
    setNotifications(notifications1);
  };
  const FlexBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontFamily: `${process.env.REACT_APP_FONT}`,

    justifyContent: "space-between",
  });
  return (
    <div
      style={{
        marginRight: "15px",
        marginLeft: "15px",
        padding: "0px",
        width: "24px",
        fontFamily: `${process.env.REACT_APP_FONT}`,
      }}
    >
      <Button
        id="demo-positioned-button"
        aria-controls={open ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          color: "white",
          margin: "0px",
          padding: "0px",
          width: "24px",
        }}
      >
        <Badge
          badgeContent={badgeContent}
          sx={{ cursor: "pointer" }}
          color="error"
        >
          <NotificationAdd sx={{ color: "white" }} />
        </Badge>
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            verticalAlign: "center",
          }}
        >
          <FlexBox sx={{ width: "min-content" }}>
            <NotificationsIcon
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginLeft: "10px",
                marginRight: "10px",
              }}
            />
            <Typography
              variant="h6"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginLeft: "5px",
                display: "inline-block",
              }}
            >
              התראות​
            </Typography>
          </FlexBox>
          <hr
            style={{
              backgroundColor: "blue",
              height: "3px",
              borderRadius: "5px",
            }}
          ></hr>
          {notifications.length > 0 ? (
            notifications
              .reverse()
              .map((notification) => (
                <Notifications
                  handleClose={handleClose}
                  notification={notification}
                  removeNotification={removeNotification}
                />
              ))
          ) : (
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginTop: "10px",
                padding: "10px",
                display: "block",
                marginLeft: "10px",
                marginRight: "10px",
              }}
            >
              אין לך התראות חדשות​
            </Typography>
          )}
        </Box>
      </Menu>
    </div>
  );
}
