import { Box } from "@mui/material";
import React from "react";
import PostBidDescription from "./PostBidDescription";
import UploadPhoto from "./UploadPhoto";

function PostBidUploadImages({ bidData }) {
  const [next, setNext] = React.useState(false);
  const setImages = (e, size) => {
    let files = [];
    console.log(size);
    if (size !== 0) {
      for (let i = 0; i < e[0].length; i++) files.push(e[0][i]);
      console.log(files);
      bidData.bidImages = files;
      bidData.size = size;
      setNext(true);
    } else {
      bidData.bidImages = e;
      bidData.size = size;
      setNext(true);
    }
  };

  return (
    <Box>
      {!next && <UploadPhoto setImages={setImages} />}
      {next && <PostBidDescription bidData={bidData} />}
    </Box>
  );
}

export default PostBidUploadImages;
