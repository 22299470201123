import { Box } from "@mui/material";
import HeroSection from "./HeroSection";
import React, { useEffect } from "react";
import Working from "./Working";
import HowRegister from "./HowRegister";
import Youtube from "./Youtube";
import Support from "./Support";
import AccordionNoDivider from "./FaqQuestions";
import FaqComponent from "./FaqQuestions";
import Faq from "./Faq";
import user from "../../../asset/images/user-profile.svg";
import best from "../../../asset/images/best.svg";
//import email from "../../../asset/images/email.svg";
import whatsapp from "../../../asset/images/whatsapp-1.svg";
import Convinient from "./Convinient";
import Footer from "../../Footer";

export default function LandingPageProf(){
    

    const landingPageuser={
        heroHeader:"Add your Header here",
        heroSecond:"Add your Header here",
		heroThird:"Add your Header here",
        workingFirst:"Add your text here",
        workingSecond:"Add your text here",
        workingThird:"Add your text here",
        howReg:{
            firstTitle:"Add your Header here",
            firstSub:"Add your text here",
            secondTitle:"Add your Header here",
            secondSub:"Add your text here",
            thirdTitle:"Add your Header here",
            thirdSub:"Add your text here",
        },
		    supportList:[
      "Free Website Migration",
      "Free Website Migration",
      "Free Website Migration",
      "Free Website Migration",
      "Free Website Migration",

	]
 }
    const landingPageProf={
        heroHeader:"בעל מקצוע? נותן שירות? מעוניין להרוויח יותר?",
        heroSecond:"באתר שלנו לקוחות מפרסמים מכרזים לעבודות שהם צריכים מבעלי מקצוע כמוך!",
		heroThird:"אתה תתן להם הצעה. המכרז הסתיים ואתה זכית? תקבל את מספר הטלפון של הלקוח, והוא כבר יחכה לך שתגיע אליו לתחילת העבודה!",
        workingFirst:"קבלת התראות בוואטסאפ ובמייל",
        workingSecond:"נותנים הצעה מתי שרוצים (אפילו בשתיים בלילה...)",
        workingThird:"מרוויחים יותר כסף ויותר לקוחות!",
        howReg:{
            firstTitle:"הגדר פרופיל",
            firstSub:"צור פרופיל באתר שלנו. בפרופיל תגדיר את העיסוק ואזורי השירות שלך. תוכל גם לכתוב כמה מילים על עצמך.",
            secondTitle:"קבל התראות",
            secondSub:"ברגע שלקוח יפרסם מכרז עם בקשה לעבודה שמתאימה לעיסוק ולאזור השירות שלך, אתה תקבל מיד הודעת וואטסאפ ומייל, תוכל להכנס לאתר ולתת הצעת מחיר לעבודה הזו.",
            thirdTitle:"תתחיל לעבוד!",
            thirdSub:"המכרז הסתיים ואתה זכית? הלקוח יאשר את ההצעה שנתת, אתה תקבל את מספר הטלפון שלו ולך רק נשאר להתקשר אליו ולקבוע מועד לתחילת העבודה.",
        },
		
		   supportList:[
      "ממשק ידידותי, פשוט ונוח.",
      "מקבלים התראות על עבודות רלוונטיות, גם אם אתם על הספה...",
      "רק אתה בוחר את העבודה שאתה מעוניין בה.",
      "השליטה בידיים שלך - אתה רואה את כל הצעות המחיר האחרות!",
      "מרוויחים יותר!",

	]
}

    const faqUserQuestions = [
        {
          question: 'כמה השירות הזה עולה?',
          answer: 'This is the answer to the first question. It can be a long answer that will be displayed within the elevated section.',
        },
        {
          question: 'How does the second question work?',
          answer: 'This is the answer to the second question. It can also be a lengthy answer that fits within the elevated section.',
        },
        {
            question: 'How does the second question work?',
            answer: 'This is the answer to the second question. It can also be a lengthy answer that fits within the elevated section.',
          },
          {
            question: 'How does the second question work?',
            answer: 'This is the answer to the second question. It can also be a lengthy answer that fits within the elevated section.',
          },
      ];
      const faqProfQuestions = [
        {
          question: 'מה זה בידאיט?',
          answer: 'בידאיט היא פלטפורמה למציאת בעלי מקצוע בצורת מכרז - באופן פשוט, נוח והוגן. כל אחד יכול לפתוח מכרז, מוגבל בזמן, על עבודה שהוא מעוניין שיבצעו אותה עבורו. כל בעל מקצוע יכול להצטרף ולתת הצעת מחיר ולזכות במכרז. ',
        },
        {
          question: 'כמה השירות הזה עולה?',
          answer: 'השירות לא עולה כסף. הוא בחינם לבעל המקצוע וללקוח.',
        },
        {
            question: 'איפה רואים את המכרזים?',
            answer: 'ברגע שלקוח מפרסם מכרז באתר שלנו, המכרז מופיע בדף הראשי (דף הבית) שלנו. ',
          },
          {
            question: 'איך נותנים הצעת מחיר למכרז?',
            answer: 'מרגע שמכרז עולה לאתר, בעל המקצוע יכול לתת עליו הצעת מחיר. כנס למכרז, לחץ על "תן הצעה". אין מגבלה לגובה ההצעה שתוכל לתת. אין מגבלה על כמות ההצעות שתוכל לתת. עם זאת, במידה וכבר קיימת הצעה, תוכל להציע מחיר שקטן ב-10 ש"ח לפחות מהמחיר המוצע. לדוגמא: אם קיימת הצעה על סכום של 500 ש"ח, תוכל להציע הצעה חדשה מסכום של 490 ש"ח ומטה. לא תוכל להציע הצעה על סכום של 495 ש"ח.',
          },
          {
		    question: 'איך רואים את תוצאות המכרז?',
            answer: 'כשמכרז מסתיים, הלקוח ובעל המקצוע הזוכה יקבלו הודעה על כך במייל. כאשר תכנסו למכרז, תראו שנוצר לו כפתור "תוצאות". לחיצה עליו תראה את "סרגל ההתקדמות" של העסקה. בשלב ראשון הלקוח צריך לאשר את ההצעה, ורק לאחר מכן יוכל בעל המקצוע לאשר מצדו את ההצעה. כשבעל המקצוע יאשר, ייחשף בפניו מספר הטלפון של הלקוח כדי שהוא יוכל ליצור איתו קשר.',
          },
		  {
		    question: 'יש לי עוד שאלות...',
            answer: 'תוכל לעיין בסעיף "שאלות נפוצות" באתר שלנו, בתחתית המסך. יש שם תשובות להמון שאלות שיכול להיות שמעניינות אותך. לא קיבלת תשובה? תוכל ליצור איתנו קשר, גם בתחתית המסך תחת "דיגיטל ויצירת קשר".',
          },
		  
      ];

      const conviDataUser=[
        {
            text:"Add your text here",
            image:user,
        },
        {
            text:"Add your text here",
            image:whatsapp,
        },
        {
            text:"Add your text here",
            image:best,
        }
      ]
      const conviDataProf=[
        {
            text:"יוצרים פרופיל",
            image:user,
        },
        {
            text:"מקבלים התראות על מכרזים בוואטסאפ ובמייל",
            image:whatsapp,
        },
        {
            text:"נכנסים למכרזים באתר, נותנים הצעות מחיר וזוכים",
            image:best,
        }
      ]
      let prof={current:true}
    return (<Box>
  <Box sx={{margin:"20px auto",width:{xs:"90%",md:"75%"}}}>
        <HeroSection prof={prof.current} text={prof.current?landingPageProf:landingPageuser}/>
        <Working text={prof.current?landingPageProf:landingPageuser}/>
        <HowRegister text={prof.current?landingPageProf:landingPageuser} prof={prof.current}/>
        <Youtube prof={prof.current}/>
        <Support text={prof.current?landingPageProf:landingPageuser} prof={prof.current}/>
        <Faq faq={prof.current?faqProfQuestions:faqUserQuestions} prof={prof.current}/>
        <Convinient conviData={prof.current?conviDataProf:conviDataUser} prof={prof.current}/>
    
    </Box>
        <Footer/>
        </Box>)
}