import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useState } from "react";
import { Navigate } from "react-router-dom";
import { FlexBox } from "./FlexBox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: 250,
    md: 400,
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  textAlign: "center",
  p: 4,
  fontFamily: `${process.env.REACT_APP_FONT}`,
};
export default function DeleteModal({
  open,
  id,
  user,
  admin,
  setOpen2,
  handleOpen,
  handleClose,
  result,
  changeStep,
  mes,
}) {
  const [delete1, setDelete] = useState(false);

  const deleteAccount = () => {
    handleClose();
    if (admin === "yes") {
      axios
        .delete(
          `${process.env.React_APP_BACKEND_URL}/api/admin/userdelete?id=${user.id}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("admintoken")}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          window.location.reload();
        })
        .catch((err) => console.log(err));
      return;
    }
    axios
      .delete(`${process.env.React_APP_BACKEND_URL}/api/userdelete`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        Cookies.remove("favids");
        Cookies.remove("token");
        Cookies.remove("name");
        Cookies.remove("last_name");
        Cookies.remove("id");
        Cookies.remove("username");
        Cookies.remove("email");
        Cookies.remove("phone");
        setDelete(true);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={(e) => handleClose("delete")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {delete1 && <Navigate to={`/signin`}></Navigate>}
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "10px",
            }}
            variant="h6"
            component="h2"
          >
            למחוק חשבון?
          </Typography>
          <Typography
            id="modal-modal-title"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "10px",
            }}
            variant="h6"
            component="h2"
          >
            פעולה זו אינה ניתנת לשחזור. כל המידע שלך יימחק לצמיתות. ​
          </Typography>
          <FlexBox>
            <Button
              variant="contained"
              onClick={deleteAccount}
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginRight: "10px",
                padding: "5px 30px",
              }}
            >
              מחק חשבון​
            </Button>
            <Button
              variant="contained"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginRight: "10px",
                padding: "5px 30px",
              }}
              onClick={(e) => handleClose("delete")}
            >
              לא​
            </Button>
          </FlexBox>
        </Box>
      </Modal>
    </div>
  );
}
