import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import * as React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../asset/images/signin_new.JPG";
import ValidateEmail from "../asset/js/ValidateEmail";
import SimpleBackdrop from "./BackDrop";
import { Copyright } from "./Copyright";
import Next from "./Next";
import OTPInput from "./OTP";
import { useEffect } from "react";
import Footer from "./Footer";
import { SetMeal } from "@mui/icons-material";
import ProgressBar from "./SignUpCompo/Progressbar";
import ShowDetailSignup from "./SignUpCompo/ShowDetailsSignup";
const theme = createTheme({
  typography: {
    fontFamily: [
      "Assistant",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

export default function SignUpWithPhone() {
  const [direct, setDirect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [progress, setProgress] = useState(1);

  const handleToggle = () => {
    setOpen(!open);
  };
  let prof=React.useRef(false);
  useEffect(() => {
    // Accessing the current URL
    const currentUrl = window.location.href;
    console.log('Current URL:', currentUrl);
    if(currentUrl.includes("prof"))
    prof.current=true;

    // You can perform further actions with the URL if needed
  }, []);
  const [next, setNext] = React.useState(false);
  const [otp, setotp] = useState("");
  const [token, setToken] = useState("no");
  const [which, setWhich] = useState("no");
  let email = React.useRef();

  const [validEmail, setValidEmail] = React.useState(false);
  useEffect(()=>{
   
    const digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    setotp(OTP);
 
},[])
  const handleSubmit = () => {
    setValidEmail(false);
    console.log(email.current);
 
    axios
    .get(
      `${process.env.React_APP_BACKEND_URL}/api/checkphone2?phone=${email.current}`
    ).then(res=>{
      axios.post(`https://api.sms4free.co.il/ApiSMS/v2/SendSMS`,{
        "key":"8aTTPFD18",
        "user":"0553181000",
        "pass":"36414431",
        "sender":"Bidit",
        "recipient":email.current,
        "msg":"קוד האימות שלך הוא "+otp
    
    }).then(res=>{
        setOpen(false);
      setNext(true);
      setDirect(true);
    }).catch(err=>console.log(err))
    }).catch(err=>{
      setValidEmail(true);
    })
    
    
  };

  const handleEmail = (e) => {
    const change = e.target.value;
    email.current = change;
  };
  

  return (
    <ThemeProvider theme={theme}>
    <ProgressBar progress={progress} prof={prof.current}/>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              fontWeight: "bold",
            }}
            variant="h5"
						
          >
            הפרופיל שלי​
          </Typography>
		  	<p
				style={{
				fontFamily: `${process.env.REACT_APP_FONT}`,
				fontWeight: "bold",
				}}
			>
			{/*בואו נתחיל...*/}
			</p>
		  
          {!next && !direct && (
            <Box
              noValidate
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 1 }}
            >
              <Typography
                variant="p"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  fontWeight: "bold",
                }}
              >
               הזן את מספר הטלפון שלך:​
              </Typography>

              <TextField
                margin="normal"
                required
                fullWidth
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                onChange={(e) => handleEmail(e)}
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                type="number"
              />
              {validEmail && (
                <p
                  style={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
              המספר הסלולרי כבר תפוס​
                </p>
              )}
              <Button
                fullWidth
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  mt: 3,
                  mb: 2,
				  backgroundColor: 'black',
				  color: 'white',
                }}
              >
                שלח לי את הקוד לסלולר (המתן מספר שניות)
              </Button>
              <ShowDetailSignup text={"אנחנו נשלח לך קוד למספר שתזין כאן, אז מומלץ שזה יהיה מספר בשימושך..."}/>

            </Box>
          )}
         {open && (
            <SimpleBackdrop
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              handleToogle={handleToggle}
            />
          )}
          {next && which === "no" && (
            <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
              <OTPInput email={email.current} sentOtp={otp} type={"phone"}progress={progress}setProgress={setProgress}prof={prof.current}/>
            </Box>
          )}
          {direct && which !== "no" && (
            <Next email={email.current} token={token} which={which} type={"phone"}/>
          )}
          <NavLink to={prof.current?"/signupasprofemail":"/signup"}><p style={{textAlign:"end", fontFamily:'Assistant', fontSize: '18px', color: 'black'}}>אני מעוניין לקבל קוד לכתובת הדוא"ל
		  </p>
		  </NavLink> 
			
		<br />	
          <Grid container sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
            <Grid item sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
              <NavLink to="/signin" style={{ fontFamily: 'Assistant, sans-serif' }}>כבר יש לך חשבון? התחבר​</NavLink>
            </Grid>
          </Grid>
        </Box>

		{/*(<Copyright sx={{ mt: 8, mb: 4 }} />*/}
      </Container>
	  <Footer />
    </ThemeProvider>
  );
}
