import { Box } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  "pk_test_51J9GpJCEzjJSwkXGjBJcAAHVjZOTgcu10vr1WHEdrx9DEeZEJEkGvNb8KuoCwUb70zIIoaAm9ZV3xAt5FKu7oxnU00omM67EY9"
);

export default function StripeCheckout() {
  const [clientSecret, setClientSecret] = useState({});
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/selectplan?id=${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((result) => {
        console.log(result);
        let clientSecret = result.data.Intent;
        setClientSecret(clientSecret.client_secret);
      });
  }, [id]);

  return (
    <Box>
      {Object.keys(clientSecret).length > 0 && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm intent={clientSecret} id={id} />
        </Elements>
      )}
    </Box>
  );
}
