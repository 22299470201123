import React from "react";
import { Box, Button, Typography } from "@mui/material";
import congo from "../../../asset/images/firework.png"; // Replace with your image component or use an MUI Image component
import { NavLink } from "react-router-dom";

const RegistrationComplete = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh", // Full height of the viewport
      }}
    >
        <Box sx={{height:{xs:"300px",height:"250px"}}}>
      <img src={congo} style={{width:"90%",height:"95%"}} /> {/* Replace with your Congratulations Image component */}
      </Box>
      <Typography variant="h4" sx={{ mt: 2, textAlign: "center", fontFamily:"Assistant" }}>
       תודה שהצטרפת אלינו!
      </Typography>

      <Typography variant="body1" sx={{ mt: 2, textAlign: "center", fontFamily:"Assistant" }}>
        כעת תוכל להכנס לאתר - אם אתה לקוח, תוכל לפרסם בקשות למכרזי עבודה. אם אתה בעל מקצוע, תוכל גם לתת הצעות מחיר.    
      </Typography>
    <NavLink to="/signin">
      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 3, fontFamily:"Assistant" }}
        onClick={() => {
          // Handle button click, e.g., redirect the user
          console.log("Redirecting user...");
        }}
      >
        הכנס לאתר
      </Button>
      </NavLink>
    </Box>
  );
};

export default RegistrationComplete;
