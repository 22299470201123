import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";

import React, { useState } from "react";

function ChangeBioModal({ open, handleClose, user, admin }) {
  const [value, setValue] = React.useState("");
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: 280,
      md: 400,
    },
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    textAlign: "center",
    p: 4,
    fontFamily: "Assistant",
  };

  const [invalid, setInvalid] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Hello");
    setInvalid(false);
    const data = new FormData(e.currentTarget);
    if (data.get("bio") < 3) setInvalid(true);
    else {
      console.log(admin);
      if (admin === "yes") {
        axios
          .put(
            `${process.env.React_APP_BACKEND_URL}/api/admin/changebio`,
            {
              bio: data.get("bio"),
              id: user.id,
            },
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("admintoken")}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            setInvalid(false);

            handleClose("bio");
          })
          .catch((err) => console.log(err));
        return;
      }
      axios
        .put(
          `${process.env.React_APP_BACKEND_URL}/api/changebio`,
          {
            bio: data.get("bio"),
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .then((res) => {
          console.log(res);
          setInvalid(false);
          Cookies.set("bio", data.get("bio"), { expires: 365 });

          handleClose("bio");
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <Modal
      open={open}
      onClose={(e) => handleClose("bio")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontFamily: "Assistant",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          שנה תיאור​
        </Typography>
        <Box component="form" onSubmit={handleSubmit}>
          <Typography
            variant="p"
            sx={{ fontFamily: "Assistant", fontWeight: "bold" }}
          >
            הזן תיאור ​​​​​​​
          </Typography>
          <TextField
            width="100%"
            margin="normal"
            required
            id="text"
            name="bio"
            sx={{ fontFamily: "Assistant" }}
            autoComplete="bio"
          />
          {invalid && (
            <p
              style={{
                fontFamily: "Assistant",
                color: "red",
                fontWeight: "bold",
              }}
            >
              הביוגרפיה צריכה להיות יותר מ-2 תווים
            </p>
          )}
          <Button
            variant="contained"
            type="submit"
            sx={{
              fontFamily: "Assistant",
              marginTop: "20px",
              marginLeft: "10px",
              marginRight: "20px",
            }}
          >
            עדכן​
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ChangeBioModal;
