import {
  Box,
  Checkbox,
  ListItemText,
  OutlinedInput,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";
import returnCityNames from "../asset/js/CityNames";
import { professions } from "../asset/js/Professions";

export default function DropDown({ setFilter, filter }) {
  const [personName, setPersonName] = React.useState(["הכל​"]);
  const cityNames = returnCityNames();
  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setFilter({ area: event.target.value, profession: filter.profession });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "area") {
      setFilter({ area: event.target.value, profession: filter.profession });
    } else
      setFilter({
        profession: event.target.value,
        area: filter.area,
      });
  };
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Assistant",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  return (
    <div>
      <ThemeProvider theme={theme}>
        <FormControl
          required
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            m: 1,
            minWidth: 120,
            padding: "0px",
          }}
        >
          <InputLabel
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, color: "black" }}
            id="demo-simple-select-readonly-label"
          >
            אזור
          </InputLabel>
          <Select
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              width: "100%",
              marginBottom: "10px",
            }}
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            placeholder="Enter Area"
            value={personName}
            onChange={handleChange1}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            {[
              "הכל​",
              "צפון",
              "קרית שמונה והסביבה",
              "חיפה והסביבה",
              "ירושלים והסביבה",
              "נתניה והסביבה",
              "השרון והסביבה",
              "מרכז",
              "שפלה והסביבה",
              "באר שבע והסביבה",
              "דרום",
            ].map((name, i) => (
              <MenuItem
                key={name}
                value={name}
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  flexDirection: "column",
                  fontSize: { xs: "80%", md: "100%" },
                }}
              >
                <Checkbox checked={personName.indexOf(name) > -1} />
                <ListItemText primary={name} />
                <Box
                  display="block"
                  sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                >
                  {name === "צפון" ? (
                    <Box>
                      {cityNames.southCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "קרית שמונה והסביבה" ? (
                    <Box>
                      {cityNames.northCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "חיפה והסביבה" ? (
                    <Box>
                      {cityNames.westCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "ירושלים והסביבה" ? (
                    <Box>
                      {cityNames.eastCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "נתניה והסביבה" ? (
                    <Box>
                      {cityNames.eCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "השרון והסביבה" ? (
                    <Box>
                      {cityNames.fCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "מרכז" ? (
                    <Box>
                      {cityNames.gCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "שפלה והסביבה" ? (
                    <Box>
                      {cityNames.hCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "באר שבע והסביבה" ? (
                    <Box>
                      {cityNames.iCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : name === "דרום" ? (
                    <Box>
                      {cityNames.jCities.map((e) => {
                        return (
                          <Typography
                            variant="p"
                            display="block"
                            sx={{
                              fontFamily: `${process.env.REACT_APP_FONT}`,
                              color: "grey",
                              margin: "0px 3px",
                            }}
                          >
                            {e}
                          </Typography>
                        );
                      })}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          required
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            m: 1,
            minWidth: 125,
          }}
        >
          <InputLabel
            sx={{ color: "black" }}
            id="demo-simple-select-required-label"
          >
            מקצוע​
          </InputLabel>
          <Select
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={filter.profession}
            label="מקצוע​ *"
            name="profession"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            onChange={handleChange}
          >
            <MenuItem
              value="הכל​"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            >
              הכל​
            </MenuItem>
            {professions.map((e) => (
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={e}
              >
                {e}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </div>
  );
}
