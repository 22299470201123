import { Box, Stack } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import NotAuthorized from "../NotAuthorized";
import PlanForm from "../Planform";
import AdminMenu from "./AdminMenu";
import ChangePrivacy from "./ChangePrivacy";
import CreateArticles from "./CreateArticles";
import CreateNews from "./CreateNews";
import ShowData from "./ShowData";
import ChangeShow from "./ChangeShow";
import SendNoti from "./SendNoti";

function AdminHome() {
  const [data, setData] = useState([]);
  const [what, setWhat] = useState("unapproved");
  const [show, setShow] = useState(false);
  useEffect(() => {
    let use = "";
    setShow(false);
    if (what === "approved") use = "allbids";
    else if (what === "unapproved") use = "allunbids";
    else if (what === "users") use = "allusers";
    else if (what === "subs") use = "allsubs";
    else if (what === "admins") use = "alladmins";
    else if (what === "history") use = "allhistory";
    else if (what === "deals") use = "alldeals";
    else if (what === "sendNoti") use = "alldeals";

    if(what.toString() === "changeShow"||what === "sendNoti")
    return ;
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/admin/${use}`, {
        headers: { Authorization: `Bearer ${Cookies.get("admintoken")}` },
      })
      .then((res) => {
        console.log(res);
        setData(res.data.Bids);
        setShow(true);
      })
      .catch((err) => console.log(err));
  }, [what]);
  return (
    <Box sx={{ fontFamily: "Assistant" }}>
      {(Cookies.get("adminId") === null ||
        Cookies.get("adminId") === undefined) && <NotAuthorized />}
      <Stack
        sx={{ fontFamily: "Assistant" }}
        className="App"
        spacing={2}
        direction="row"
        justifyContent="space-evenly"
      >
        <AdminMenu setWhat={setWhat} setShow={setShow} />
        {show && !what.toString().includes("plans") && (
          <ShowData data={data} what={what} sx={{ marginRight: "10px" }} />
        )}
           {what.toString() === "sendNoti" && <SendNoti />}
           {what.toString() === "changeShow" && <ChangeShow />}
        {what.toString() === "createArticles" && <CreateArticles />}
        {what.toString() === "createans" && <PlanForm />}
        {what.toString() === "createNews" && <CreateNews />}
        {what.toString() === "changePrivacy" && (
          <ChangePrivacy change={"privacy"} />
        )}
        {what.toString() === "changeAboutUs" && (
          <ChangePrivacy change={"aboutus"} />
        )}
        {what.toString() === "faq" && <ChangePrivacy change={"faq"} />}
        {what.toString() === "termsofuse" && (
          <ChangePrivacy change={"termsofuse"} />
        )}
        {what.toString() === "support" && <ChangePrivacy change={"support"} />}
        {what.toString() === "changelink" && (
          <ChangePrivacy change={"changelink"} />
        )}
         
      </Stack>
    </Box>
  );
}

export default AdminHome;
