import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemIcon,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChangeBioModal from "../ChangeBioModal";
import ChangeNameModal from "../ChangeNameModal";
import ChangeServiceArea from "../ChangeServiceArea";
import DeleteModal from "../DeleteModal";
import { FlexBox } from "../FlexBox";
import ProfessionModal from "../ProfessionModal";
function EditProfile2() {
  const { id } = useParams();
  const [changed, setChanged] = useState(0);
  const [handle, setSendNotifications] = useState([]);
  const [user, setUser] = useState();
  const [showUser, setShowUser] = useState(false);
  const [areaModal, setAreaModal] = useState(false);
  const [showChange, setShowChange] = useState(false);
  const [handleChangeUser, sethandleChangeUser] = useState({});
  const [showUpdate, setShowUpdate] = useState(false);
  const [changedUser, setChangedUser] = useState(user);
  const [openModal, setModal] = React.useState(false);
  const [delete1, setDelete] = useState(false);
  const [state, setState] = React.useState({});
  const addChangedUser = (e, value) => {
    setShowUpdate(true);
  };
  const [changePhone, setChangePhone] = useState(false);
  const [changeThings, setChangeThings] = useState(false);

  const handleChange = (event) => {
    let name = event.target.name;
    setShowChange(true);
    if (name === "agree") {
      if (state.check) {
        setState({
          sms: false,
          email: false,
          check: false,
        });
        handleChangeUser({
          sendEmail: "no",
          sendPhone: "no",
        });
      } else {
        setState({
          sms: true,
          email: true,
          check: true,
        });
        handleChangeUser({
          sendEmail: "yes",
          sendPhone: "yes",
        });
      }
    } else if (name === "sms") {
      if (!state.email && !event.target.checked) {
        setState({
          ...state,
          [event.target.name]: event.target.checked,
          check: false,
        });
        sethandleChangeUser({
          sendEmail: "no",
          sendPhone: "no",
        });
      } else {
        setState({
          ...state,
          check: true,
          [event.target.name]: event.target.checked,
        });
        sethandleChangeUser({
          sendEmail: state.email ? "yes" : "no",
          sendPhone: event.target.checked ? "yes" : "no",
        });
      }
    } else {
      if (name === "email") {
        if (!state.sms && !event.target.checked) {
          setState({
            ...state,
            [event.target.name]: event.target.checked,
            check: false,
          });
          sethandleChangeUser({
            sendEmail: "no",
            sendPhone: "no",
          });
        } else {
          setState({
            ...state,
            check: true,
            [event.target.name]: event.target.checked,
          });
          sethandleChangeUser({
            sendEmail: event.target.checked ? "yes" : "no",
            sendPhone: state.sms ? "yes" : "no",
          });
        }
      }
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    fontFamily: `${process.env.REACT_APP_FONT}`,
    p: 4,
  };
  const ProfessionBox = styled(Box)({
    backgroundColor: "#E8F4FF",
    padding: "10px",
    display: "inline-block",
    margin: "10px",
    marginTop: "0px",
    boxShadow: 2,
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const StyledBox = styled(Box)({
    marginTop: "10px",
    float: "none",
    marginBottom: "10px",
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const StyledTypography = styled(Typography)({
    marginBottom: "5px",

    display: "block",
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const [changeBio, setChangeBio] = useState(false);
  const [openProfessionModal, setProfessionModal] = useState(false);
  const handleOpen = () => setModal(true);
  const [changeName, setChangeName] = useState(false);
  const handleClose = (e) => {
    updatePage();
    if (e === "password") setModal(false);
    else if (e === "area") setAreaModal(false);
    else if (e === "delete") setDelete(false);
    else if (e === "name") setChangeName(false);
    else if (e === "bio") setChangeBio(false);
    else setProfessionModal(false);
  };
  const handleUpdate = () => {
    console.log(handleChangeUser);
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/admin/updateuser?id=${id}`,
        {
          sendEmail: handleChangeUser.sendEmail,
          sendPhone: handleChangeUser.sendPhone,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("admintoken")}`,
          },
        }
      )
      .then((res) => {
        setShowChange(false);
        setShowUpdate(true);
        setChanged((e) => e++);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updatePage = () => {
    setChanged((e) => (e = e + 1));
  };
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/admin/profile?id=${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get("admintoken")}` },
      })
      .then((res) => {
        console.log(res);
        setSendNotifications(res.data.sendNotifications[0]);
        setState({
          sms: res.data.sendNotifications[0].sendPhone === "yes" ? true : false,
          email:
            res.data.sendNotifications[0].sendEmail === "yes" ? true : false,
          check:
            res.data.sendNotifications[0].sendPhone === "yes" ||
            res.data.sendNotifications[0].sendEmail === "yes"
              ? true
              : false,
        });
        let user = res.data.user;
        user.rating = res.data.rating;
        setUser(res.data.user);
        setShowUser(true);
      })
      .catch((err) => console.log(err));
  }, [id]);
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/admin/profile?id=${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get("admintoken")}` },
      })
      .then((res) => {
        console.log(res);
        setSendNotifications(res.data.sendNotifications[0]);
        setState({
          sms: res.data.sendNotifications[0].sendPhone === "yes" ? true : false,
          email:
            res.data.sendNotifications[0].sendEmail === "yes" ? true : false,
          check:
            res.data.sendNotifications[0].sendPhone === "yes" ||
            res.data.sendNotifications[0].sendEmail === "yes"
              ? true
              : false,
        });
        let user = res.data.user;
        user.rating = res.data.rating;
        setUser(res.data.user);
        setShowUser(true);
      })
      .catch((err) => console.log(err));
  }, [changed, id]);

  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: { md: "70%", xs: "80%" },
        margin: "20px auto",
      }}
    >
      {showUser && (
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          {showChange && (
            <FlexBox
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                backgroundColor: "#E0FFF7",
                padding: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              >
                המידע שלך עודכן בהצלחה​
              </Typography>
              <Button
                varinat="contained"
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                onClick={handleUpdate}
              >
                Update
              </Button>
            </FlexBox>
          )}
          {showUpdate === false ? (
            ""
          ) : (
            <FlexBox
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                backgroundColor: "#E0FFF7",
                padding: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              >
                המידע שלך עודכן בהצלחה​
              </Typography>
              <Button
                varinat="contained"
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                onClick={(e) => setShowUpdate(false)}
              >
                X
              </Button>
            </FlexBox>
          )}
          <Typography
            variant="h5"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            הגדרות​
          </Typography>

          <hr></hr>

          <StyledBox>
            <ListItem disablePadding sx={{ curson: "none" }}>
              <ListItemButton sx={{ margin: "0px", padding: "0px" }}>
                <ListItemIcon>
                  <EditIcon
                    sx={{
                      color: "black",
                      border: "1px solid grey",
                      borderRadius: "50%",
                      padding: "10px",
                    }}
                    onClick={(e) => setProfessionModal(true)}
                  ></EditIcon>
                </ListItemIcon>
                <StyledTypography variant="h6">מקצועות​</StyledTypography>
              </ListItemButton>
            </ListItem>
            <StyledBox sx={{ width: { md: "80%", xs: "90%" } }}>
              {user.profession !== null &&
                user.profession
                  .split(",")
                  .filter((e) => e.length > 0)
                  .map((e) => {
                    return (
                      <ProfessionBox>
                        <Typography variant="p">{e}</Typography>
                      </ProfessionBox>
                    );
                  })}
            </StyledBox>
          </StyledBox>
          <hr></hr>
          <StyledBox>
            <StyledTypography variant="h6">אזור שירות</StyledTypography>
            <StyledTypography variant="p" sx={{ marginBottom: "10px" }}>
              אזור
            </StyledTypography>
            <StyledTypography
              variant="p"
              sx={{ color: "grey", display: "block" }}
            >
              {user.area}
            </StyledTypography>
            <Button
              variant="outlined"
              sx={{ fontWeight: "300", color: "grey", fontFamily: "Assistant" }}
              onClick={(e) => setAreaModal(true)}
            >
              שנה אזור שירות
            </Button>
          </StyledBox>
          <hr></hr>
          <StyledBox>
            <StyledTypography variant="h6">שם משתמש</StyledTypography>
            <StyledTypography variant="p" sx={{ marginBottom: "10px" }}>
              שם
            </StyledTypography>
            <StyledTypography
              variant="p"
              sx={{ color: "grey", display: "block" }}
            >
              {user.name + " " + user.last_name}
            </StyledTypography>
            <Button
              variant="outlined"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "300",
                color: "grey",
              }}
              onClick={(e) => setChangeName(true)}
            >
              שנה שם
            </Button>
          </StyledBox>
          {changeName && (
            <ChangeNameModal
              open={changeName}
              handleClose={handleClose}
              admin="yes"
              user={user}
            />
          )}
          <hr></hr>
          <StyledBox>
            <StyledTypography variant="p" sx={{ marginBottom: "10px" }}>
              שם משתמש
            </StyledTypography>
            <StyledTypography
              variant="p"
              sx={{ color: "grey", display: "block" }}
            >
              {user.username}
            </StyledTypography>
          </StyledBox>

          {areaModal && (
            <ChangeServiceArea
              admin={"yes"}
              updatePage={updatePage}
              open={areaModal}
              handleClose={handleClose}
              user={user}
              addChangedUser={addChangedUser}
            />
          )}
          <hr></hr>

          <StyledBox>
            <StyledTypography variant="h6">ביוגרפיה של משתמש</StyledTypography>
            <StyledTypography variant="p" sx={{ marginBottom: "10px" }}>
              ביו
            </StyledTypography>
            <StyledTypography
              variant="p"
              sx={{ color: "grey", display: "block" }}
            >
              {user.bio !== null ? user.bio : ""}
            </StyledTypography>
            <Button
              variant="outlined"
              sx={{ fontWeight: "300", color: "grey", fontFamily: "Assistant" }}
              onClick={(e) => setChangeBio(true)}
            >
              שנה ביוגרפיה
            </Button>
          </StyledBox>
          <hr></hr>
          {areaModal && (
            <ChangeServiceArea
              admin="yes"
              updatePage={updatePage}
              open={areaModal}
              handleClose={handleClose}
              user={user}
              addChangedUser={addChangedUser}
            />
          )}
          <hr></hr>
          <StyledBox>
            <StyledTypography variant="h6" sx={{ color: "red" }}>
              שים לב!
            </StyledTypography>
            <StyledTypography variant="p" sx={{ marginBottom: "10px" }}>
              מחק חשבון
            </StyledTypography>
            <Button
              variant="contained"
              sx={{ bgcolor: "red", fontFamily: "Assistant" }}
              onClick={(e) => setDelete(true)}
            >
              מחק חשבון
            </Button>
          </StyledBox>
          {changeBio && (
            <ChangeBioModal
              open={changeBio}
              handleClose={handleClose}
              user={user}
              admin="yes"
            />
          )}
          {delete1 && (
            <DeleteModal
              open={delete1}
              handleClose={handleClose}
              user={user}
              admin="yes"
              addChangedUser={addChangedUser}
            />
          )}
          {openProfessionModal && (
            <ProfessionModal
              admin="yes"
              updatePage={updatePage}
              open={openProfessionModal}
              handleClose={handleClose}
              user={user}
              addChangedUser={addChangedUser}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default EditProfile2;
