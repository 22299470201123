import { Button } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useState } from "react";
import { NavLink, Navigate } from "react-router-dom";
import SimpleBackdrop from "../BackDrop";
import Snackbar1 from "../Snaclbar";

export default function ShowData({ what, data }) {
  let columns = [];
  console.log(Array.from(data));
  const [showSnack, setShowSnack] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  if (what === "approved" || what === "unapproved") {
    columns = [
      { id: "title", label: "כותרת", minWidth: 170 },
      { id: "skills", label: "מיומנויות", minWidth: 170, maxWidth: 220 },
      {
        id: "dateDeadline",
        label: "מועד אחרון",
        minWidth: 170,
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "bidderName",
        label: "שם משתמש של המפרסם",
        minWidth: 170,
        align: "right",
      },
      {
        id: "user_id",
        label: "זיהוי מפרסם",
        align: "right",
      },
      {
        id: "size",
        label: "גודל מדיה",
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
    ];
  } else if (what === "users") {
    columns = [
      { id: "id", label: "זהות המשתמש", minWidth: 50, maxWidth: 100 },
      { id: "name", label: "שם", minWidth: 170 },
      { id: "email", label: "אימייל", minWidth: 100 },
      {
        id: "username",
        label: "שם משתמש",
        minWidth: 170,
      },
      {
        id: "phone",
        label: "טלפון",
        minWidth: 170,
      },
      {
        id: "profession",
        label: "מיומנויות",
        minWidth: 170,
        maxWidth: 220,
      },
      {
        id: "area",
        label: "אזור",
        minWidth: 170,
      },
      {
        id: "favids",
        label: "רשימת משאלות",
        minWidth: 170,
      },
    ];
  } else if (what === "admins")
    columns = [
      {
        id: "userId",
        label: "זהות המשתמש",
        minWidth: 170,
      },
      {
        id: "id",
        label: "זיהוי מנהל",
        minWidth: 170,
      },
      { id: "email", label: "אימייל", minWidth: 100 },
      {
        id: "username",
        label: "שם משתמש",
        minWidth: 170,
      },
      {
        id: "phone",
        label: "טלפון",
        minWidth: 170,
      },
      {
        id: "created_at",
        label: "הפך למנהל",
        minWidth: 170,
      },
    ];
  else if (what === "history")
    columns = [
      {
        id: "id",
        label: "זהות המשתמש",
      },
      {
        id: "title",
        label: "כותרת",
        minWidth: 170,
      },
      { id: "email", label: "אימייל", minWidth: 170 },
      {
        id: "username",
        label: "שם משתמש",
      },
      {
        id: "adminId",
        label: "מנהל",
      },
      {
        id: "phone",
        label: "טלפון",
      },
    ];
  else if (what === "deals") {
    columns = [
      {
        id: "hello",
        label: "קישור לפרסום דרושים",
      },
      {
        id: "bidId",
        label: "זיהוי עבודה",
      },
      {
        id: "prof",
        label: "שם משתמש מקצועי",
        minWidth: 170,
      },
      {
        id: "adverter",
        label: "שם משתמש של המפרסם",
        minWidth: 170,
      },
      {
        id: "payment",
        label: "ההצעה הזוכה",
      },
      {
        id: "date",
        label: "תאריך",
      },
    ];
  }
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [id, setId] = React.useState(-1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const deleteAccount = (userUd) => {
    handleClose();
    axios
      .delete(
        `${process.env.React_APP_BACKEND_URL}/api/admin/userdelete?id=${userUd}`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("admintoken")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper
      flex={5}
      p={2}
      sx={{ width: "100%", overflow: "hidden", fontFamily: "Assistant" }}
    >
      <TableContainer sx={{ maxHeight: 440, fontFamily: "Assistant" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead sx={{ fontFamily: "Assistant" }}>
            <TableRow sx={{ fontFamily: "Assistant" }}>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              {(what === "approved" || what === "unapproved") && (
                <TableCell>נוף</TableCell>
              )}
              {what === "users" && <TableCell>תשלום</TableCell>}
              {what === "users" && <TableCell>הפוך למנהל</TableCell>}
              {what === "users" && <TableCell>למחוק</TableCell>}
              {what === "users" && <TableCell>Block User</TableCell>}
              {what === "users" && <TableCell>Edit</TableCell>}
              {what === "users" && <TableCell>Provide Review</TableCell>}
              {what === "users" && <TableCell>User Profile</TableCell>}
              {what === "admins" && <TableCell>הסר מנהל מערכת</TableCell>}
              {(what === "approved" || what === "deals") && (
                <TableCell>הסר מהטבלה</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={{ fontFamily: "Assistant" }}>
            {Array.from(data)
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];

                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : what === "approved" || what === "unapproved"
                            ? column.id === "profession" ||
                              column.id === "skills"
                              ? value
                              : value.substr(0, 20)
                            : value}
                        </TableCell>
                      );
                    })}
                    {(what === "approved" || what === "unapproved") && (
                      <TableCell>
                        <Button
                          onClick={(e) => setId(row.id)}
                          variant="contained"
                        >
                          צפה{" "}
                        </Button>
                      </TableCell>
                    )}
                    {(what === "approved" || what === "deals") && (
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            setOpen(true);
                            axios
                              .put(
                                `${process.env.React_APP_BACKEND_URL}/api/admin/removefrom`,
                                {
                                  id: row.id,
                                  what: what === "approved" ? "bid" : "deal",
                                  adminId: Cookies.get("adminId"),
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${Cookies.get(
                                      "admintoken"
                                    )}`,
                                  },
                                }
                              )
                              .then((res) => {
                                window.location.reload();
                                console.log(res);
                              })
                              .catch((err) => console.log(err));
                          }}
                          variant="contained"
                        >
                          הסר
                        </Button>
                      </TableCell>
                    )}

                    {what === "users" && (
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            setOpen(true);
                            axios
                              .post(
                                `${process.env.React_APP_BACKEND_URL}/api/admin/changepayment`,
                                {
                                  id: row.id,
                                  email: row.email,
                                  adminId: Cookies.get("adminId"),
                                  payment:
                                    row.payment === "free" ? "Premium" : "free",
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${Cookies.get(
                                      "admintoken"
                                    )}`,
                                  },
                                }
                              )
                              .then((res) => {
                                window.location.reload();
                                console.log(res);
                              })
                              .catch((err) => console.log(err));
                          }}
                          variant="contained"
                        >
                          {row.payment === "free"
                            ? "הפוך לפרימיום"
                            : "הפוך לחינם"}
                        </Button>
                      </TableCell>
                    )}

                    {what === "admins" && (
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            setOpen(true);
                            axios
                              .delete(
                                `${process.env.React_APP_BACKEND_URL}/api/admin/remove?id=${row.id}`,
                                {
                                  headers: {
                                    Authorization: `Bearer ${Cookies.get(
                                      "admintoken"
                                    )}`,
                                  },
                                }
                              )
                              .then((res) => {
                                window.location.reload();
                                setShowSnack(true);
                                setOpen(false);
                                console.log(res);
                              })
                              .catch((err) => {
                                console.log(err);
                                setOpen(false);
                              });
                          }}
                          variant="contained"
                        >
                          הסר מנהל מערכת
                        </Button>
                      </TableCell>
                    )}
                    {what === "users" && (
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            setOpen(true);
                            axios
                              .post(
                                `${process.env.React_APP_BACKEND_URL}/api/admin/sendin`,
                                {
                                  id: row.id,
                                  email: row.email,
                                  adminId: Cookies.get("adminId"),
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${Cookies.get(
                                      "admintoken"
                                    )}`,
                                  },
                                }
                              )
                              .then((res) => {
                                setShowSnack(true);
                                setOpen(false);
                                console.log(res);
                              })
                              .catch((err) => console.log(err));
                          }}
                          variant="contained"
                        >
                          שלח הזמנה
                        </Button>
                      </TableCell>
                    )}
                    {what === "users" && (
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            setOpen(true);
                            deleteAccount(row.id);
                          }}
                          variant="contained"
                        >
                          מחק
                        </Button>
                      </TableCell>
                    )}
                    {what === "users" && (
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            setOpen(true);
                            axios
                              .put(
                                `${process.env.React_APP_BACKEND_URL}/api/admin/blockuser`,
                                {
                                  id: row.id,
                                  email: row.email,
                                  adminId: Cookies.get("adminId"),
                                  block:
                                    row.block === "block" ? "unblock" : "block",
                                },
                                {
                                  headers: {
                                    Authorization: `Bearer ${Cookies.get(
                                      "admintoken"
                                    )}`,
                                  },
                                }
                              )
                              .then((res) => {
                                window.location.reload();
                                console.log(res);
                                setOpen(false);
                              })
                              .catch((err) => {
                                setOpen(false);
                                console.log(err);
                              });
                          }}
                          variant="contained"
                        >
                          {row.block === "block" ? "Unblock" : "Block"}
                        </Button>
                      </TableCell>
                    )}
                    {what === "users" && (
                      <TableCell>
                        <Button>
                          <NavLink to={`/admin/user/${row.id}`}>Edit</NavLink>
                        </Button>
                      </TableCell>
                    )}
                    {what === "users" && (
                      <TableCell>
                        <Button variant="contained">
                          <NavLink
                            to={`/admin/reviewuser/${row.id}`}
                            style={{ textDecoration: "none", color: "inherit" }}
                          >
                            Review
                          </NavLink>
                        </Button>
                      </TableCell>
                    )}
                    {what === "users" && (
                      <TableCell>
                        <Button>
                          <NavLink to={`/viewuserprofile/${row.id}`}>
                            User Reviews
                          </NavLink>
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={Array.from(data).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {id !== -1 && <Navigate to={`showbid/${id}`} />}
      {showSnack && <Snackbar1 mes={"ההזמנה נשלחה בהצלחה"} />}
      {open && (
        <SimpleBackdrop
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleToogle={handleToggle}
        />
      )}
    </Paper>
  );
}
