import {
  Avatar,
  Box,
  Button,
  Card,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FlexBox } from "./FlexBox";

function ReviewsOfUser({ id }) {
  const [reviews, setReviews] = useState([]);
  const [siteReview, setSiteReview] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getuserreviews?id=${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        console.log(res);
        setReviews(res.data.Reviews);
        setSiteReview(res.data.Site);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const deleteReview = (reviewId) => {
    axios
      .delete(
        `${process.env.React_APP_BACKEND_URL}/api/admin/deletereview?id=${reviewId}`
      )
      .then((res) => {
        window.location.reload();
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      {siteReview.length > 0 && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginBottom: "40px",
          }}
        >
          {siteReview.map((review) => (
            <Card
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                padding: "20px",
                width: "fit-content",
                marginBottom: "20px",
              }}
            >
              <FlexBox
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  flexWrap: "wrap",
                }}
              >
                <FlexBox sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
                  <Avatar sx={{ bgcolor: "#009080", padding: "20px" }}>
                    אב
                  </Avatar>
                  <Box
                    sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                    margin="0px 20px"
                  >
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: `${process.env.REACT_APP_FONT}`,
                          fontWeight: "bold",
                          display: "inline-block",
                          color: "red",
                        }}
                      >
                        אתר בידאיט{" "}
                      </Typography>
                      <span style={{ fontWeight: "light", fontSize: "75%" }}>
                        {" "}
                        *ניתן על ידי אתר בידאיט
                      </span>
                    </Box>
                    <Typography
                      variant="span"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                      }}
                    >
                      {review.des}
                    </Typography>
                    <FlexBox></FlexBox>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        marginTop: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: `${process.env.REACT_APP_FONT}`,
                          display: "inline-block",
                        }}
                      >
                        דירוג:{" "}
                      </Typography>
                      <Rating
                        dir="ltr"
                        name="simple-controlled"
                        value={review.star}
                        precision={0.5}
                        readOnly
                      />
                    </Stack>
                    {Cookies.get("admintoken") && (
                      <Button onClick={() => deleteReview(review.id)}>
                        מחק
                      </Button>
                    )}
                  </Box>
                </FlexBox>
              </FlexBox>
            </Card>
          ))}
        </Box>
      )}
      <hr></hr>
      <Box sx={{ marginTop: "30px", marginBottom: "30px" }}>
        <Typography variant="h6" sx={{ display: "block", textAlign: "center", fontFamily: "Assistant" }}>
          דירוג וחוות דעת של לקוחות
        </Typography>
      </Box>
      {reviews.length > 0 && (
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          {reviews.map((review) => (
            <Card
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                padding: "20px",
                width: "fit-content",
                marginBottom: "20px",
              }}
            >
              <FlexBox
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  flexWrap: "wrap",
                }}
              >
                <FlexBox sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
				  <Avatar sx={{ bgcolor: "#F20091", padding: "20px" }}>
                    {review.name.charAt(0) + " " + review.name.charAt(1)}
                  </Avatar>
                  <Box
                    sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                    margin="0px 20px"
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                    >
                      {review.name}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        fontFamily: `${process.env.REACT_APP_FONT}`,
                        color: "grey",
                      }}
                    >
                      {review.des}
                    </Typography>
                    <FlexBox></FlexBox>
                    <Stack
                      direction="row"
                      spacing={2}
                      sx={{
                        marginTop: "10px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: `${process.env.REACT_APP_FONT}`,
                          display: "inline-block",
                        }}
                      >
                        דירוג:{" "}
                      </Typography>
                      <Rating
                        dir="ltr"
                        name="simple-controlled"
                        value={review.star}
                        precision={0.5}
                        readOnly
                      />
                    </Stack>
                    {Cookies.get("admintoken") && (
                      <Button onClick={() => deleteReview(review.id)}>
                        מחק
                      </Button>
                    )}
                  </Box>
                </FlexBox>
              </FlexBox>
            </Card>
          ))}
        </Box>
      )}

      {reviews.length === 0 && (
        <Typography variant="p" sx={{ color: "grey", fontFamily: "Assistant" }}>
          אין עדיין חוות דעת​
        </Typography>
      )}
    </Box>
  );
}

export default ReviewsOfUser;
