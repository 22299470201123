import { Box, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import approval from "../asset/images/approval_1.png";
import SimpleBackdrop from "./BackDrop";
function UpdateBid({ bidData }) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [postBid, setPostBid] = useState(false);
  const [mediaProgress, setMediaProgress] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    setOpen(true);
    console.log(bidData);
    let imagesSize = bidData.imagesSize;

    const token = Cookies.get("token");
    console.log(token);
    let allImageUrl = bidData.images;
    let allVideoUrl = "";
    let allpublicid = bidData.publicid;
    const uploaders = Array.from(bidData.bidImages).map((file, i) => {
      let type = file.type;
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("tags", `codeinfuse, medium, gist`);
      formData.append(
        "upload_preset",
        process.env.React_App_CLOUDINARY_UPLOAD_PRESET
      );
      formData.append("api_key", process.env.React_App_CLOUDINARY_API); // Replace API key with your own Cloudinary key
      formData.append("timestamp", (Date.now() / 1000) | 0);

      // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
      return axios
        .post(
          `https://api.cloudinary.com/v1_1/${process.env.React_App_CLOUDINARY_CLOUD_NAME}/upload`,
          formData,
          {
            onUploadProgress: (data) => {
              console.log(data.loaded, data.total);
              setMediaProgress(Math.round((data.loaded / data.total) * 100));
            },
          },
          {
            headers: { "X-Requested-With": "XMLHttpRequest" },
          }
        )
        .then((response) => {
          console.log(response);
          const data = response.data;
          imagesSize += data.bytes + ",";
          console.log(imagesSize);
          allpublicid += data.public_id + ",";
          if (type.includes("video")) allVideoUrl += data.secure_url + ",";
          else allImageUrl += data.secure_url + ","; // You should store this URL for future references in your app
          //  console.log(data);
          if (i === bidData.bidImages.length - 1) setPostBid(true);
        })
        .catch((err) => console.log(err));
    });

    let date = new Date(parseInt(bidData.deadline.toString()));
    date = date.getTime() + 10 * 60000;
    date = new Date(date);
    const mins =
      date.getMinutes() < 10 ? date.getMinutes() + 10 : date.getMinutes();
    axios
      .all(uploaders)
      .then((res) => {
        axios
          .put(
            `${process.env.React_APP_BACKEND_URL}/api/updateWholeBid?id=${bidData.id}`,
            {
              title: bidData.title,
              area: bidData.area,
              skills: bidData.skills,
              notes: bidData.notes,
              description: bidData.description,
              images: allImageUrl.toString(),
              video: allVideoUrl.toString(),
              deadline: bidData.deadline.toString(),
              length1: bidData.length,
              status: true,
              extra:
                Cookies.get("name") +
                " " +
                Cookies.get("last_name").charAt(0) +
                ".",
              publicid: allpublicid,
              size: bidData.size,
              imagesSize,
              dateDeadline:
                date.getFullYear() +
                "-" +
                (date.getMonth() + 1 < 10
                  ? "0" + (date.getMonth() + 1)
                  : date.getMonth() + 1) +
                "-" +
                (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
                " " +
                (date.getHours() < 10
                  ? "0" + date.getHours()
                  : date.getHours()) +
                ":" +
                mins +
                ":" +
                "00",
            },

            {
              headers: {
                Authorization: `Bearer ${token}
              `,
              },
            }
          )
          .then((data) => {
            setSubmitted(true);
            setOpen(false);
            console.log(data);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }, [bidData]);
  return (
    <Box sx={{ width: "100%", textAlign: "center" }}>
      <Box sx={{ width: "100%" }}>
        {submitted && (
          <Box
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              textAlign: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              עודכן בהצלחה
            </Typography>
            <p>
              <img
                src={approval}
                alt="approval"
                //style={{ maxWidth: "100%", marginBottom: "10px" }}
				 style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
				width: "auto",
                display: "block",
                margin: "0 auto",
                marginBottom: "10px",
				}}
              ></img>
            </p>
            <p
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "inline-block",
              }}
            >
              תוכל לחזור כאן.  
            </p>
            <NavLink
              to="/"
              style={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: "inline-block",
              }}
            >
              חזור
            </NavLink>
          </Box>
        )}
      </Box>
      {open && (
        <SimpleBackdrop
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleToogle={handleToggle}
        />
      )}
    </Box>
  );
}

export default UpdateBid;
