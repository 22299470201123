import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { Box, styled, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import StyledButton from "./StyledButton";
function TimeandMoney({
  bid,
  post,
  handleToggle,
  setShowSnack,
  handleClose,
  setMes,
}) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [time, setTime] = useState("");

  const deadline = post.deadline;
  const addToFavorites = () => {
    handleToggle();
    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/addtowishlist`,
        {
          id: post.id,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        handleClose();
        const favids = res.data.favids;
        let favs = "";
        favids.split(",").forEach((e) => {
          favs += e + "f";
        });
        Cookies.set("favids", favs, { expires: 365 });
        setMes("נוסף לרשימת המשאלות בהצלחה");
        setShowSnack(true);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const removeFromWishList = () => {
    handleToggle();
    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/removefromwishlist`,
        {
          id: post.id,
        },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        handleClose();
        console.log(res);
        const favids = res.data.favids;
        let favs = "";
        favids.split(",").forEach((e, i) => {
          if (favids.split(",").length - 1 === i) favs += e;
          else favs += e + "f";
        });
        Cookies.set("favids", favs, { expires: 365 });
        setMes("הוסר מרשימת המשאלות בהצלחה");
        setShowSnack(true);
      })
      .catch((err) => console.log(err));
  };
  const StyledTime = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "5px",
    marginLeft: "5px",
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const getTime = useCallback(() => {
    const x = new Date().getTime();
    const time = deadline - x;
    setTime(
      Math.floor(time / (1000 * 60 * 60 * 24)) +
        ":" +
        Math.floor((time / (1000 * 60 * 60)) % 24) +
        ":" +
        Math.floor((time / 1000 / 60) % 60) +
        ":" +
        Math.floor((time / 1000) % 60)
    );
    // setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
    // setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    // setMinutes(Math.floor((time / 1000 / 60) % 60));
    // setSeconds(Math.floor((time / 1000) % 60));
  }, [deadline]);
  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, [deadline, getTime]);

  return (
    <StyledTime>
      <StyledTime>
        <AccessAlarmIcon
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginRight: "10px",
          }}
        />
        <Typography
          variant="p"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginRight: "10px",
          }}
        >
          {post.deadline - new Date().getTime() < 0 ? "הסתיים" : time}
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginRight: "5px",
          }}
        >
          ​ ₪​{post.lowestBid}
        </Typography>
      </StyledTime>
      {bid && (
        <NavLink
          to={`/showbid/${post.id}`}
          style={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            textDecoration: "none",
            color: "white",
          }}
        >
          <StyledButton />
        </NavLink>
      )}
      {Cookies.get("favids") ? (
        Cookies.get("favids")
          .split("f")
          .filter((e) => post.id.toString() === e.toString()).length === 0 ? (
          <FavoriteBorderIcon
            sx={{ zIndex: "1", cursor: "pointer" }}
            onClick={addToFavorites}
          />
        ) : (
          <FavoriteIcon
            sx={{ cursor: "pointer" }}
            onClick={removeFromWishList}
          />
        )
      ) : (
        ""
      )}
    </StyledTime>
  );
}

export default TimeandMoney;
