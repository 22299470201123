import { Box, Button, TextField, Typography } from "@mui/material";
import axios from "axios";
import JoditEditor from "jodit-react";
import Cookies from "js-cookie";
import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import SimpleBackdrop from "../BackDrop";
import UploadPhoto from "../UploadPhoto";

function CreateArticles() {
  const [enabled, setEnabled] = useState(true);
  const [inValidTitle, setValidTitle] = useState(false);
  const editorConfig = {
    readonly: false,
    toolbar: true,
    spellcheck: true,
    language: "en",
    toolbarButtonSize: "small",
    toolbarAdaptive: false,
    showCharsCounter: true,
    showWordsCounter: true,
    showXPathInStatusbar: false,
    askBeforePasteHTML: true,
    askBeforePasteFromWord: true,
    //defaultActionOnPaste: "insert_clear_html",
    uploader: {
      insertImageAsBase64URI: true,
    },
    height: "300px",
  };
  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/,
    placeholder: "Start typings...",
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [mediaProgress, setMediaProgress] = useState(0);
  const [images, setImages1] = useState([]);
  const [data, setData] = useState("");
  const [validDesc, setValidDesc] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (e) => {
    setData(e);
  };
  const setImages = (e, size) => {
    console.log(e);
    console.log("Hello I am Abid");
    let files = [];
    if (size !== 0) {
      for (let i = 0; i < e[0].length; i++) files.push(e[0][i]);
    }
    console.log(files);
    setImages1(files);
    setEnabled(false);
  };

  const ref = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    console.log("Hello");
    let month = new Date().getMonth() + 1;
    setOpen(true);
    let allImageUrl = "";
    let allVideoUrl = "";
    let allpublicid = "";
    const uploaders = Array.from(images).map((file, i) => {
      console.log(file);
      let type = file.type;
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("tags", `codeinfuse, medium, gist`);
      formData.append(
        "upload_preset",
        process.env.React_App_CLOUDINARY_UPLOAD_PRESET
      );
      formData.append("api_key", process.env.React_App_CLOUDINARY_API); // Replace API key with your own Cloudinary key
      formData.append("timestamp", (Date.now() / 1000) | 0);

      // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
      return axios
        .post(
          `https://api.cloudinary.com/v1_1/${process.env.React_App_CLOUDINARY_CLOUD_NAME}/upload`,
          formData,
          {
            onUploadProgress: (data) => {
              console.log(data.loaded, data.total);
              setMediaProgress(Math.round((data.loaded / data.total) * 100));
            },
          },
          {
            headers: { "X-Requested-With": "XMLHttpRequest" },
          }
        )
        .then((response) => {
          console.log(response);
          const data = response.data;
          allpublicid += data.public_id + ",";
          allImageUrl += data.secure_url + ","; // You should store this URL for future references in your app
          //  console.log(data);
        })
        .catch((err) => console.log(err));
    });
    setValidTitle(false);
    setValidDesc(false);
    let date = new Date();
    const data1 = new FormData(event.currentTarget);
    let title = data1.get("title");
    if (title.length <= 5) setValidTitle(true);
    if (data.length <= 30) setValidDesc(true);
    console.log(inValidTitle + " " + validDesc);

    console.log("I am Abid");
    axios
      .all(uploaders)
      .then((res) => {
        axios
          .post(
            `${process.env.React_APP_BACKEND_URL}/api/admin/postarticle`,
            {
              title,
              description: data,
              images: allImageUrl,
              publicId: allpublicid,
              date: date.getDate() + "/" + month + "/" + date.getFullYear(),
            },

            {
              headers: {
                Authorization: `Bearer ${Cookies.get("admintoken")}
              `,
              },
            }
          )
          .then((data) => {
            setSubmitted(true);
            setMediaProgress(200);
            console.log(data);
            setOpen(false);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: "70%",
        margin: "20px auto",
        marginBottom: "30px",
        paddingBottom: "20px",
      }}
    >
      <Typography
        variant="h6"
        sx={{ textAlign: "center", fontFamily: `${process.env.REACT_APP_FONT}` }}
      >
        צור מאמר
      </Typography>
      {!submitted && !open && (
        <Box
          component="form"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          onSubmit={handleSubmit}
        >
          <Typography
            variant="p"
            sx={{ fontWeight: "bold", fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            הזן כותרת של המאמר
          </Typography>
          <TextField
            margin="normal"
            sx={{ marginBottom: "20px", fontFamily: `${process.env.REACT_APP_FONT}` }}
            required
            fullWidth
            id="title"
            autoFocus
            name="title"
          />
          {inValidTitle && (
            <p
              style={{
                color: "red",
                marginTop: "10px",
                marginBottom: "10px",
                fontFamily: `${process.env.REACT_APP_FONT}`,
              }}
            >
              הכותרת צריכה להיות לפחות מ-5 תווים
            </p>
          )}
          <Typography variant="h6" sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
            הזן פירוט של המאמר
          </Typography>
          <Box
            sx={{
              display: { xs: "none", md: "block" },
              fontFamily: `${process.env.REACT_APP_FONT}`,
            }}
          >
            <JoditEditor
              tabIn
              style={{
                display: {
                  xs: "none",
                  md: "block",
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                },
              }}
              value={data}
              ref={ref}
              config={editorConfig}
              onBlur={(newContent) => handleChange(newContent)}
            />
          </Box>
		  
          <Box
            sx={{
              display: { xs: "block", md: "none" },
              fontFamily: `${process.env.REACT_APP_FONT}`,
            }}
          >
            <JoditEditor
              tabIn
              value={data}
              ref={ref}
              style={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              placeholder={"התחל לכתוב..."}
              onBlur={(newContent) => handleChange(newContent)}
            />
          </Box>
          {validDesc && (
            <p
              style={{
                color: "red",
                marginTop: "10px",
                marginBottom: "10px",
				fontFamily: `${process.env.REACT_APP_FONT}`,
              }}
            >
              התיאור צריך להיות לפחות 50 תווים
            </p>
          )}
          <UploadPhoto setImages={setImages} />
          <Button
            variant="contained"
            type="submit"
            disabled={enabled}
            sx={{ width: "100%", fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            בצע
          </Button>
        </Box>
      )}
      {open && (
        <SimpleBackdrop
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleToogle={handleToggle}
        />
      )}
      <Box sx={{ width: "100%" }}>
        {mediaProgress === 200 && (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h3" sx={{ marginBottom: "10px" }}>
              המאמר נשלח בהצלחה
            </Typography>
            <p style={{ display: "inline-block" }}>
              Article has been posted. Users will be able to view the article
              now. Go hoom from ​
            </p>
            <NavLink to="/" style={{ display: "inline-block" }}>
              כאן
            </NavLink>
          </Box>
        )}
        {open && (
          <SimpleBackdrop
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            handleToogle={handleToggle}
          />
        )}
      </Box>
    </Box>
  );
}

export default CreateArticles;
