import { Box, createTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import axios from "axios";
import parse from "html-react-parser";
import Cookies from "js-cookie";
import * as React from "react";
import { useState, useEffect } from "react";
import { NavLink, Navigate } from "react-router-dom";

export default function ArticleShow({ article }) {
  const [viewArt, setViewArt] = useState(false);
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Assistant",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  const onArticleClicked = () => {
    setViewArt(true);
    let views = parseInt(article.views) + 1;
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/updateview`,
        { id: article.id, views },
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data.Articles);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box sx={{ minWidth: "300px" }}>
      {viewArt && <Navigate to={`/viewarticle/${article.id}`}></Navigate>}
      <Card
        sx={{ maxWidth: 345, cursor: "pointer", height: "300px" }}
        onClick={onArticleClicked}
      >
        {article.images !== null && (
          <Box>
            <img
              src={article.images.split(",")[0]}
              maxWidth="100%"
              style={{
                objectFit: "fill",
                margin: "0px auto",
                paddingLeft: "0px",
                paddingRight: "0px",
                textAlign: "center",
              }}
              alt="green iguana"
            />
          </Box>
        )}
        {article.images === null && (
          <Box
            sx={{
              backgroundColor: "#7C7C7C",
              height: "200px",
              margin: "0px auto",
              verticalAlign: "center",
              paddingLeft: "0px",
              paddingRight: "0px",
              textAlign: "center",
              fontFamily: "Assistant",
            }}
          >
            <Typography
              variant="h6"
              sx={{ color: "white", fontFamily: "Assistant" }}
            >
              bidit
            </Typography>
          </Box>
        )}
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            sx={{ fontFamily: "Assistant" }}
            component="div"
          >
            {article.title.substr(0, 25)}
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontFamily: "Assistant" }}
            color="text.secondary"
          >
            {parse(article.description.substr(0, 30) + " ....")}
          </Typography>
        </CardContent>
         <CardActions>
          <NavLink to={`/viewarticle/${article.id}`}>
            <Button size="small" sx={{ fontFamily: "Assistant", marginTop: '-13px' }}>
              קרא עוד
            </Button>
          </NavLink>
        </CardActions>
      </Card>
    </Box>
  );
}
