import { Box } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfessionalAboutMe from "./ProfessionalAboutMe";

function ShowProfessionalProfile() {
  const { id } = useParams();
  const [about, setAbout] = useState(true);
  const [settings, setSettings] = useState(false);
  const [bids, setBids] = useState(false);
  const [user, setUser] = useState();
  const [showUser, setShowUser] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getuserprofile?id=${id}`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        let user = res.data.user;
        user.rating = res.data.rating;
        setUser(user);
        setShowUser(true);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const handleProfile = (e) => {
    if (e === "about") {
      setSettings(false);
      setAbout(true);
      setBids(false);
    } else if (e === "bids") {
      setSettings(false);
      setAbout(false);
      setBids(true);
    } else if (e === "settings") {
      setSettings(true);
      setAbout(false);
      setBids(false);
    }
  };
  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {showUser && <ProfessionalAboutMe user={user} />}
    </Box>
  );
}

export default ShowProfessionalProfile;
