import {
  Box,
  Button,
  CircularProgress,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import NoEntry from "./NoEntry";
function PostReview() {
  const { id } = useParams();
  const [userId, setUserId] = useState(-1);
  const [des, setDes] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [navigat, setNavigat] = useState(false);

  const [value, setValue] = useState(0);
  const [result, setResult] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getresult?id=${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.Result[0].major.toString() !== "3")
          setResult(res.data.Result[0]);
        else setResult(res.data.Result[1]);
        setUserId(res.data.UserId.user_id);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const handleSubmit = () => {
    console.log(des);
    setIsLoading(true);
    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/providereview`,
        {
          bidAmount: result.bidAmount,
          bidderId: result.bidderId,
          reviewerId: userId,
          des: des,
          star: value.toString(),
          bidId: result.bidId,
        },
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      )
      .then((res) => {
        setDes("");
        setNavigat(true);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        marginTop: "20px",
        margin: "0px auto",
      }}
    >
      {result.review === "Yes" && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginTop: "25px",
            textAlign: "center",
          }}
        >
          <Typography
            variant={{ xs: "p", md: "h6" }}
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              textAlign: "center",
            }}
          >
            שים לב, 
          </Typography>
          <Typography
            variant="p"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            כבר ניתנה חוות דעת לעבודה הזו.
            <NavLink to="/home" style={{ fontFamily: "Assistant" }}> חזור לדף הבית</NavLink>
          </Typography>
        </Box>
      )}
      {userId.toString() !== Cookies.get("id").toString() && <NoEntry />}
      {!isLoading && !navigat && result.review !== "Yes" && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            width: { xs: "80%", md: "70%" },
            margin: "0px auto",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            מלא כאן את חוות הדעת שלך{" "}
          </Typography>
          <TextField
            margin="normal"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "20px",
            }}
            required
            fullWidth
            id="title"
            //autoFocus
            value={des}
            onChange={(e, newValue) => setDes(e.target.value)}
            label="תאר את המקצועיות, אמינות ויחסי האנוש של בעל המקצוע"
            name="title"
          />
          <Rating
            name="simple-controlled"
            value={value}
            dir="ltr"
            precision={0.5}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
          <Button
            onClick={handleSubmit}
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              display: "block",
              marginTop: "20px",
            }}
            variant="contained"
          >
            שלח חוות דעת
          </Button>
        </Box>
      )}
      {isLoading && (
        <Box sx={{ textAlign: "center", marginTop: "50px" }}>
          <CircularProgress />
          <Typography variant="h6">נא המתן</Typography>
        </Box>
      )}
      {navigat && (
        <Box sx={{ marginTop: "25px", textAlign: "center" }}>
          <Typography variant="h6" sx={{ textAlign: "center", fontFamily: "Assistant" }}>
            חוות הדעת נשלחה בהצלחה
          </Typography>
          <Typography variant="p" sx={{ textAlign: "center", fontFamily: "Assistant" }}>
            תודה שמילאת את חוות הדעת שלך! זה מאוד עוזר לבעל המקצוע ולשאר המשתמשים
            <NavLink to="/home" style={{ fontFamily: "Assistant" }}> חזור לדף הבית</NavLink>
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default PostReview;
