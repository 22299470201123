import { Box, Button, styled, TextField, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SimpleBackdrop from "./BackDrop";

function PlanForm() {
  const [bidData, setBidData] = useState({});
  const [title, setTitle] = useState("");
  const [next, setNext] = useState(false);
  const [data, setData] = useState("");
  const [content, setContent] = useState("");
  const [open, setOpen] = useState(false);
  const [goToHome, setGoToHome] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const [validTitle, setValidTitle] = useState(false);

  const StyledBox = styled(Box)({
    width: "70%",
    margin: "0px auto",
    marginTop: "20px",
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });

  //   const config = {
  //     readonly: false, // all options from https://xdsoft.net/jodit/doc/,
  //     placeholder:
  //       "Enter Your Description(Should be more than at least 300characters)",
  //     height: "400px",
  //   };
  const [area, setArea] = React.useState("New York");
  const [time, setTime] = React.useState(24);
  const [title1, setTitle1] = React.useState("");
  const handleChange = (event) => {
    event.preventDefault();
    const change = event.target.value;

    if (event.target.name === "location") setArea(change);
    else if (event.target.name === "time") {
      setTime(change);
    } else setTitle1(change);
  };
  const handleSu = (event) => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/selectplan?id=1`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    const data = new FormData(event.currentTarget);
    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/createplan`,
        {
          name: data.get("name"),
          cost: data.get("cost"),
          description: data.get("description"),
        },
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      )
      .then((res) => {
        console.log(res);
        setGoToHome(true);
        setOpen(false);
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box
      flex={5}
      p={2}
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: "100%",
        overflow: "hidden",
      }}
    >
      {!goToHome && (
        <StyledBox
          noValidate
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 1 }}
        >
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            <Typography
              variant="h6"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                textAlign: "center",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              צור תוכנית עבור המנוי החודשי של המשתמשים
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
              }}
            >
              הזן את כותרת התוכנית
            </Typography>
            <TextField
              margin="normal"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginBottom: "20px",
              }}
              required
              fullWidth
              id="title"
              //autoFocus
              name="name"
            />
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
              }}
            >
              הזן את מחיר התוכנית
            </Typography>
            <TextField
              margin="normal"
              sx={{ marginBottom: "20px" }}
              required
              fullWidth
              id="title"
              //autoFocus
              name="cost"
            />
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
              }}
            >
              הזן את תיאור התוכנית
            </Typography>
            <TextField
              margin="normal"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginBottom: "20px",
              }}
              required
              fullWidth
              id="description"
              //autoFocus
              name="description"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2 }}
            >
              שלח
            </Button>
          </Box>
          {open && (
            <SimpleBackdrop
              open={open}
              setOpen={setOpen}
              handleToogle={handleToggle}
            />
          )}
        </StyledBox>
      )}
      {goToHome && (
        <StyledBox>
          <Typography
            variant="p"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            הידיעה הוגשה. לך הביתה מ <NavLink to="/admin/home">here</NavLink>
          </Typography>
        </StyledBox>
      )}
    </Box>
  );
}

export default PlanForm;
