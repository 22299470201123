import { Button, Stack, ThemeProvider, createTheme } from "@mui/material";
import React, { useState } from "react";

function ProfileMenu({ handleProfile }) {
  const [color1, setColor1] = useState("red");
  const [color2, setColor2] = useState("black");
  const [color3, setColor3] = useState("black");
  const theme = createTheme({
    typography: {
      fontFamily: [
        "Assistant",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Stack
        flex={1}
        p={2}
        direction={{ xs: "row", md: "column" }}
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          fontWeight: "bold",
          marginLeft: "0px",
          textAlign: "center",
        }}
      >
        <Button
          onClick={(e) => {
            setColor1("red");
            setColor2("black");
            setColor3("black");
            handleProfile("about");
          }}
          variant="text"
          sx={{
            marginBottom: "10px",
            display: "block",
            color: color1,
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontWeight: "bold",
          }}
        >
          <span style={{ fontWeight: "bold" }}>קצת על עצמי​</span>
        </Button>
        <Button
          onClick={(e) => {
            setColor2("red");
            setColor1("black");
            setColor3("black");
            handleProfile("settings");
          }}
          variant="text"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontWeight: "bold",
            marginBottom: "10px",
            display: "block",
            color: color2,
          }}
        >
          הגדרות​
        </Button>
        <Button
          onClick={(e) => {
            setColor3("red");
            setColor1("black");
            setColor2("black");
            handleProfile("bids");
          }}
          variant="text"
          sx={{
            marginBottom: "10px",
            display: "block",
            color: color3,
            fontFamily: `${process.env.REACT_APP_FONT}`,
            fontWeight: "bold",
          }}
        >
          היסטורית מכרזים​
        </Button>
      </Stack>
    </ThemeProvider>
  );
}

export default ProfileMenu;
