import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import * as React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 240, md: 400 },
  bgcolor: "#0C243B",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  fontFamily: `${process.env.REACT_APP_FONT}`,
  color: "white",
};

export default function BasicModal({
  handleOpen,
  handleClose,
  setOpen,
  open,
  uploadImages,
  cancel,
}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          {!cancel && (
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  marginBottom: "10px",
                }}
              >
                אתה בטוח שאתה מעוניין להעלות את הקבצים?
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  marginBottom: "10px",
                }}
              >
                *תכנים פוגעניים יחסמו
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  margin: "0px 10px",
                }}
                onClick={uploadImages}
              >
                המשך
              </Button>
              <Button
                variant="outlined"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  margin: "0px 10px",
                  color: "white",
                }}
                onClick={handleClose}
              >
                חזור
              </Button>
            </Box>
          )}
          {cancel && (
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  marginBottom: "10px",
                }}
              >
                נפח גדול מ30 מגה
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  marginBottom: "10px",
                }}
              >
                עליך להקטין את כמות או גודל התמונות
              </Typography>
              <Button
                variant="contained"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  margin: "0px 10px",
                  color: "white",
                  padding: "5px 30px",
                }}
                onClick={handleClose}
              >
                אוקי
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}
