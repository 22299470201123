import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import no from "../asset/images/no.jpeg";
function NoEntry() {
  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      <img src={no} alt="No Entry"></img>
      <Typography
        variant="h6"
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
      >
        Sorry, You are not allowed to visit this page.{" "}
        <NavLink to="/home">Go to home form here</NavLink>
      </Typography>
    </Box>
  );
}

export default NoEntry;
