import { Box, Stack } from "@mui/material"
import bidit from "../../../asset/images/cell_phone_3.png"
import sto from "../../../asset/images/sto.png";
import sto2 from "../../../asset/images/sto2.png";
import sto3 from "../../../asset/images/sto3.png";

export default function Working({text}){
    console.log(text)
    return (<div style={{margin:"15px auto"}}>
        <Box sx={{width:{xs:"250px",md:"560px"},margin:"0px auto", display: "flex", justifyContent: "center"}}>
   <img src={bidit} style={{margin:"0px auto",display:"block",width:"120%",height:"120%", borderRadius: "30px"}}/>
   </Box>
   <Box sx={{margin:"20px auto"}}>
   <Stack  sx={{flexDirection:{xs:"column",md:"row"},gap:"30px",margin:"10px",alignItems:'center',justifyContent:"center"}}>
   <h2 style={{margin:"20px 0px", fontFamily: 'Assistant'}}>{text.workingFirst}</h2>
   <img src={sto}/>
   <div>
        </div>
        
       
    </Stack>
    <Stack  sx={{flexDirection:{xs:"column",md:"row"},gap:"30px",margin:"10px",alignItems:'center',justifyContent:"center"}}>
	<h2 style={{margin:"20px 0px", fontFamily: 'Assistant'}}>{text.workingSecond}</h2>
    <img src={sto2}/>
    <div>
        </div>
     
    </Stack>
    <Stack  sx={{flexDirection:{xs:"column",md:"row"},gap:"30px",margin:"10px",alignItems:'center',justifyContent:"center"}}>
	<h2 style={{margin:"20px 0px", fontFamily: 'Assistant'}}>{text.workingThird}</h2>
	<img src={sto3}/>
    <div>
        </div>
        
    </Stack>
   </Box>
    </div>)
}