import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useState } from "react";
import OtpInput from "react-otp-input";
import { Navigate } from "react-router-dom";
import ValidateEmail from "../asset/js/ValidateEmail";
import SimpleBackdrop from "./BackDrop";

import { Copyright } from "./Copyright";
const theme = createTheme();

export default function ChangeUpdateEmail() {
  const [direct, setDirect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [sentOtp, setOtp] = useState("");
  const [next1, setNext1] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleSubmit1 = () => {
    if (otp.toString() === sentOtp.toString()) {
      setOpen(true);
      axios
        .put(
          `${process.env.React_APP_BACKEND_URL}/api/changeemail`,
          {
            changeEmail: email.current,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .then((res) => {
          setOpen(false);
          setNext1(true);
          Cookies.set("subscription", "No", { expires: 365 });

          Cookies.remove("favids");
          Cookies.remove("token");
          Cookies.remove("name");
          Cookies.remove("last_name");
          Cookies.remove("id");
          Cookies.remove("username");
          Cookies.remove("email");
          Cookies.remove("phone");
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [next, setNext] = React.useState(false);
  const [otp, setValue] = useState("");
  let email = React.useRef();
  const [validEmail, setValidEmail] = React.useState(false);
  const handleSubmit = () => {
    setValidEmail(false);
    console.log(email.current);
    if (ValidateEmail(email.current)) {
      setOpen(true);
      axios
        .get(
          `${process.env.React_APP_BACKEND_URL}/api/checkemail?email=${email.current}`
        )
        .then((data1) => {
          if (data1.status === 200) {
            setOtp(data1.data.OTP);
            setOpen(false);
            setNext(true);
            setDirect(true);
          }
        })
        .catch((err) => {
          setOpen(false);
          console.log(err);
          if (err.response.status === 400) setValidEmail(true);
        });
    } else {
      setValidEmail(true);
    }
  };
  const handleChange = (event) => {
    console.log(event);
    setValue(event);
  };
  const handleEmail = (e) => {
    const change = e.target.value;
    email.current = change;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            component="h1"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            variant="h5"
          >
            עדכן דוא"ל​
          </Typography>
          {!next && !direct && (
            <Box noValidate sx={{ mt: 1 }}>
              <Typography
                variant="p"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  fontWeight: "bold",
                }}
              >
                כתובת דוא"ל:
              </Typography>
              <TextField
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                margin="normal"
                required
                fullWidth
                onChange={(e) => handleEmail(e)}
                id="email"
                name="email"
                autoComplete="email"
                //autoFocus
              />
              {validEmail && (
                <p
                  style={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    color: "red",
                    fontWeight: "bold",
                  }}
                >
                  כתובת הדוא"ל אינה תקינה או שהיא כבר בשימוש 
                </p>
              )}
              <Button
                fullWidth
                onClick={handleSubmit}
                variant="contained"
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  mt: 3,
                  mb: 2,
                }}
              >
                הבא​
              </Button>
            </Box>
          )}
          {open && (
            <SimpleBackdrop
              open={open}
              setOpen={setOpen}
              handleClose={handleClose}
              handleToogle={handleToggle}
            />
          )}
          {next && (
            <Box>
              <Typography variant="h6">
                הזן את הסיסמה שנשלחה למייל שלך
              </Typography>
              <Box dir="ltr">
                <OtpInput
                  containerStyle={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    textAlign: "center",
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "30px",
                    color: "black",
                    fontWeight: "110%",
                  }}
                  value={otp}
                  onChange={handleChange}
                  numInputs={6}
                  separator={<span>-</span>}
                />
              </Box>
              <Button variant="contained" onClick={handleSubmit1}>
                Submit
              </Button>
            </Box>
          )}
        </Box>
        {next1 && <Navigate to="/signin"></Navigate>}
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
