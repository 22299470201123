import { Box, Button, Typography } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import SimpleBackdrop from "./BackDrop";
const CheckoutForm = ({ intent }) => {
  const [isLoading, seIsLoading] = useState(false);
  const [thanks, setThanks] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const stripe = useStripe();
  const elements = useElements();
  const [plan, setPlan] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getplan?id=${id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        setPlan(res.data.plan);
      })
      .catch((err) => console.log(err));
  }, [id]);
  const createSubscription = async () => {
    seIsLoading(true);
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });
      console.log(paymentMethod);
      const response = await axios.post(
        `${process.env.React_APP_BACKEND_URL}/api/choose?id=${id}`,
        {
          paymentMethod: paymentMethod.paymentMethod.id,
        },
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      );
      if (response.data.Updated.subscriptions[0].stripe_status === "active") {
        setThanks(true);
        Cookies.set("subscription", "Yes", { expires: 365 });
        return alert("Payment Successful! Subscription active.");
      } else return alert("Payment unsuccessful! Wrong credentials");
      // alert("Payment Successful! Subscription active.");
      // console.log(response.data.Updated.client_secret);
      // const confirm = await stripe.confirmCardPayment(
      //   response.data.Updated.client_secret
      // );
      // console.log(confirm);
      // if (confirm.error) return alert("Payment unsuccessful!");
      // alert("Payment Successful! Subscription active.");
    } catch (err) {
      console.error(err);
      alert("Payment failed! " + err.message);
    }
  };

  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: "50%",
        margin: "40px auto",
      }}
    >
      {plan.length > 0 && (
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <Typography
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "20px",
              color: "red",
            }}
            variant="h6"
          >{`Subscribe to ${plan[0].name} for $${plan[0].cost}`}</Typography>

          <CardElement />
          <br />
          <Button
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
            onClick={createSubscription}
            variant="contained"
          >
            Subscribe- {plan[0].name}
          </Button>
        </Box>
      )}
      {open && (
        <SimpleBackdrop
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleToogle={handleToggle}
        />
      )}
      {thanks && <Navigate to="/thanks"></Navigate>}
    </Box>
  );
};

export default CheckoutForm;
