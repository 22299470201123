import {
  Box,
  Button,
  Checkbox,
  createTheme,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Select as Select1,
  styled,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import returnCityNames from "../asset/js/CityNames";
import PostBidGeneralInfo from "./PostBidGeneralInfo";
function PostBid() {
  const [bidData, setBidData] = useState({});
  const [title, setTitle] = useState("");
  const [next, setNext] = useState(false);
  const [data, setData] = useState("");
  const [content, setContent] = useState("");

  const [validTitle, setValidTitle] = useState(false);
  const cityNames = returnCityNames();
  const StyledBox = styled(Box)({
    margin: "0px auto",
    marginTop: "20px",
  });
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setArea(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const theme = createTheme({
    typography: {
      fontFamily: [
        "Assistant",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });
  const [area, setArea] = React.useState([]);
  const [time, setTime] = React.useState(1);
  const [title1, setTitle1] = React.useState("");
  const handleChange = (event) => {
    event.preventDefault();
    const change = event.target.value;
    if (event.target.name === "time") {
      setTime(change);
    } else setTitle1(change);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const date = new Date();

    console.log(date.getTime());

    bidData.area = area;
    bidData.time = date.getTime() + time * 60 * 60;
    bidData.length = time * 60 * 60;
    setValidTitle(false);
    if (title1.length < 5) setValidTitle(true);

    if (!validTitle) {
      let area1 = "";
      for (let i = 0; i < area.length; i++) area1 += area[i];
      setBidData({
        title: title1 + " - " + area,
        area: area1,
        time: date.getTime() + time * 60 * 60 * 1000,
        length: time * 60 * 60 * 1000,
      });
      setNext(true);
    }
  };
  return (
    <div>
      <StyledBox
        noValidate
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          mt: 1,
          width: { xs: "100%", md: "70%" },
        }}
      >
        {!next && (
          <Box component="form" onSubmit={handleSubmit}>
            <Typography
              variant="h6"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                textAlign: "center",
                marginBottom: "20px",
                marginTop: "20px",
              }}
            >
              פרסם מכרז עבודה ובדוק שכל השדות מולאו כראוי​
            </Typography>
            <Typography
              variant="p"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                fontWeight: "bold",
              }}
            >
              הזן כותרת של המכרז, מומלץ לציין גם את העיר
            </Typography>
            <ThemeProvider theme={theme}>
              <TextField
                margin="normal"
                sx={{
                  fontFamily: "Assistant",
                  marginBottom: "20px",
                }}
                required
                fullWidth
                value={title1}
                onChange={handleChange}
                id="title"
                autoFocus
                name="title"
              />
            </ThemeProvider>
            {validTitle && (
              <p
                style={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  color: "red",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                הכותרת צריכה להיות לפחות 5 תווים
              </p>
            )}

            <InputLabel
              id="area"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginTop: "10px",
              }}
            >
              בחר את האזור שבו תתבצע העבודה​
            </InputLabel>
            <Select1
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                width: "100%",
                marginBottom: "10px",
              }}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              placeholder="Enter Area"
              value={area}
              required
              onChange={handleChange1}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {[
                "צפון",
                "קרית שמונה והסביבה",
                "חיפה והסביבה",
                "ירושלים והסביבה",
                "נתניה והסביבה",
                "השרון והסביבה",
                "מרכז",
                "שפלה והסביבה",
                "באר שבע והסביבה",
                "דרום",
              ].map((name, i) => (
                <MenuItem
                  key={name}
                  value={name}
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    flexDirection: "column",
                    fontSize: { xs: "80%", md: "100%" },
                  }}
                >
                  <Checkbox checked={area.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                  <Box
                    display="block"
                    sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                  >
                    {name === "צפון" ? (
                      <Box>
                        {cityNames.southCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "קרית שמונה והסביבה" ? (
                      <Box>
                        {cityNames.northCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "חיפה והסביבה" ? (
                      <Box>
                        {cityNames.westCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "ירושלים והסביבה" ? (
                      <Box>
                        {cityNames.eastCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "נתניה והסביבה" ? (
                      <Box>
                        {cityNames.eCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "השרון והסביבה" ? (
                      <Box>
                        {cityNames.fCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "מרכז" ? (
                      <Box>
                        {cityNames.gCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "שפלה והסביבה" ? (
                      <Box>
                        {cityNames.hCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "באר שבע והסביבה" ? (
                      <Box>
                        {cityNames.iCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
                    ) : name === "דרום" ? (
                      <Box>
                        {cityNames.jCities.map((e) => {
                          return (
                            <Typography
                              variant="p"
                              display="block"
                              sx={{
                                fontFamily: `${process.env.REACT_APP_FONT}`,
                                color: "grey",
                                margin: "0px 3px",
                              }}
                            >
                              {e}
                            </Typography>
                          );
                        })}
                      </Box>
					 ) : (
                       ""
					 )}
                  </Box>
                </MenuItem>
              ))}
            </Select1>
            <InputLabel
              id="time"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                marginTop: "20px",
              }}
            >
              בחר את משך הזמן למכרז​
            </InputLabel>
            <Select
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                width: "100%",
              }}
              labelId="time"
              id="time"
              value={time}
              label="Time"
              name="time"
              onChange={handleChange}
            >
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={0.166}
              >
                10 דקות
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={0.5}
              >
                30 דקות
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={1}
              >
                1 שעה
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={3}
              >
                3 שעות
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={6}
              >
                6 שעות
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={12}
              >
                12 שעות
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={24}
              >
                1 יום
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={48}
              >
                2 ימים
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={72}
              >
                3 ימים
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={96}
              >
                4 ימים
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={120}
              >
                5 ימים
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={144}
              >
                6 ימים
              </MenuItem>
              <MenuItem
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
                value={168}
              >
                7 ימים
              </MenuItem>
            </Select>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              הבא​
            </Button>
          </Box>
        )}
        {next && <PostBidGeneralInfo bidData={bidData} />}
      </StyledBox>
    </div>
  );
}

export default PostBid;
