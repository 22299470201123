import { Box, Typography } from "@mui/material";
import React from "react";
import GeneralNews from "./GeneralNews";

function Rightbar({ news }) {
  return (
    <Box flex={1} p={2}>
      <Typography
        variant="h4"
		//sx={{ fontFamily: `${process.env.REACT_APP_FONT}`}}
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, borderRadius: "20px", backgroundColor: "#f7b237", padding: "10px" }}
      >
        הודעות חשובות​
      </Typography>
      {Array.from(news).map((news) => {
        return <GeneralNews news={news} />;
      })}
    </Box>
  );
}

export default Rightbar;
