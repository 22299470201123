import { Box, Stack } from "@mui/material";
import tick from "../../../asset/images/v-live-1.png"
import JoinUs from "./JoinUsButton";
export default function Support({text,prof}){
 
    return (<Box style={{margin:"40px auto",width:{md:"85%",xs:"95%"}}}>
        <h1 style={{ marginBottom: "10px", fontFamily: "Assistant", textAlign: "center", fontWeight: "bold" }}>למה כדאי להצטרף אלינו?</h1>
        <h2 style={{ marginBottom: "10px", fontFamily: "Assistant", textAlign: "center", fontWeight: "bold" }}>פשוט כי יש לנו את כל מה שבעל מקצוע ולקוח היו רוצים!</h2>
        {text.supportList.map(e=>{
         return (<Stack key={e} direction={"row"}gap="20px" sx={{alignItems:'center',justifyItems:'center'}}>
            <Box>
                <img src={tick} style={{width:"40px",height:'40px'}}/>
            </Box>
            <Box>
                <p style={{fontSize:'18px', fontFamily:"Assistant"}}>{e}</p>
            </Box>
         </Stack>)
        })}
               <JoinUs prof={prof}text={"אני רוצה להרשם! >"}/>

    </Box>)
}