import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  styled,
  Typography,
} from "@mui/material";
import axios from "axios";
import parse from "html-react-parser";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import AdvertVideos from "./AdvertVideos";
import AlertModal from "./AlertModal";
import BidModal from "./BidModal";
import Bidded from "./Bids";
import TextareaValidator from "./CommentBox";
import CommentDesign from "./CommentDesign";
import ExplainUser from "./ExplainUser";
import { FlexBox } from "./FlexBox";
import ListItem1 from "./ListItem";
import Snackbar1 from "./Snaclbar";

function ShowBidPost({ post, skills, bidId, result, loadFunction, bidderId }) {
  const [comments, setComments] = useState([]);
  const [message, setMessage] = useState("");
  const [showComment, setShowComment] = useState(false);
  useEffect(
    (e) => {
      axios
        .get(
          `${process.env.React_APP_BACKEND_URL}/api/getcomment?id=${bidId}`,
          {
            headers: {
              Authorization: `Bearer ${Cookies.get("token")}`,
            },
          }
        )
        .then((res) => {
          setComments(res.data.Comments);
          setShowComment(true);
          console.log(res);
        })
        .catch((err) => console.log(err));
    },
    [bidId]
  );
  const loadComments = () => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getcomment?id=${bidId}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      })
      .then((res) => {
        setComments(res.data.Comments);
        setShowComment(true);
        console.log(res);
      })
      .catch((err) => console.log(err));
  };
  const [showSnack, setShowSnack] = useState(false);
  const StyledBox = styled(Box)({
    marginTop: "10px",
    float: "none",
    marginBottom: "10px",
  });
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [error, setError] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const ProfessionBox = styled(Box)({
    backgroundColor: "#E8F4FF",
    padding: "10px",
    display: "inline-block",
    margin: "10px",
    marginTop: "0px",
    boxShadow: 2,
    fontFamily: `${process.env.REACT_APP_FONT}`,
  });
  const style = {
    position: "absolute",
    fontFamily: `${process.env.REACT_APP_FONT}`,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [notes, setNotes] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Gell");
    let notes1 = notes;
    setNotes("");
    const date = new Date();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let d = date.getDate();
    let time = d + "-" + m + "-" + y;
    setError(false);

    if (notes1 !== undefined) {
      console.log("Hello");
      if (notes1.length > 0) {
        axios
          .post(
            `${process.env.React_APP_BACKEND_URL}/api/postcomment`,
            {
              name: Cookies.get("name"),
              username: Cookies.get("username"),
              email: Cookies.get("email"),
              userId: Cookies.get("id"),
              bidId,
              comment: notes1,
              time,
            },
            {
              headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
              },
            }
          )
          .then((res) => {
            loadComments();
            setShowSnack(true);
            setMessage("התגובה נוספה בהצלחה");
            console.log(res);
          })
          .catch((err) => console.log(err));
      } else {
        setError(true);
      }
    }
  };
  const giveBid = (value) => {
    console.log(value);
    const username = Cookies.get("username");
    const email = Cookies.get("email");
    const token = Cookies.get("token");
    const date = new Date();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    let d = date.getDate();
    let time = d + "-" + m + "-" + y;
    axios
      .post(
        `${process.env.React_APP_BACKEND_URL}/api/givebid`,
        {
          username,
          email,
          bidAmount: parseInt(value),
          time,
          bidId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => {
        setShowSnack(true);
        setMessage("הצעת המחיר נוספה בהצלחה");
        console.log(res);
        loadFunction();
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box
      sx={{
        fontFamily: `${process.env.REACT_APP_FONT}`,
        width: { xs: "100%", md: "65%" },
        margin: { md: "0px 25px", xs: "0px" },
      }}
    >
      {showSnack && <Snackbar1 mes={message} />}
      {error && <Snackbar1 mes={"התגובה לא יכולה להיות ריקה"} />}
      {post.images !== null && (
        <Box
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            display: {
              xs: "block",
              md: "none",
            },
          }}
        >
          <ImageList
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              width: "100%",
              height: 150, //450
              marginTop: "0px",
              display: {},
            }}
            cols={3}
            rowHeight={164}
          >
            {post.images !== null &&
              post.images
                .split(",")
                .filter((e) => {
                  if (e !== ",") return e;
                  return "";
                })
                .map((item) =>
                  item.length > 0 ? (
                    <ImageListItem key={item.img}>
                      <img src={item} alt="Side" loading="lazy" />
                    </ImageListItem>
                  ) : (
                    ""
                  )
                )}
          </ImageList>
        </Box>
      )}
      {post.images !== null && (
        <Box
          component="img"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            display: { xs: "none", md: "block" },
            width: "100%",
            minHeight: "350px",
            maxHeight: "600px",
          }}
          alt="The house from the offer."
          src={post.images.split(",")[0]}
        />
      )}

      <FlexBox
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          marginTop: "20px",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          sx={{
            width: "80%",
            fontFamily: `${process.env.REACT_APP_FONT}`,
            textAlign: "center",
            backgroundColor: "#262626",
            padding: "10px",
            borderRadius: "5px",
            marginRight: "10px",
          }}
        >
          <FlexBox
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, color: "white" }}
          >
            <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
              <ListItem1
                sx={{
                  fontFamily: `${process.env.REACT_APP_FONT}`,
                  padding: "0px",
                }}
                post={post}
              />
            </Box>
            <Typography
              variant="span"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: {
                  xs: "block",
                },
              }}
            >
              ₪{post.lowestBid} הצעה
            </Typography>
            <Typography
              variant="span"
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                display: { xs: "none", md: "block" },
              }}
            >
              {
                Array.from(result).filter((e) => (e.block === "false" ? e : ""))
                  .length
              }{" "}
              מספר הצעות​
            </Typography>
          </FlexBox>
        </Box>
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <Button
            variant={
              new Date().getTime() - post.deadline < 0 &&
              post.user_id.toString() !== Cookies.get("id").toString()
                ? "contained"
                : "disabled"
            }
            onClick={(e) => {
              const res = result;
              const p = res.filter((e1) => {
                console.log(e1);
                return parseInt(Cookies.get("id")) === parseInt(e1.bidderId);
              });
              console.log(p.length > 0 && p[0].block.toString() === "true");
              if (
                Cookies.get("bio") === "no" ||
                Cookies.get("experience") === "no" ||
                Cookies.get("profession") === "no"
              )
                return handleOpen3();
              else {
                if (p.length > 0 && p[0].block.toString() === "true") {
                  return handleOpen2();
                } else if (new Date().getTime() - parseInt(post.deadline) < 0)
                  return handleOpen();
              }
            }}
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              width: { xs: "100%" },
              marginTop: { xs: "20px", md: "0px" },
            }}
          >
            תן הצעה​
          </Button>
        </Box>
      </FlexBox>
      {open3 && (
        <ExplainUser
          open={open3}
          handleOpen={handleOpen3}
          handleClose={handleClose3}
        />
      )}
      {open2 && (
        <AlertModal
          open={open2}
          handleOpen={handleOpen2}
          handleClose={handleClose2}
          message={
            "מפרסם המכרז חסם אותך מלהציע הצעות במכרז זה"
          }
          what="not"
        />
      )}
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginBottom: "20px",
            marginTop: "10px",
			borderRadius: "20px",
			backgroundColor: "#f7b237",
			padding: "7px",
          }}
        >
          פירוט הצעת העבודה​
        </Typography>
        <p style={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          {parse(post.description)}
        </p>
        <Typography
          variant="h6"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginBottom: "20px",
            marginTop: "20px",
			borderRadius: "20px",
			backgroundColor: "#e0e0e0",
			padding: "7px",
          }}
        >
          בעל מקצוע נדרש​
        </Typography>
        <StyledBox>
          {skills
            .filter((e) => e.length > 0)
            .map((e) => {
              return (
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: `${process.env.REACT_APP_FONT}`,
                    marginRight: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#D7D7D5",
                    color: "black",
                    boxShadow: "10px 0px 10px rgba(0, 0, 0, 0.1)",
                    fontWeight: "bold",
                  }}
                >
                  <Typography variant="p">{e}</Typography>
                </Button>
              );
            })}
        </StyledBox>
        <Typography
          variant="h6"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginBottom: "20px",
            marginTop: "20px",
			borderRadius: "20px",
			backgroundColor: "#e0e0e0",
			padding: "7px",
			
          }}
        >
          הערות לקוח​
        </Typography>
        {post.notes !== null ? (
          <Typography variant="p">{post.notes}</Typography>
        ) : (
          <Typography variant="p" sx={{ fontFamily: 'Assistant' }}>אין הערות​</Typography>
        )}
      </Box>
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginTop: "20px",
            marginBottom: "20px",
			borderRadius: "20px",
			backgroundColor: "#e0e0e0",
			padding: "7px",
          }}
        >
          הצעות​
        </Typography>
        {result.length === 0 ? (
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "10px",
            }}
          >
            עדיין לא הוגשו הצעות
          </Typography>
        ) : (
          result
            .filter((e) => e.block !== "true")
            .map((e, i) => {
              return (
                <Bidded
                  allBids={result}
                  bid={e}
                  post={post}
                  handleOpen={handleOpen}
                />
              );
            })
        )}
      </Box>
      <Box
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h6"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, borderRadius: "20px", backgroundColor: "#e0e0e0", padding: "7px", }}
        >
          סרטונים
        </Typography>
        {post.video === null || post.video === undefined ? (
          <Typography
            variant="p"
            sx={{
              fontFamily: `${process.env.REACT_APP_FONT}`,
              marginBottom: "10px",
            }}
          >
            לא נוספו סרטונים
          </Typography>
        ) : (
          ""
        )}
        <FlexBox
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            margin: "10px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {post.video !== null &&
            post.video
              .split(",")
              .map((item) =>
                item.length > 0 ? (
                  <AdvertVideos video={item} height="300px" width="100%" />
                ) : (
                  ""
                )
              )}
        </FlexBox>
      </Box>
      <Box
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          marginBottom: "20px",
        }}
      >
        {post.deadline - new Date().getTime() > 0 && (
          <TextareaValidator
            result={result}
            what="comment"
            handleSubmit={handleSubmit}
            setNotes={setNotes}
            notes={notes}
            message={"הוסף תגובה"}
          />
        )}
        <Typography
          variant="h6"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginBottom: "10px",
			borderRadius: "20px",
			backgroundColor: "#e0e0e0",
			padding: "7px",
          }}
        >
          הערות
        </Typography>
        {comments.length === 0 ? (
          <Typography variant="p" sx={{ fontFamily: 'Assistant' }}>עדיין לא נמסרו הערות</Typography>
        ) : (
          showComment &&
          comments.map((e) => {
            return <CommentDesign comment={e} />;
          })
        )}
      </Box>

      {open && (
        <BidModal
          data={post.lowestBid}
          open={open}
          giveBid={giveBid}
          handleClose={handleClose}
        />
      )}
    </Box>
  );
}

export default ShowBidPost;
