import { Box, Stack } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Feed from "./Feed";
import Footer from "./Footer";
import Rightbar from "./Rightbar";

function Home() {
  let search1 = "";
  const { search } = useParams();
  const [news, setNews] = useState([]);
  if (search === null || search === undefined) search1 = "no";
  else search1 = search;
  useEffect(() => {
    if (localStorage.getItem("reload") === "1") {
      localStorage.setItem("reload", "2");
      window.location.reload();
    }
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/getnews`)
      .then((res) => {
        console.log(res);
        setNews(res.data.News);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      <Stack
        sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
        className="App"
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-evenly"
      >
        <Feed search1={search1} />
        {news.length > 0 && <Rightbar news={news} />}
      </Stack>
      <Footer></Footer>
    </Box>
  );
}

export default Home;
