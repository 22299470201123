import {
  Box,
  Button,
  Checkbox,
  ListItemText,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import returnCityNames from "../asset/js/CityNames";
function ChangeServiceArea({
  open,
  admin,
  handleClose,
  user,
  addChangedUser,
  updatePage,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [area, setArea] = useState(
    user.area !== null ? user.area.split(",")[0] : "צפון"
  );
  const cityNames = returnCityNames();
  let ar = [];

  const [areas, setAreas] = useState(user.area);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      xs: 200,
      md: 400,
    },
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    fontFamily: `${process.env.REACT_APP_FONT}`,
  };
  let choosenAreas = [];
  if (user.area !== null) {
    choosenAreas = user.area.split(",").filter((e) => {
      if (e !== ",") return e;
      return "";
    });
    let aer = [];
    for (let i = 0; i < choosenAreas.length; i++) {
      aer.push(
        typeof choosenAreas[i] === "string"
          ? choosenAreas[i].split(",")
          : choosenAreas[i]
      );
    }
  }
  const [personName, setPersonName] = React.useState(
    choosenAreas.filter((e) => (typeof e === "string" ? e.split(",") : e))
  );

  console.log(personName);
  console.log(personName.indexOf("South A"));
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      },
    },
  };
  const handleSubmit1 = (e) => {
    console.log(e);
    let userArea = "";
    console.log(admin);
    console.log(user);
    personName.forEach((e) => (userArea += e + ","));
    if (admin === "yes") {
      console.log(admin);
      axios
        .put(
          `${process.env.React_APP_BACKEND_URL}/api/admin/changearea?id=${user.id}`,
          {
            area: userArea,
            id: user.id,
          },
          { headers: { Authorization: `Bearer ${Cookies.get("admintoken")}` } }
        )
        .then((res) => {
          console.log(res);
          handleClose("area");
          addChangedUser("area", area);
          updatePage();
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
    axios
      .put(
        `${process.env.React_APP_BACKEND_URL}/api/changearea?id=${user.id}`,
        {
          area: userArea,
        },
        { headers: { Authorization: `Bearer ${Cookies.get("token")}` } }
      )
      .then((res) => {
        console.log(res);
        handleClose("area");
        addChangedUser("area", area);
        updatePage();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Modal
      open={open}
      onClose={(e) => handleClose("area")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          שנה או הוסף אזור שירות​
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="p"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            display: "inline-block",
            marginBottom: "10px",
          }}
        >
          בחר את האזור או האזורים ​
        </Typography>
        <Select
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            width: "100%",
            marginBottom: "10px",
          }}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          placeholder="Enter Area"
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {[
            "צפון",
            "קרית שמונה והסביבה",
            "חיפה והסביבה",
            "ירושלים והסביבה",
            "נתניה והסביבה",
            "השרון והסביבה",
            "מרכז",
            "שפלה והסביבה",
            "באר שבע והסביבה",
            "דרום",
          ].map((name, i) => (
            <MenuItem
              key={name}
              value={name}
              sx={{
                fontFamily: `${process.env.REACT_APP_FONT}`,
                flexDirection: "column",
                fontSize: { xs: "80%", md: "100%" },
              }}
            >
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
              <Box
                display="block"
                sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
              >
                {name === "צפון" ? (
                  <Box>
                    {cityNames.southCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "קרית שמונה והסביבה" ? (
                  <Box>
                    {cityNames.northCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "חיפה והסביבה" ? (
                  <Box>
                    {cityNames.westCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "ירושלים והסביבה" ? (
                  <Box>
                    {cityNames.eastCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "נתניה והסביבה" ? (
                  <Box>
                    {cityNames.eCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "השרון והסביבה" ? (
                  <Box>
                    {cityNames.fCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "מרכז" ? (
                  <Box>
                    {cityNames.gCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "שפלה והסביבה" ? (
                  <Box>
                    {cityNames.hCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "באר שבע והסביבה" ? (
                  <Box>
                    {cityNames.iCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
                ) : name === "דרום" ? (
                  <Box>
                    {cityNames.jCities.map((e) => {
                      return (
                        <Typography
                          variant="p"
                          display="block"
                          sx={{
                            fontFamily: `${process.env.REACT_APP_FONT}`,
                            color: "grey",
                            margin: "0px 3px",
                          }}
                        >
                          {e}
                        </Typography>
                      );
                    })}
                  </Box>
				) : (
                  ""
				)}
              </Box>
            </MenuItem>
          ))}
        </Select>
        <Button onClick={handleSubmit1} variant="contained">
          עדכן​
        </Button>
      </Box>
    </Modal>
  );
}

export default ChangeServiceArea;
