import { Box } from "@mui/material";
import FaqComponent from "./FaqQuestions";
import JoinUs from "./JoinUsButton";

export default function Faq({faq,prof}){
    return(<Box sx={{backgroundColor:"#eeeeee",padding:{xs:"10px 15px",md:"10px 30px"}, borderRadius:"5%"}}>
        <h1 style={{margin:"20px auto",textAlign:'center', fontFamily: 'Assistant'}}>שאלות נפוצות</h1>
		{/*<h4 style={{margin:"10px auto",textAlign:'center', fontFamily: 'Assistant'}}>Questions potential users may ask</h4>*/}
        <FaqComponent faq={faq}/>
        <JoinUs prof={prof} text={"הצטרף עכשיו >"}/>
    </Box>)
}