import { Box, Stack } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import AboutMe from "./AboutMe";
import CommentsExpand from "./CommentsExpand";
import EditProfile from "./EditProfile";
import ProfileMenu from "./ProfileMenu";

function Profile() {
  const [sendNotifications, setSendNotifications] = useState([]);
  const [about, setAbout] = useState(true);
  const [settings, setSettings] = useState(false);
  const [bids, setBids] = useState(false);
  const [user, setUser] = useState();
  const [showUser, setShowUser] = useState(false);
  useEffect(() => {
    axios
      .get(`${process.env.React_APP_BACKEND_URL}/api/profile`, {
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
      })
      .then((res) => {
        console.log(res);
        setSendNotifications(res.data.sendNotifications[0]);
        let user = res.data.user;
        user.rating = res.data.rating;
        setUser(res.data.user);
        setShowUser(true);
      })
      .catch((err) => console.log(err));
  }, []);
  const handleProfile = (e) => {
    if (e === "about") {
      setSettings(false);
      setAbout(true);
      setBids(false);
    } else if (e === "bids") {
      setSettings(false);
      setAbout(false);
      setBids(true);
    } else if (e === "settings") {
      setSettings(true);
      setAbout(false);
      setBids(false);
    }
  };
  return (
    <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
      {showUser && (
        <Stack
          className="App"
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-evenly"
          sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
        >
          <ProfileMenu handleProfile={handleProfile}></ProfileMenu>
          {about && <AboutMe user={user} />}
          {bids && <CommentsExpand user={user} />}
          {settings && <EditProfile handle={sendNotifications}></EditProfile>}
        </Stack>
      )}
    </Box>
  );
}

export default Profile;
