import { Box } from "@mui/material";
import React from "react";
import Home from "./Home";
import Navbar from "./Navbar";

function AfterSignIn() {
  return (
    <Box>
      <Navbar></Navbar>
      <Home />
    </Box>
  );
}

export default AfterSignIn;
