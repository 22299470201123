import { Box, Typography } from "@mui/material";
import axios from "axios";
import parse from "html-react-parser";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import Footer from "./Footer";

function PrivacyPolicy() {
  const [data, setData] = useState("");

  useEffect(() => {
    // Function to scroll to the top of the page
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    // Call the function to scroll to the top when the component mounts
    scrollToTop();

    // Fetch data from the backend API
    axios
      .get(
        `${process.env.React_APP_BACKEND_URL}/api/getcontent?change=privacy`,
        {
          headers: {
            Authorization: `Bearer ${Cookies.get("token")}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data.Content[0]);
        setData(response.data.Content[0]["privacy"]);
      })
      .catch((err) => console.log(err));
  }, []); // Empty dependency array ensures that this effect runs only once, equivalent to componentDidMount

  return (
    <Box>
      <Box
        sx={{
          fontFamily: `${process.env.REACT_APP_FONT}`,
          width: "75%",
          margin: "20px auto",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontFamily: `${process.env.REACT_APP_FONT}`,
            marginBottom: "20px",
            display: "none",
          }}
        >
          Privacy Policy Page
        </Typography>
        {data.length > 0 && (
          <Typography
            variant="pre"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            {parse(data)}
          </Typography>
        )}
      </Box>
      <Footer />
    </Box>
  );
}

export default PrivacyPolicy;
