import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
import Next from "./Next";
import ShowDetailSignup from "./SignUpCompo/ShowDetailsSignup";

function OTPInput({ sentOtp, email,type,setProgress,progress,prof }) {
  const [otp, setValue] = useState("");
  const [next, setNext] = useState(false);
  const handleChange = (event) => {
    console.log(event);
    setValue(event);
  };
  const handleSubmit = () => {
    if (otp.toString() === sentOtp.toString()) {
      console.log(otp)
      setProgress(2);
      setNext(true);
    };
  };
  return (
    <Box style={{ textAlign: "center" }}>
      {!next && (
        <Box sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}>
          <Typography
            variant="h6"
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}` }}
          >
            הזן את הקוד שנשלח אליך​
          </Typography>
          <Box dir="ltr">
            <OtpInput
              containerStyle={{
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
                textAlign: "center",
              }}
              inputType="number"
              inputStyle={{
                width: "100%",
                height: "30px",
                color: "black",
                fontWeight: "110%",
              }}
              isInputNum={true}
              value={otp}
              onChange={handleChange}
              numInputs={6}
              separator={<span>-</span>}
            />
          </Box>
          <Button
            variant={otp.length===6?"contained":"diasbled"}
            sx={{ fontFamily: `${process.env.REACT_APP_FONT}`, mt: 3, mb: 2, fontSize: '1.2rem', border: '1px solid #ccc', borderRadius: '4px',padding: '5px 65px',display:"block",margin:"20px auto" }}
            onClick={handleSubmit}
          >
            המשך​
          </Button>
          <ShowDetailSignup text={'הזן את הקוד שנשלח אליך לטלפון/לדוא"ל'}/>

        </Box>
      )}
      {next && <Next email={email} token="no" which="no" type={type} setProgress={setProgress}progress={progress} prof={prof}/>}
    </Box>
  );
}

export default OTPInput;
